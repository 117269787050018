/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type { Software } from '../../vulnerability.types';

const useGetSoftware = (keys: Array<any> = [], params: any = {}) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['software', ...(keys || [])],
    queryFn: () => vulnerabilityApi('v1/vulnerable_software_list').get(params),
  });

  const data = apiRes?.data;
  const software: Pick<Software, 'name' | 'icon_key' | 'vulnerability_type'>[] =
    data || [];

  return { software, isLoading };
};

export { useGetSoftware };
