/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import { transformDetectionDateFilterToApi } from '../../helpers';
import type { AllVulnerabilitiesFilter } from '../../vulnerability.types';

const useGetVulnerabilitiesBySeverity = (
  timespan: AllVulnerabilitiesFilter['latestDetected'] = null,
  softwareName: AllVulnerabilitiesFilter['softwareName'] = null,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: [
      'vulnerabilities-by-severity',
      timespan,
      softwareName,
      ...(keys || []),
    ],
    queryFn: () =>
      vulnerabilityApi('v2/dashboards/vulnerabilities_by_severity').get({
        ...params,
        filter: {
          vulnerable_software: {
            in:
              softwareName?.appName?.length || softwareName?.osName?.length
                ? [...softwareName.appName, ...softwareName.osName]
                : null,
          },
          timespan: transformDetectionDateFilterToApi(timespan),
        },
      }),
  });

  const data = apiRes?.data;
  const numCritical = data?.critical || 0;
  const numHigh = data?.high || 0;
  const numMedium = data?.medium || 0;
  const numLow = data?.low || 0;
  const total = data?.total || 0;

  return { numCritical, numHigh, numMedium, numLow, total, isLoading };
};

export { useGetVulnerabilitiesBySeverity };
