import { z } from 'zod';

const InfoSchema = z.object({
  added_at: z.string(),
  updated_at: z.string().nullable(),
  serial_number: z.string(),
  windows_device_id: z.string(),
  hardware_device_id: z.string(),
  os_version: z.string(),
  os_edition: z.number(),
  manufacturer: z.string(),
  device_model: z.string(),
  device_type: z.string(),
  device_name: z.string(),
  locale: z.string(),
  application_version: z.string(),
  mac_addresses: z.array(z.string()),
});

const DevInfoSchema = z.object({
  content: z.object({
    DevId: z.string(),
    Man: z.string(),
    Mod: z.string(),
    DmV: z.string(),
    Lang: z.string(),
    Ext: z.nullable(z.string()),
  }),
  csp_name: z.string(),
  added_at: z.string(),
  updated_at: z.nullable(z.string()),
  last_sync_at: z.string(),
});

const DevDetailSchema = z.object({
  content: z.object({
    URI: z.object({
      MaxDepth: z.string(),
      MaxTotLen: z.string(),
      MaxSegLen: z.string(),
    }),
    DevTyp: z.string(),
    OEM: z.string(),
    FwV: z.string(),
    SwV: z.string(),
    HwV: z.string(),
    LrgObj: z.boolean(),
    Ext: z.object({
      Microsoft: z.object({
        MobileID: z.string(),
        RadioSwV: z.string(),
        Resolution: z.string(),
        CommercializationOperator: z.string(),
        ProcessorArchitecture: z.number(),
        ProcessorType: z.number(),
        OSPlatform: z.string(),
        LocalTime: z.string(),
        DeviceName: z.string(),
        DNSComputerName: z.string(),
        TotalStorage: z.number(),
        FreeStorage: z.number(),
        TotalRAM: z.number(),
        SMBIOSSerialNumber: z.string(),
        SMBIOSVersion: z.string(),
        SystemSKU: z.nullable(z.string()),
      }),
      WLANMACAddress: z.string(),
      VoLTEServiceSetting: z.boolean(),
      WlanIPv4Address: z.string(),
      WlanIPv6Address: z.string(),
      WlanDnsSuffix: z.string(),
      WlanSubnetMask: z.string(),
      DeviceHardwareData: z.string(),
    }),
  }),
  csp_name: z.string(),
  added_at: z.string(),
  updated_at: z.string().nullable(),
  last_sync_at: z.string(),
});

const CspsSchema = z.object({
  DevInfo: DevInfoSchema.optional(),
  DevDetail: DevDetailSchema.optional(),
});

export const WindowsDeviceDetailRootSchema = z.object({
  id: z.string(),
  added_at: z.string(),
  updated_at: z.string().nullable(),
  tenant_id: z.string(),
  identity_id: z.string().optional(),
  enrollment_status: z.string(),
  enrollment_type: z.string(),
  first_enrolled_at: z.string(),
  last_enrolled_at: z.string().nullable(),
  is_removed: z.boolean(),
  info: InfoSchema,
  csps: CspsSchema,
});
