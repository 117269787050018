/* istanbul ignore file */

import { useLocation } from 'react-router-dom';

export const useGetSearchParams = (...keys) => {
  const location = useLocation();
  const URLparams = new URLSearchParams(location.search);

  return keys.map((key) => URLparams.get(key));
};
