/* istanbul ignore file */
import { Text } from '@kandji-inc/nectar-ui';
import { createColumnHelper } from '@tanstack/react-table';
import type { z } from 'zod';

import { i18n } from 'i18n';
import {
  DateCell,
  NULL_VALUE_N_DASH,
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getMsdcColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const msdcSchema = schemas.find((schema) => schema.uri === 'ms_compliance');

  if (!msdcSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...msdcSchema.schema.properties,
  };

  const msdcColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(msdcColumnHelper, combinedProperties);

  function getModifiedGlobalColumns({ startColumnDefs, endColumnDefs }) {
    const makeSerialNumberDefaultVisible = (col) =>
      col.id === 'serial_number'
        ? { ...col, meta: { ...col.meta, defaultHidden: false } }
        : col;

    const columnsWithSerialNumberColumnDef = endColumnDefs.find(
      (columnDef) => columnDef.id === 'serial_number',
    )
      ? 'end'
      : 'start';
    const columnsToMap =
      columnsWithSerialNumberColumnDef === 'end'
        ? endColumnDefs
        : startColumnDefs;

    const newColumns = columnsToMap.map(makeSerialNumberDefaultVisible);
    return {
      startColumnDefs:
        columnsWithSerialNumberColumnDef === 'end'
          ? startColumnDefs
          : newColumns,
      endColumnDefs:
        columnsWithSerialNumberColumnDef === 'end' ? newColumns : endColumnDefs,
    };
  }

  function msdcDeviceManagementStateCell(info) {
    const value = info.getValue();
    switch (value) {
      case 'MANAGED':
        return <Text>{i18n.t('Managed')}</Text>;
      case 'NOT_MANAGED':
        return <Text>{i18n.t('Not Managed')}</Text>;
      default:
        return NULL_VALUE_N_DASH;
    }
  }

  function msdcDeviceComplianceStateCell(info) {
    const value = info.getValue();
    switch (value) {
      case 'COMPLIANT':
        return <Text>{i18n.t('Compliant')}</Text>;
      case 'NOT_COMPLIANT':
        return <Text>{i18n.t('Not Compliant')}</Text>;
      default:
        return NULL_VALUE_N_DASH;
    }
  }

  const { startColumnDefs, endColumnDefs } =
    getModifiedGlobalColumns(globalColumns);

  const columnDefs = [
    ...startColumnDefs,

    msdcColumnHelper.accessor((row) => row.intune_tenant_id, {
      id: 'intune_tenant_id',
      meta: {
        displayName: combinedProperties.intune_tenant_id.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    msdcColumnHelper.accessor((row) => row.intune_device_id, {
      id: 'intune_device_id',
      meta: {
        displayName: combinedProperties.intune_device_id.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    msdcColumnHelper.accessor((row) => row.intune_user_id, {
      id: 'intune_user_id',
      meta: {
        displayName: combinedProperties.intune_user_id.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    msdcColumnHelper.accessor((row) => row.is_intune_registration_active, {
      id: 'is_intune_registration_active',
      cell: OnOffCell,
      meta: {
        displayName: combinedProperties.is_intune_registration_active.title,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS()],
        filterDisabled,
      },
    }),

    msdcColumnHelper.accessor((row) => row.msdc_device_management_state, {
      id: 'msdc_device_management_state',
      cell: msdcDeviceManagementStateCell,
      meta: {
        displayName: combinedProperties.msdc_device_management_state.title,
        filterType: 'enum',
        filterIcon: 'list-check',
        filterOptions: [
          { value: 'MANAGED', label: i18n.t('Managed') },
          { value: 'NOT_MANAGED', label: i18n.t('Not Managed') },
        ],
        filterDisabled,
      },
    }),

    msdcColumnHelper.accessor((row) => row.msdc_device_compliance_state, {
      id: 'msdc_device_compliance_state',
      cell: msdcDeviceComplianceStateCell,
      meta: {
        displayName: combinedProperties.msdc_device_compliance_state.title,
        filterType: 'enum',
        filterIcon: 'list-check',
        filterOptions: [
          { value: 'COMPLIANT', label: i18n.t('Compliant') },
          { value: 'NOT_COMPLIANT', label: i18n.t('Not Compliant') },
        ],
        filterDisabled,
      },
    }),

    msdcColumnHelper.accessor((row) => row.last_checkin, {
      id: 'last_checkin',
      cell: DateCell,
      meta: {
        displayName: combinedProperties.last_checkin.title,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    msdcColumnHelper.accessor((row) => row.marketing_name, {
      id: 'marketing_name',
      meta: {
        displayName: combinedProperties.marketing_name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    msdcColumnHelper.accessor((row) => row.os_version, {
      id: 'os_version',
      meta: {
        displayName: combinedProperties.os_version.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    ...endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
