import { Box, styled } from '@kandji-inc/nectar-ui';
import { memo, useCallback, useMemo } from 'react';
import { DataTable, type SortState } from 'src/components';
import type {
  BehavioralThreatDetail,
  SortColumnState,
  ThreatDetail,
} from '../../threat.types';
import getColumns from './utils/get-columns';

const Container = styled(Box, {
  flex: 1,
  position: 'relative',
  overflow: 'auto',
});

type ThreatListTableProps = Readonly<{
  isFileDetectionType: boolean;
  searchTerm: string | undefined;
  handleReleaseThreat: (threat: ThreatDetail) => void;
  handleRecheckThreatStatus: (threat: ThreatDetail) => void;
  isThreatBeingChecked: boolean;
  threats: ThreatDetail[] | BehavioralThreatDetail[];
  sort: SortColumnState;
  handleColumnSortChange: (sortState: SortState) => void;
  handleOpenSplitView: (
    threat: ThreatDetail | BehavioralThreatDetail,
    isFileDetectionType: boolean,
  ) => void;
  selectedForSplitView:
    | {
        threat: ThreatDetail | BehavioralThreatDetail;
        isFileDetectionType: boolean;
      }
    | undefined;
  isThreatSelectedForSplitView: boolean;
  isEmpty: boolean;
  isError: boolean;
  error: string | undefined;
}>;

const ThreatListTable = (props: ThreatListTableProps) => {
  const {
    isFileDetectionType,
    searchTerm,
    handleReleaseThreat,
    handleRecheckThreatStatus,
    isThreatBeingChecked,
    threats,
    sort,
    handleColumnSortChange,
    handleOpenSplitView,
    selectedForSplitView,
    isThreatSelectedForSplitView,
    isEmpty,
    isError,
    error,
  } = props;

  if (isError) {
    throw new Error(`Data fetching/validating error: ${error}`);
  }

  const isMoreActionsShown =
    !isThreatSelectedForSplitView && isFileDetectionType;

  const containerCssProcessMonitoring = useMemo(
    () => ({
      '& td:first-child': {
        paddingLeft: '$5',
      },
      '& td:last-child': {
        textAlign:
          isThreatSelectedForSplitView || !isFileDetectionType
            ? 'left'
            : 'right',
      },
    }),
    [isThreatSelectedForSplitView, isFileDetectionType],
  );

  const columns = useMemo(
    () =>
      getColumns({
        isFileDetectionType,
        query: searchTerm,
        onRelease: handleReleaseThreat,
        onRecheckStatus: handleRecheckThreatStatus,
        isThreatBeingChecked,
        isMoreActionsShown,
      }),
    [
      isFileDetectionType,
      searchTerm,
      isThreatBeingChecked,
      handleReleaseThreat,
      handleRecheckThreatStatus,
      isMoreActionsShown,
    ],
  );

  const handleRowClick = useCallback(
    /* istanbul ignore next */ (row: ThreatDetail | BehavioralThreatDetail) => {
      handleOpenSplitView(row, isFileDetectionType);
    },
    [handleOpenSplitView, isFileDetectionType],
  );

  const dataTableOffsets = useMemo(
    () => ({
      content: 0,
      table: 0,
      container: 0,
    }),
    [],
  );

  const dataTableSort = useMemo(
    () =>
      isEmpty
        ? null
        : {
            sortState: {
              col: sort.sortBy,
              direction: sort.sortOrder,
            },
            setSortState: handleColumnSortChange,
          },
    [handleColumnSortChange, sort, isEmpty],
  );

  const selectedId = `${selectedForSplitView?.threat.file_hash}${selectedForSplitView?.threat.file_path}${selectedForSplitView?.threat.device_id}${selectedForSplitView?.threat.detection_date}`;
  const dataTableSelectionModel = useMemo(
    /* istanbul ignore next */ () => ({
      selection: selectedForSplitView?.threat ? [selectedId] : [],
      hideSelectionColumn: true,
      customSelectionFunc: (row) => {
        if (!selectedForSplitView?.threat) return false;
        const id = `${row.file_hash}${row.file_path}${row.device_id}${row.detection_date}`;
        return id === selectedId;
      },
    }),
    [selectedForSplitView, selectedId],
  );

  const dataTableCss = useMemo(
    () => ({
      borderBottom: 'none',
      table: {
        marginBottom: !isEmpty && 120,
      },
      '& tbody tr:last-of-type': {
        borderBottom: '1px solid $neutral20',
      },
      '& tr > td:last-child': {
        paddingRight: !isThreatSelectedForSplitView && '$5',
      },
      '& th:last-child, td:last-child': {
        padding: 0,
        textAlign: 'right',
      },
      '& th:first-child': {
        paddingLeft: '$5',
      },
      ...containerCssProcessMonitoring,
    }),
    [isThreatSelectedForSplitView, isEmpty, containerCssProcessMonitoring],
  );

  return (
    <Container aria-label="Threats" data-testid="threats-table">
      <DataTable
        columns={columns}
        data={threats}
        offsets={dataTableOffsets}
        onRowClick={handleRowClick}
        sort={dataTableSort}
        selectionModel={dataTableSelectionModel}
        css={dataTableCss}
      />
    </Container>
  );
};

export default memo(ThreatListTable);
