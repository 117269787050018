/* istanbul ignore file */
import { Button, TextInput } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';
import './common.css';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';

const ReportEncryptionStatus = (props) => {
  const { param, update, isDisabled, validations } = props;
  const { details } = param;
  const [exclusions, setExclusions] = useState(details?.ignored_paths || []);
  const [isInvalid, setIsInvalid] = useState(
    new Array(details?.ignored_paths?.length || 0).fill(false),
  );

  const {
    ReportEncryptionStatus: { syncInvalid, displayInvalid },
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setExclusions(details?.ignored_paths || []);
      setIsInvalid(new Array(details?.ignored_paths?.length || 0).fill(false));
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        update({
          details: { ignored_paths: exclusions },
          isInvalid: isInvalid.some(Boolean),
        });
      }, 250);
      return () => clearTimeout(time);
    }
  }, [exclusions, isDisabled]);

  const addExclusion = () => {
    setExclusions((prev) => [...prev, '']);
    setIsInvalid((prev) => [...prev, true]);
  };

  const onTrash = (i) => {
    setExclusions((prev) => prev.filter((_, idx) => idx !== i));
    setIsInvalid((prev) => [...prev.filter((_, idx) => idx !== i)]);
  };

  return (
    <div className="">
      {exclusions.map((exclusion, idx) => {
        return (
          <div
            key={idx}
            className="b-mb3 b-flex"
            style={{ alignItems: 'flex-end' }}
          >
            <div className="b-flex1">
              <TextInput
                fieldsGrid
                disabled={isDisabled}
                wrapperClassName="b-flex1"
                className="b-flex1"
                label={i18n.t(
                  'Path of excluded volume. Path can end with * for wildcard match.',
                )}
                value={exclusion}
                placeholder="/Volumes/Simulation/*"
                onChange={(e) => {
                  const val = e.target.value;
                  setExclusions((prev) => {
                    prev[idx] = val;
                    return [...prev];
                  });
                }}
                onInvalidate={(isInvalid) =>
                  setIsInvalid((prev) => {
                    prev[idx] = isInvalid;
                    return [...prev];
                  })
                }
                validator={(v) => [
                  {
                    message: i18n.t('Required'),
                    invalid: () => !v,
                    trigger: ['onBlur', 'onInput'],
                  },
                  {
                    message: i18n.t('Path must begin with a /'),
                    invalid: () => !v.startsWith('/'),
                    trigger: ['onBlur', 'onInput'],
                  },
                ]}
                {...syncInvalid(param.isInvalid, {
                  key: `exclusion-volume-${idx}`,
                })}
              />
            </div>
            <Button
              className="b-ml-tiny"
              onClick={() => onTrash(idx)}
              disabled={isDisabled}
              kind="link"
              icon="trash-can"
              theme="error"
            />
          </div>
        );
      })}
      {featureFlags.getFlag('dc-1329-path-exclusion') && (
        <Button onClick={addExclusion} disabled={isDisabled} icon="plus">
          {i18n.t('Add Volume Exclusion')}
        </Button>
      )}
      {displayInvalid(param.isInvalid && !exclusions.length)}
    </div>
  );
};

export default ReportEncryptionStatus;
