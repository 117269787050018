import { Flex, styled } from '@kandji-inc/nectar-ui';
import { lazy, useEffect, useMemo, useState } from 'react';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { i18n } from 'src/i18n';
import ThreatListErrorBoundary from '../../common/components/ThreatListErrorBoundary';
import ThreatListSuspense from '../../common/components/ThreatListSuspense';
import ThreatListTable from '../../common/components/ThreatListTable/ThreatListTable';
import constants from '../../common/constants';
import useStateParams from '../../common/hooks/use-state-params';
import convertToCSV from '../../common/utils/convert-to-csv';
import downloadCSVFile from '../../common/utils/download-csv-file';
import getDisplayDetectionPeriod from '../../common/utils/get-display-detection-period';
import type {
  BehavioralThreatGroup,
  DetectionTypeSelectorState,
  FilterFields,
  FiltersState,
  GetBehavioralThreatGroupResponse,
  GetByThreatsResponse,
  MitreTechnique,
  PageSize,
  PaginationState,
  ShowToast,
  SortColumnDirection,
  SortColumnName,
  SortColumnState,
  TableHeaderColumn,
  ThreatClassification,
  ThreatGroup,
  ThreatStatus,
} from '../../threat.types';
import ThreatListFilters from '../ThreatListFilters';
import ThreatListTableBody from './ThreatListTableBody';
import ThreatListTableBulkAction from './ThreatListTableBulkAction';
import useExportThreatsMutation from './hooks/use-export-threats';
import useGetBehavioralThreatsGroup from './hooks/use-get-behavioral-threats-group/use-get-behavioral-threats-group';
import useGetByThreats from './hooks/use-get-by-threats/use-get-by-threats';
import useGetMitreTechniques from './hooks/use-get-mitre-techniques/use-get-mitre-techniques';
import useSelectThreatsMutation from './hooks/use-select-all-threats';
import useSelectRows from './hooks/use-select-rows';
import {
  createPaginationParamConfig,
  defaultFilters,
  filtersParamConfig,
  sortParamConfig,
} from './params/params';

const ThreatListSidePanel = lazy(
  () => import('./SidePanels/ThreatListSidePanel/ThreatListSidePanel'),
);

const ThreatListSidePanelPM = lazy(
  () => import('./SidePanels/ThreatListSidePanelPM/ThreatListSidePanel'),
);

const Container = styled(Flex, {
  height: 'calc(100vh - 140px)',
  flexDirection: 'column',
});

const errorBoundaryTableCss = {
  flex: 1,
  minHeight: 400,
};

const getColumns = (isFileDetectionType: boolean): TableHeaderColumn[] => [
  {
    name: 'threat_id',
    title: i18n.t('Threat ID'),
    tooltip: isFileDetectionType
      ? i18n.t('SHA-256 hash of the detected file')
      : i18n.t('Behavioral detection rule name'),
    tooltipWidth: 150,
    size: isFileDetectionType ? 13 : 12,
  },
  {
    name: 'associated_item',
    title: i18n.t('Associated Item'),
    size: isFileDetectionType ? 10 : 11,
    tooltip: isFileDetectionType
      ? i18n.t('The most recent file name')
      : i18n.t('The most recent parent process name'),
  },
  {
    name: 'classification',
    title: i18n.t('Classification'),
    size: isFileDetectionType ? 9 : 11,
  },
  {
    name: 'detection_date',
    title: i18n.t('Detection Date'),
    size: isFileDetectionType ? 10 : 11,
    tooltip: i18n.t(
      'The most recent detection date for each grouped threat event.',
    ),
    tooltipWidth: 250,
  },
  {
    name: 'device_count',
    title: i18n.t('Devices'),
    tooltip: i18n.t('Devices impacted'),
    size: isFileDetectionType ? 9 : 10,
  },
  {
    name: 'mitre',
    title: i18n.t('MITRE'),
    size: 9,
    isSortable: false,
    isHidden: isFileDetectionType,
  },
  {
    name: 'status',
    title: i18n.t('Threat Status'),
    tooltip: isFileDetectionType
      ? i18n.t('Not quarantined, Quarantined, Resolved, Released')
      : i18n.t('Detected, Blocked, Informational'),
    size: 12,
  },
];

type ThreatListByThreatsProps = {
  showToast: ShowToast;
};

const ThreatListByThreats = (props: ThreatListByThreatsProps) => {
  const { showToast } = props;
  const [selectedForSidePanel, setSelectedForSidePanel] = useState<
    ThreatGroup | BehavioralThreatGroup | undefined
  >();

  const {
    selectRow,
    unselectRow,
    selectRows,
    unselectRows,
    unselectAllRows,
    isRowSelected,
    selectedRowsCount,
    isAnyRowSelected,
  } = useSelectRows();

  const { paginationConfig, updateStoredPageSize } =
    createPaginationParamConfig('threatListGroupsPaginationPageSize');

  const {
    filters,
    setFilters,
    pagination,
    setPagination,
    sort,
    setSort,
    isDefaultFilterState,
  } = useStateParams<FiltersState, PaginationState, SortColumnState>(
    filtersParamConfig,
    paginationConfig,
    sortParamConfig,
    defaultFilters,
  );

  const isFileDetectionType = Boolean(
    filters.detectionType === constants.THREAT_DETECTION_TYPE.FILE ||
      filters.detectionType === undefined,
  );

  const {
    data: dataFileThreats,
    isLoading: isLoadingFileThreats,
    isFetching: isFetchingFileThreats,
    isError: isErrorFetchingFileThreats,
    error: errorFetchingFileThreats,
    refetch: refetchThreats,
  } = useGetByThreats(filters, sort, pagination, isFileDetectionType);

  useEffect(() => {
    if (!isFetchingFileThreats && selectedForSidePanel) {
      const updatedThreat = threats.find(
        (threat) => threat.threat_id === selectedForSidePanel.threat_id,
      );
      setSelectedForSidePanel(updatedThreat);
    }
  }, [isFetchingFileThreats]);

  const {
    data: dataBehavioralThreats,
    isLoading: isLoadingBehavioralThreats,
    isError: isErrorFetchingBehavioralThreats,
    error: errorFetchingBehavioralThreats,
  } = useGetBehavioralThreatsGroup(
    filters,
    sort,
    pagination,
    !isFileDetectionType,
  );

  const { data: dataMitreTechniques, isLoading: isLoadingMitreTechniques } =
    useGetMitreTechniques();

  const mitre: MitreTechnique[] = dataMitreTechniques || [];

  const dataThreats = isFileDetectionType
    ? dataFileThreats
    : dataBehavioralThreats;
  const isLoadingThreats = isFileDetectionType
    ? isLoadingFileThreats
    : isLoadingBehavioralThreats;
  const isErrorFetchingThreats = isFileDetectionType
    ? isErrorFetchingFileThreats
    : isErrorFetchingBehavioralThreats;
  const errorFetchingThreats = isFileDetectionType
    ? errorFetchingFileThreats
    : errorFetchingBehavioralThreats;

  const { fetchForExport, isLoading: isExporting } = useExportThreatsMutation(
    (
      data: GetByThreatsResponse | GetBehavioralThreatGroupResponse,
      isAll: boolean,
    ) => {
      if (!data?.data) return;

      const selectedRowsData = data.data.filter((threat) =>
        isRowSelected(threat.threat_id),
      );
      const isExportAll = isAll || selectedRowsData.length === 0;
      const dataToExport = isExportAll ? data.data : selectedRowsData;
      downloadCSVFile(convertToCSV(dataToExport));
      showToast(`export-complete`);
    },
    () => showToast(`export-error`),
  );

  const { fetchForSelect, isLoading: isLoadingForSelecting } =
    useSelectThreatsMutation(
      (data: GetByThreatsResponse) => {
        if (!data?.data) return;
        selectRows(data.data);
      },
      () => showToast(`select-all-error`),
    );

  const dataCount = dataThreats?.count;
  const malwareCount = dataThreats?.malware_count;
  const pupCount = dataThreats?.pup_count;
  const benignCount = dataThreats?.benign_count;
  const unknownCount = dataThreats?.unknown_count;

  const threats: ThreatGroup[] | BehavioralThreatGroup[] =
    dataThreats?.data || [];
  const isEmpty = dataCount === 0;
  const isFiltering = !isDefaultFilterState(['detectionType']);
  const isFilteringOmittingFields = !isDefaultFilterState([
    'detectionDate',
    'detectionType',
  ]);
  const displayDetectionPeriod: string = getDisplayDetectionPeriod(
    filters.detectionDate,
  );
  const selectedRowsInData = useMemo(
    () => threats.filter((threat) => isRowSelected(threat.threat_id)).length,
    [threats, isRowSelected],
  );
  const isAllPageSelected =
    threats.length === selectedRowsInData && !isLoadingThreats && !isEmpty;

  // istanbul ignore next
  const setFiltersAndReset = (newFilters: FiltersState) => {
    setFilters(newFilters);
    setPagination({ page: 1 });
    unselectAllRows();
  };

  // istanbul ignore next
  const handleClearFilters = (filterName: FilterFields) => {
    setFiltersAndReset({ [filterName]: undefined });
  };

  // istanbul ignore next
  const handleApplyFilters = (
    filterName: FilterFields,
    value: ThreatStatus[] | ThreatClassification | DetectionDateFilterFields,
  ) => {
    setFiltersAndReset({ [filterName]: value });
  };

  const handleApplyFiltersForDetectionType = (
    value: DetectionTypeSelectorState | undefined,
  ) => {
    setFiltersAndReset({
      status: undefined,
      classification: undefined,
      mitre: undefined,
      detectionType: value,
    });
  };

  // istanbul ignore next
  const handleClearAllFilters = () => {
    setFiltersAndReset({
      status: undefined,
      classification: undefined,
      detectionDate: undefined,
      mitre: undefined,
      query: undefined,
    });
  };

  // istanbul ignore next
  const handlePaginationChange = (page: number, pageSize: PageSize) => {
    setPagination({ page, pageSize });
    updateStoredPageSize(pageSize);
  };

  const handleColumnSortChange = (
    sortBy: SortColumnName | undefined,
    sortOrder: SortColumnDirection | undefined,
  ) => {
    setSort({ sortBy, sortOrder });
  };

  const handleExport = async (isAll: boolean) => {
    showToast(`export-preparing`);
    fetchForExport({
      filters,
      sort,
      pagination: {},
      isAll,
      isFileDetectionType,
    });
  };

  const handleOnAllPageSelected = (isSelected: boolean) => {
    if (isSelected) {
      selectRows(threats);
    } else {
      unselectRows(threats);
    }
  };

  const handleOnSelectRow = (key: string, isSelected: boolean) => {
    if (isSelected) {
      selectRow(key);
    } else {
      unselectRow(key);
    }
  };

  const handleOnAllSelected = () => {
    fetchForSelect({
      filters,
      sort,
      pagination: {},
    });
  };

  const handleOpenSidePanel = (threat: ThreatGroup | BehavioralThreatGroup) => {
    setSelectedForSidePanel(threat);
  };

  const handleCloseSidePanel = () => {
    setSelectedForSidePanel(undefined);
  };

  const ThreatListSidePanelPlaceholder = isFileDetectionType
    ? ThreatListSidePanel
    : ThreatListSidePanelPM;

  return (
    <ThreatListErrorBoundary
      css={errorBoundaryTableCss}
      preReset={refetchThreats}
    >
      <Container>
        <ThreatListFilters
          filters={filters}
          mitre={mitre}
          isMitreLoading={isLoadingMitreTechniques}
          malwareCount={malwareCount}
          pupCount={pupCount}
          benignCount={benignCount}
          unknownCount={unknownCount}
          onApply={handleApplyFilters}
          onDetectionTypeApply={handleApplyFiltersForDetectionType}
          onClear={handleClearFilters}
          onClearAll={handleClearAllFilters}
          isFiltering={isFiltering}
          isExportHidden={isEmpty || isLoadingThreats}
          isExportDisabled={isExporting}
          onExport={() => handleExport(true)}
          isFileDetectionType={isFileDetectionType}
        />
        <ThreatListTable
          isLoading={isLoadingThreats}
          isError={isErrorFetchingThreats}
          error={errorFetchingThreats}
          sort={sort}
          onColumnSort={handleColumnSortChange}
          pagination={pagination}
          onPaginationChange={handlePaginationChange}
          dataCount={dataCount}
          displayDetectionPeriod={displayDetectionPeriod}
          displayDetectionSubject={
            isFileDetectionType
              ? i18n.t('malware or PUP detections')
              : i18n.t('malicious or suspicious behavioral detections')
          }
          isFiltering={isFilteringOmittingFields}
          showToast={showToast}
          columns={getColumns(isFileDetectionType)}
          containerCss={{
            '& tr > td:first-child': {
              padding: 0,
              pl: '$4',
            },
            '& tr > th:first-child': {
              pl: '$5',
            },
            '& td:last-child': {
              textAlign: !isFileDetectionType ? 'left' : 'right',
            },
            '& thead': {
              top: 0,
            },
          }}
          isScrollable
          isSelectable={false}
          onAllPageSelected={handleOnAllPageSelected}
          isAllPageSelected={isAllPageSelected}
          isPaginationPageSizeShown={!isAnyRowSelected}
          isLoadingForSelecting={isLoadingForSelecting}
          bulkActions={
            <ThreatListTableBulkAction
              dataCount={dataCount}
              selectedCount={selectedRowsCount}
              onClearAllSelected={unselectAllRows}
              onAllSelected={handleOnAllSelected}
              isLoadingForSelecting={isLoadingForSelecting}
              isExporting={isExporting}
              isAnyRowSelected={isAnyRowSelected}
              onExport={() => handleExport(false)}
            />
          }
          isMoreActionsShown={isFileDetectionType}
        >
          <ThreatListTableBody
            threats={threats}
            query={filters.query}
            isLoading={isLoadingThreats}
            isSelectable={false}
            isRowSelected={isRowSelected}
            onSelectRow={handleOnSelectRow}
            isSelectionDisabled={isLoadingThreats || isLoadingForSelecting}
            onOpenSidePanel={handleOpenSidePanel}
            selectedThreatForSidePanel={selectedForSidePanel}
            isFileDetectionType={isFileDetectionType}
          />
        </ThreatListTable>
      </Container>
      {selectedForSidePanel && (
        <ThreatListSuspense>
          <ThreatListSidePanelPlaceholder
            threat={selectedForSidePanel}
            detectionDate={filters.detectionDate}
            onClose={handleCloseSidePanel}
            tableSearchQuery={filters.query}
            refetchThreats={refetchThreats}
          />
        </ThreatListSuspense>
      )}
    </ThreatListErrorBoundary>
  );
};

export default ThreatListByThreats;
