import { Flex, Heading, Toast } from '@kandji-inc/nectar-ui';
import { InterfaceContext } from 'contexts/interface';
import useWhiteBg from 'features/util/hooks/use-white-bg';
import { i18n } from 'i18n';
import { useContext, useRef } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import ThreatListUnassignedEmptyState from '../common/components/ThreatListUnassignedEmptyState';
import useThreatsToast from '../common/hooks/use-threats-toast';
import ThreatListByThreats from './ThreatListByThreats/ThreatListByThreats';

const ThreatList = () => {
  useWhiteBg();
  const { path } = useRouteMatch();
  const { sidebarOpened } = useContext(InterfaceContext);
  const toastRef = useRef(null);
  const { showToast } = useThreatsToast(toastRef);

  return (
    <>
      <Flex flow="column">
        <Heading size="1" css={{ padding: '$6 $5 $2' }}>
          {i18n.t('Threats')}
        </Heading>
        <ThreatListUnassignedEmptyState />
        <Switch>
          <Route exact path={`${path}`}>
            <ThreatListByThreats showToast={showToast} />
          </Route>

          <Redirect to={links.threat} />
        </Switch>
      </Flex>
      <Toast
        testId="threat-toaster"
        managerRef={toastRef}
        viewportCss={{
          left: sidebarOpened ? '256px' : '78px',
        }}
      />
    </>
  );
};

export default ThreatList;
