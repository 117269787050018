import { Card, Heading, styled } from '@kandji-inc/nectar-ui';

const Tile = styled(Card, {
  flex: 1,
  minWidth: 0,
});

const TileHeading = styled(Heading, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export { Tile, TileHeading };
