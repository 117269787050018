import { Code, Flex, Text, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import ThreatListTextCopyButton from '../../common/components/ThreatListTextCopyButton';
import ThreatListButtonPopover from './SidePanels/ThreatListButtonPopover';

const TextBold = styled(Text, { fontWeight: '$medium' });

const CodeStyled = styled(Code, {
  padding: '2px 4px',
  fontSize: 12,
  lineHeight: '16px',
  display: 'flex',
  width: 364,
  whiteSpace: 'normal',
  overflowY: 'scroll',
  wordBreak: 'break-all',
  maxHeight: 85,
});

const AssociatedItemPopoverCopyButton = ({
  text,
  copyText,
  position = 'left',
}: {
  text: string | undefined;
  copyText?: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
}) => {
  return (
    <ThreatListTextCopyButton
      copyText={copyText}
      text={text}
      buttonTootlipPosition={position}
      css={{
        alignItems: 'start',
        gap: 2,
        fontSize: '$1',
        lineHeight: '$1',
        '& > div': {
          marginTop: 2,
          width: '$4',
          height: '$4',
        },
        '& svg': {
          mt: 1,
          height: 14,
          width: 14,
          verticalAlign: 'text-top',
        },
      }}
    />
  );
};

const AssociatedItemPopoverContent = ({
  latestParentProcessName,
  latestParentProcessArgs,
  latestTargetProcessName,
  latestTargetProcessArgs,
}: {
  latestParentProcessName: string;
  latestParentProcessArgs: string;
  latestTargetProcessName: string;
  latestTargetProcessArgs: string;
}) => {
  return (
    <Flex
      flow="column"
      gap="xs"
      css={{
        fontSize: '$1',
        maxHeight: 322,
        width: 414,
        overflow: 'auto',
        position: 'relative',
        marginInline: '-$4',
        marginBlock: '-$3',
        paddingInline: '$4',
        paddingBlock: '$3',
        whiteSpace: 'normal',
      }}
    >
      <Flex flow="column" gap="xs" alignItems="start">
        <Flex>
          <TextBold>{i18n.t('Parent process')}</TextBold>
        </Flex>
        <AssociatedItemPopoverCopyButton
          position="right"
          text={latestParentProcessName}
        />
        {latestParentProcessArgs && (
          <>
            <Flex>
              <TextBold>{i18n.t('Parent command line')}</TextBold>
            </Flex>
            <AssociatedItemPopoverCopyButton
              copyText={latestParentProcessArgs}
              text={<CodeStyled>{latestParentProcessArgs}</CodeStyled>}
            />
          </>
        )}
        {latestTargetProcessName && (
          <>
            <Flex>
              <TextBold>{i18n.t('Target process')}</TextBold>
            </Flex>
            <AssociatedItemPopoverCopyButton
              position="right"
              text={latestTargetProcessName}
            />
          </>
        )}
        {latestTargetProcessArgs && (
          <>
            <Flex>
              <TextBold>{i18n.t('Target command line')}</TextBold>
            </Flex>
            {latestTargetProcessArgs ? (
              <AssociatedItemPopoverCopyButton
                copyText={latestParentProcessArgs}
                text={<CodeStyled>{latestTargetProcessArgs}</CodeStyled>}
              />
            ) : (
              '-'
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};

const AssociatedItemPopover = ({
  text,
  latestParentProcessName,
  latestParentProcessArgs,
  latestTargetProcessName,
  latestTargetProcessArgs,
}: {
  text: string;
  latestParentProcessName: string;
  latestParentProcessArgs: string;
  latestTargetProcessName: string;
  latestTargetProcessArgs: string;
}) => {
  if (!text) return null;

  return (
    <ThreatListButtonPopover
      icon="clock-rotate-left"
      isHoverable
      trigger={text}
      testId="associated-item-popover"
    >
      <AssociatedItemPopoverContent
        latestParentProcessName={latestParentProcessName}
        latestParentProcessArgs={latestParentProcessArgs}
        latestTargetProcessName={latestTargetProcessName}
        latestTargetProcessArgs={latestTargetProcessArgs}
      />
    </ThreatListButtonPopover>
  );
};

export default AssociatedItemPopover;
