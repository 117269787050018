/* istanbul ignore file */
import {
  Box,
  Button,
  Dialog,
  Flex,
  IconButton,
  Text,
  TextArea,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import deepcopy from 'deepcopy';
import type React from 'react';
import { useContext, useEffect, useState } from 'react';
import { AccountContext } from 'src/contexts/account';
import { InterfaceContext } from 'src/contexts/interface';
import { i18n } from 'src/i18n';
import { useContactSales } from './useContactSales';

const defaultModel = {
  comments: '',
};

const SIDEBAR_DOCKED_OFFSET = 256;
const SIDEBAR_CLOSE_OFFSET = 78;
const MAX_COMMENTS_LENGTH = 320;

const ContactSalesDialog = ({
  pageName,
  isOpen,
  setIsOpen,
  goBackToSupportDialog,
  withError = null,
  openedFromSupportDialog = false,
}: {
  pageName: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  goBackToSupportDialog: () => void;
  withError: string;
  openedFromSupportDialog: boolean;
}) => {
  const { currentCompany, userEmail, userFirstName, userLastName } =
    useContext(AccountContext);

  const { sidebarDocked } = useContext(InterfaceContext);
  const { toast } = useToast();

  const tenant = currentCompany?.subdomain || '';

  const [model, setModel] = useState(deepcopy(defaultModel));

  const { mutateAsync: contactSales, isPending } = useContactSales(
    '5058330',
    '657409c6-f4eb-4fd4-891f-2d280d95a898',
  );

  const onContactSales = () =>
    contactSales({
      tenant,
      userEmail,
      userFirstName,
      userLastName,
      pageName,
      comments: model.comments,
    })
      .then(() => {
        toast({
          title: 'Sales will be in touch soon.',
          variant: 'success',
          duration: 2500,
          style: {
            left: /* istanbul ignore next */ sidebarDocked
              ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
              : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
            bottom: '12px',
            position: 'absolute',
          },
        });
        setIsOpen(false);
      })
      .catch(() =>
        toast({
          title: 'Failed to send request. Please try again.',
          variant: 'error',
          duration: 2500,
          style: {
            left: /* istanbul ignore next */ sidebarDocked
              ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
              : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
            bottom: '12px',
            position: 'absolute',
          },
        }),
      );

  useEffect(() => {
    if (isOpen) {
      setModel(deepcopy(defaultModel));
    }
  }, [isOpen]);

  const title = i18n.t('Contact sales');
  const content = (
    <Flex mb4 flow="column" gap="lg">
      {withError ? (
        <Text variant="danger" css={{ marginTop: '4px' }}>
          {withError}
        </Text>
      ) : (
        <Text>
          {i18n.t(
            'Send a request to be put in touch with a sales representative.',
          )}
        </Text>
      )}
      <Box css={{ height: '140px', '& textArea': { height: '140px' } }}>
        <TextArea
          placeholder="Enter any additional comments"
          onChange={(e) =>
            setModel((prev) => ({ ...prev, comments: e.target.value }))
          }
          // Setting resize true bypasses the builtin nectar resizing behavior
          resize={true}
          value={model.comments}
          hint={{
            charCounter: {
              count: model.comments.length,
              max: MAX_COMMENTS_LENGTH,
            },
          }}
          autoFocus
          css={{
            '& textarea': {
              // Turning off resizing again to avoid all resize behavior
              resize: 'none !important',
            },
          }}
        />
      </Box>
    </Flex>
  );

  const footer = (
    <Flex justifyContent="end" alignItems="center" gap="sm">
      {!openedFromSupportDialog && (
        <Button
          loading={isPending}
          variant="subtle"
          onClick={() => setIsOpen(false)}
        >
          {i18n.t('Cancel')}
        </Button>
      )}
      {openedFromSupportDialog && (
        <Button
          loading={isPending}
          variant="subtle"
          icon={{
            position: 'left',
            name: 'arrow-left',
          }}
          onClick={goBackToSupportDialog}
        >
          {i18n.t('Back')}
        </Button>
      )}
      <Button
        loading={isPending}
        disabled={
          !model.comments.trim().length ||
          model.comments.trim().length >= MAX_COMMENTS_LENGTH
        }
        variant="primary"
        onClick={() => onContactSales()}
      >
        {i18n.t('Send request')}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      css={{
        width: '480px',
      }}
      isOpen={isOpen}
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={setIsOpen}
      title={title}
      content={content}
      footer={footer}
    />
  );
};

export default ContactSalesDialog;
