import { Flex } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useEffect } from 'react';
import EDRSearchFilter from 'src/features/edr/common/components/Filters/EDRSearchFilter';
import { useShallow } from 'zustand/react/shallow';
import { defaultAllVulnerabilitiesFilter } from '../../constants';
import useVulnerability from '../../store';

const TableFilterBar = () => {
  const [filter, setFilter] = useVulnerability(
    useShallow((state) => [
      state.allVulnerabilitiesFilter,
      state.setAllVulnerabilitiesFilter,
    ]),
  );

  const handleChangeTerm = (term: string) => {
    setFilter({ term });
  };

  useEffect(() => {
    return () => setFilter(defaultAllVulnerabilitiesFilter);
  }, []);

  return (
    <Flex p3 gap="md">
      <EDRSearchFilter
        name="vulnerability"
        value={filter.term}
        width={320}
        onChange={(_, value) => handleChangeTerm(value as string)}
        onClear={() => handleChangeTerm('')}
        placeholder={i18n.t('Search by CVE ID or vulnerable software')}
      />
    </Flex>
  );
};

export { TableFilterBar };
