import { blueprintLibraryCategories } from 'features/blueprints/common';
/* istanbul ignore file */
import { i18n } from 'i18n';
import {
  apiTypes,
  canAddLibraryTypes,
  categories,
  deviceTypes,
} from '../../common';
import LibraryItemConfig from './item-config.class';

/* These items are returned from the API (for now). Their configurations live
here for the purpose of allowing frontend configuration, specifically routing.
*/
const appStoreApps = {
  'App Store Apps': new LibraryItemConfig({
    getName: () => i18n.t('App Store App'),
    getDescription: () => '',
    name: 'App Store App',
    description: '',
    type: apiTypes.VPP_APP,
    publisher: null,
    devices: [deviceTypes.MAC],
    osRequirements: [],
    category: categories.APP_STORE,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    icon: '',
    canAdd: canAddLibraryTypes.CANNOT_ADD,
    identifier: '',
    routeType: 'app-store-apps',
    getUrl: ({ id }) => `/library/app-store-apps/${id}`,
    getAddUrl: () => null,
  }),
};

export default appStoreApps;
