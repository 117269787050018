import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Banner, Button, Dialog, Flex, Text } from '@kandji-inc/nectar-ui';
import { bool, func, number, object } from 'prop-types';
import './styles.scss';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { bindActionCreators } from 'redux';

import { i18n } from 'i18n';
import { assignComputerUser as callAssignComputerUser } from 'src/app/_actions/computer';
import { getUsers as callGetUsers } from 'src/app/_actions/gSuite';
import AsyncSelectSearch from 'src/features/util/components/async-select-search/async-select-search';

const Modal = (props) => {
  const {
    isOpen,
    setIsOpen,
    getUsers,
    assignComputerUser,
    computerRecord,
    maxResults,
    onSave: onPostSave,
  } = props;

  const initialUser = computerRecord?.user;

  const [chosenUser, setChosenUser] = useState(null);
  const [isWorking, setIsWorking] = useState(false);

  // Get users on load
  useEffect(() => {
    getUsers({ is_archived: false, sizePerPage: maxResults });
    setChosenUser(initialUser);
  }, []);

  const getValue = () => {
    if (chosenUser) {
      return { label: chosenUser.name, value: chosenUser.id };
    }
    return null;
  };

  // Transform the list of users into objects with a label and a value
  const formatValues = (values) =>
    values.map((user) => ({
      label: `${user.name} ${user.email}`,
      value: user,
    }));

  // Given a search input, get the matching list of users
  const loadOptions = async (input) =>
    getUsers({
      is_archived: false,
      sizePerPage: maxResults,
      search: input,
    }).then((data) => formatValues(data.results));

  const onClose = () => {
    setIsOpen(false);
  };

  const onCancel = () => {
    setChosenUser(initialUser);
    onClose();
  };

  const onUserSelect = (selectedOption) => {
    const { value: user } = selectedOption;
    setChosenUser(user);
  };

  const onSave = async () => {
    const payload = { id: chosenUser.id };
    setIsWorking(true);

    await assignComputerUser(computerRecord.id, payload)
      .then(
        /* istanbul ignore next */ (r) => {
          setIsWorking(false);
          toaster(i18n.t('User was assigned successfully'));
          onPostSave?.(r);
        },
      )
      .catch(() => {
        setIsWorking(false);
        toaster(i18n.common.error());
      });

    onClose();
  };

  const onUnassign = async () => {
    const payload = null;
    setIsWorking(true);
    setChosenUser(null);

    await assignComputerUser(computerRecord.id, payload)
      .then(
        /* istanbul ignore next */ (r) => {
          setIsWorking(false);
          toaster(i18n.t('User was unassigned successfully'));
          onPostSave?.(r);
        },
      )
      .catch(() => {
        setIsWorking(false);
        toaster(i18n.common.error());
      });

    onClose();
  };

  const noUserChange = initialUser === chosenUser;
  const noSelectedUser = chosenUser === null;

  const customOption = (optionProps) => {
    const { name, email } = optionProps.data.value;
    return (
      <components.Option {...optionProps}>
        <div className="b-flex-col">
          <Text css={{ fontWeight: '$medium' }}>{name}</Text>
          <Text variant="subtle">{email}</Text>
        </div>
      </components.Option>
    );
  };

  const content = (
    <Flex flow="column" gap="lg">
      <Banner
        theme="warning"
        text={i18n.t(
          'If leveraging user directory Assignment Rules, changing the user may affect the Library Items assigned to this device.',
        )}
      />
      <Flex flow="column" gap="sm">
        <Text>{i18n.t('User')}</Text>
        <AsyncSelectSearch
          placeholder={i18n.t('Not assigned')}
          value={getValue()}
          loadOptions={loadOptions}
          onChange={onUserSelect}
          customOption={customOption}
        />
      </Flex>
    </Flex>
  );

  const footer = (
    <Flex justifyContent="space-between" wrap="wrap">
      <Button
        variant="subtle"
        onClick={onUnassign}
        isDisabled={noSelectedUser}
        loading={isWorking}
      >
        {i18n.t('Unassign device')}
      </Button>
      <Flex gap="md">
        <Button variant="subtle" onClick={onCancel}>
          {i18n.t('Cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={onSave}
          disabled={noUserChange}
          loading={isWorking}
        >
          {i18n.t('Assign')}
        </Button>
      </Flex>
    </Flex>
  );

  return (
    <Dialog
      isOpen={isOpen}
      closeOnEscape
      onOpenChange={onCancel}
      title={i18n.t('Assign this device')}
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

Modal.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  getUsers: func.isRequired,
  maxResults: number,
  assignComputerUser: func.isRequired,
  computerRecord: object.isRequired,
  onSave: func,
};

Modal.defaultProps = {
  maxResults: 50,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsers: callGetUsers,
      assignComputerUser: callAssignComputerUser,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Modal);
