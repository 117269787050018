import { Button, Flex, Text, styled } from '@kandji-inc/nectar-ui';
import isEqual from 'lodash/isEqual';
import { useFlags } from 'src/config/feature-flags';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import EDRDetectionDateFilter from 'src/features/edr/common/components/Filters/EDRDetectionDateFilter';
import { i18n } from 'src/i18n';
import { SeverityMultiSelect } from '../../common/table/filters/severity-multi-select';
import { SoftwareMultiSelect } from '../../common/table/filters/software-multi-select';
import {
  GLOBAL_FILTER_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS,
  defaultAllVulnerabilitiesFilter,
} from '../../constants';
import useVulnerability from '../../store';
import { VulnerabilityType } from '../../vulnerability.types';

const Divider = styled(Flex, {
  width: '1px',
  height: '24px',
  backgroundColor: '$neutral20',
  borderRadius: '$rounded',
});

const FilterBar = () => {
  const [allVulnerabilitiesFilter, setAllVulnerabilitiesFilter] =
    useVulnerability((state) => [
      state.allVulnerabilitiesFilter,
      state.setAllVulnerabilitiesFilter,
    ]);

  const { latestDetected, severity, softwareName } = allVulnerabilitiesFilter;

  const {
    'vulnerability-management-operating-system-detections-03-26-2025':
      showOperatingSystemFilter,
  } = useFlags();

  return (
    <Flex pl5 pr5 pt2 pb3 alignItems="center" gap="sm">
      <Text variant="secondary" size="1">
        {i18n.t('Viewing')}
      </Text>

      <EDRDetectionDateFilter
        filter={latestDetected}
        label=""
        options={GLOBAL_FILTER_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS}
        onChange={
          /* istanbul ignore next */ (_, fields: DetectionDateFilterFields) =>
            setAllVulnerabilitiesFilter({ latestDetected: fields })
        }
        showClear={false}
        buttonVariant="subtle"
      />

      <Divider />

      <SoftwareMultiSelect
        software={softwareName.appName}
        type={VulnerabilityType.application}
        setSoftware={
          /* istanbul ignore next */ (softwareName) =>
            setAllVulnerabilitiesFilter({
              softwareName: {
                ...allVulnerabilitiesFilter.softwareName,
                appName: softwareName,
              },
            })
        }
      />

      <Divider />

      {showOperatingSystemFilter && (
        <>
          <SoftwareMultiSelect
            software={softwareName.osName}
            type={VulnerabilityType.operatingSystem}
            setSoftware={
              /* istanbul ignore next */ (softwareName) =>
                setAllVulnerabilitiesFilter({
                  softwareName: {
                    ...allVulnerabilitiesFilter.softwareName,
                    osName: softwareName,
                  },
                })
            }
          />
          <Divider />
        </>
      )}

      <SeverityMultiSelect
        severity={severity}
        setSeverity={
          /* istanbul ignore next */ (severity) =>
            setAllVulnerabilitiesFilter({ severity })
        }
        subtle
      />

      {!isEqual(allVulnerabilitiesFilter, defaultAllVulnerabilitiesFilter) && (
        <Button
          variant="default"
          compact
          icon={{ name: 'fa-xmark-small', position: 'left' }}
          onClick={() =>
            setAllVulnerabilitiesFilter(defaultAllVulnerabilitiesFilter)
          }
        >
          {i18n.t('Clear all')}
        </Button>
      )}
    </Flex>
  );
};

export { FilterBar };
