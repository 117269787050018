/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type { Software } from '../vulnerability.types';

const useGetVulnerabilitySoftware = (
  vulnerabilityId: string,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['vulnerability-software', vulnerabilityId, ...keys],
    queryFn: () =>
      vulnerabilityApi(`v1/vulnerabilities/${vulnerabilityId}/software`).get(
        params,
      ),
  });

  const data = apiRes?.data;
  const software: Software[] = data?.results || [];
  const count = data?.total;

  return { software, count, isLoading };
};

export { useGetVulnerabilitySoftware };
