import { Flex, Icon } from '@kandji-inc/nectar-ui';
import { memo } from 'react';
import ThreatListHighlightedText from '../../../common/components/ThreatListHighlightedText';
import ThreatListStatusBadge from '../../../common/components/ThreatListStatusBadge';
import TableActionsDropdown from '../../../common/components/ThreatListTable/TableActionsDropdown';
import ThreatListTextCopyButton from '../../../common/components/ThreatListTextCopyButton';
import {
  getClassificationIcon,
  getClassificationLabel,
  getStatusColor,
  getStatusIcon,
  getStatusLabel,
} from '../../../common/utils/constants-getters';
import type {
  ActionsCellProps,
  AssociatedItemCellProps,
  ClassificationCellProps,
  StatusCellProps,
  ThreatIdCellProps,
} from '../types';

export const ThreatIdCell = memo(
  ({ item, isFileDetectionType, query }: ThreatIdCellProps) => (
    <Flex gap="xs" alignItems="center" css={{ '& svg': { flexShrink: 0 } }}>
      {isFileDetectionType ? (
        <>
          <Icon name="file" size="sm" />
          <ThreatListTextCopyButton
            text={item.file_hash}
            highlighted={query}
            css={{ pr: '$3' }}
          />
        </>
      ) : (
        <>
          <Icon name="gear" size="sm" />
          <ThreatListHighlightedText search={query}>
            {item.file_hash}
          </ThreatListHighlightedText>
        </>
      )}
    </Flex>
  ),
);

export const AssociatedItemCell = memo(
  ({ value, query }: AssociatedItemCellProps) => (
    <ThreatListHighlightedText search={query}>
      {value}
    </ThreatListHighlightedText>
  ),
);

export const ClassificationCell = memo(
  ({ classification }: ClassificationCellProps) => (
    <Flex gap="xs" alignItems="center" css={{ '& svg': { flexShrink: 0 } }}>
      <Icon name={getClassificationIcon(classification)} size="sm" />
      {getClassificationLabel(classification, true)}
    </Flex>
  ),
);

export const StatusCell = memo(({ status }: StatusCellProps) => (
  <ThreatListStatusBadge
    color={getStatusColor(status)}
    icon={getStatusIcon(status)}
  >
    {getStatusLabel(status)}
  </ThreatListStatusBadge>
));

export const ActionsCell = memo(({ actionOptions }: ActionsCellProps) => {
  return (
    <TableActionsDropdown options={actionOptions} testId="action-ellipsis" />
  );
});
