import { Portal } from '@radix-ui/react-popover';
/* istanbul ignore file */
import { createReducer } from 'redux-create-reducer';
import FeatureFlags from 'src/config/feature-flags';
import { account } from '../_actions/action-types';

export const initialState = {
  accountReady: false,
  tenantOverLicenseLimit: false,
  user: {
    id: null,
    role: null,
    email: null,
    locale: null,
    settings: {},
  },
  company: {
    billing_type: null,
    onboarding: null,
    company_size: null,
    estimated_macs: null,
    industry: null,
    internal_note: null,
    is_mdm: false,
    name: null,
    owner: null,
    weekly_report: true,
    instance_type: 'standard',
    billing_past_due: false,
    billing_past_due_enabled_on: '',
    locale: null,
    enrolledMacDevices: 0,
    enrolledIosIpadosDevices: 0,
    enrolledAppletvDevices: 0,
    enrolledVisionproDevices: 0,
    enabled_platforms: {},
  },
};

export default createReducer(initialState, {
  [account.ACCOUNT_FETCHING](state) {
    return { ...state, accountReady: false };
  },
  [account.ACCOUNT_FETCHED](state) {
    FeatureFlags.setCompanyContext({
      key: state.company.subdomain,
      instance_type: state.company.instance_type,
      billing_type: state.company.billing_type,
      join_date: state.company.created_at * 1000, // backend provides this value in seconds, but LD needs it in milliseconds
      // @TODO The backend sends database_host, but not sure if the frontend has access to this info?
      // database_host:
    });

    // initialize pendo user for analytics
    const { user, company } = state;

    const companyAccount = {
      id: company.auth0_organization_id,
      tenant_id: company.id,
      company_size: company.company_size,
      estimated_macs: company.estimated_macs,
      industry: company.industry,
      name: company.subdomain,
      weekly_report: company.weekly_report,
      instance_type: company.instance_type,
      is_enrollment_portal_active: company.is_enrollment_portal_active,
      hide_ade_endpoints: company.hide_ade_endpoints,
      is_vpp_auto_update_apps: company.is_vpp_auto_update_apps,
      vpp_info: company.vpp_info,
      partner: company.partner,
      is_dep: company.is_dep,
      subdomain: company.subdomain,
      billing_type: company.billing_type,
      created_at: company.created_at,
      dep_info: company.dep_info,
      mdm_info: company.mdm_info,
      trial_bannder_data: company.trial_banner_data,
      renewal_banner_data: company.renewal_banner_data,
      max_devices: company.max_devices,
      plan_type: company.plan_type,
      max_devices_per_platform: company.max_devices_per_platform,
      hubspot_id: company.hubspot_id,
      feature_configuration: company.feature_configuration,
      enabled_platforms: company.enabled_platforms,
      self_service_configuration_all_customName:
        company.self_service_configuration?.all?.customName,
      self_service_configuration_all_isCustomNameSelected:
        company.self_service_configuration?.all?.isCustomNameSelected,
      self_service_configuration_all_branding_text:
        company.self_service_configuration?.branding_text,
      self_service_configuration_all_branding_subtext:
        company.self_service_configuration?.branding_subtext,
      auth0_organization_id: company.auth0_organization_id,
      billing_past_due:
        company.feature_configuration?.billing_past_due?.enabled || false,
      billing_past_due_enabled_on:
        company.feature_configuration?.billing_past_due
          ?.billing_past_due_enabled_on || '',
      over_device_limit: state.tenantOverLicenseLimit || false,
      enrolled_mac_devices: company.enrolledMacDevices,
      enrolled_ios_ipados_devices: company.enrolledIosIpadosDevices,
      enrolled_appletv_devices: company.enrolledAppletvDevices,
      enrolled_visionpro_devices: company.enrolledVisionproDevices,
    };

    const isFromTrialWizard =
      ['trial', 'automated_trial'].includes(company.billing_type) &&
      company.onboarding?.experience === 'v1';
    const pendoId = isFromTrialWizard
      ? `${company.subdomain}_${user.email}`
      : user.id;

    const visitor = {
      id: pendoId,
      timezone: user.settings.timezone,
      is_active: user.is_active,
      role: user.role,
      type: user.type,
      created_at: user.created_at,
    };

    pendo.initialize({
      visitor,
      account: companyAccount,
    });

    return { ...state, accountReady: true };
  },
  [account.ACCOUNT_USER_FETCHED](state, action) {
    return {
      ...state,
      user: action.user,
      tenantOverLicenseLimit: action.tenantOverLicenseLimit,
    };
  },
  [account.ACCOUNT_USER_UPDATED](state, action) {
    return {
      ...state,
      user: {
        ...state.user,
        ...action.updates,
      },
    };
  },
  [account.ACCOUNT_COMPANY_FETCHED](state, action) {
    return {
      ...state,
      company: {
        ...state.company,
        ...action.company,
      },
    };
  },
  [account.ACCOUNT_LOCALE_UPDATED](state, action) {
    return {
      ...state,
      user: {
        ...state.user,
        locale: action.locale,
      },
    };
  },
  [account.ACCOUNT_LOGOUT]() {
    FeatureFlags.unsetCompanyContext();

    return initialState;
  },
  [account.ACCOUNT_COMPUTER_LIST_FETCHED](state, action) {
    const { ipados, ios, tvos, visionos, macos } = action.enrolledDevices;
    return {
      ...state,
      company: {
        ...state.company,
        enrolledMacDevices: macos,
        enrolledIosIpadosDevices: ipados + ios,
        enrolledAppletvDevices: tvos,
        enrolledVisionproDevices: visionos,
      },
    };
  },
});
