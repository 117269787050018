import { useSelector } from 'react-redux';
import { store } from 'src/app/_store/rootStore';
import { FLAGS } from 'src/app/common/constants';
import featureFlags from 'src/config/feature-flags';

type PlatformKeys = 'android' | 'windows';

export function isPlatformEnabled(
  enabled_platforms: { [key in PlatformKeys]?: boolean },
  platform: PlatformKeys,
) {
  return enabled_platforms[platform] ?? false;
}

export function getPlatformsFromConfig(enabled_platforms: any) {
  const { axp, wxp } = featureFlags.getFlag(FLAGS.ORANGE_AXP_WXP) ?? {};

  const androidEnabled = isPlatformEnabled(enabled_platforms, 'android') && axp;
  const windowsEnabled = isPlatformEnabled(enabled_platforms, 'windows') && wxp;

  return {
    crossPlatformFlag: axp || wxp,
    crossPlatformEnabled: androidEnabled || windowsEnabled,
    androidEnabled: androidEnabled,
    windowsEnabled: windowsEnabled,
  };
}

/* istanbul ignore next */
export function getEnabledPlatforms() {
  const { account } = store.getState();
  const { enabled_platforms } = account.company;

  return getPlatformsFromConfig(enabled_platforms);
}

/* istanbul ignore next */
export function useGetEnabledPlatforms(): {
  crossPlatformFlag: boolean;
  crossPlatformEnabled: boolean;
  androidEnabled: boolean;
  windowsEnabled: boolean;
} {
  const { company } = useSelector((state: any) => ({
    company: state.account?.company,
  }));

  const { enabled_platforms } = company;

  return getPlatformsFromConfig(enabled_platforms);
}
