/* istanbul ignore file */
import {
  ButtonGroup,
  DialogV2 as Dialog,
  Flex,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';
import type { Dispatch, SetStateAction } from 'react';
import { i18n } from 'src/i18n';

export function SupportDialog(props: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  toggleContactSales: Dispatch<
    SetStateAction<{ isOpen: boolean; error: null }>
  >;
}) {
  return (
    <Dialog
      css={{
        width: '480px',
      }}
      isOpen={props.isOpen}
      closeOnOutsideClick
      closeOnEscape
      onOpenChange={props.setIsOpen}
      title={i18n.t('How can we help you today?')}
      headerContent={
        <>
          <Text variant="secondary" css={{ margin: '$4 0' }}>
            {i18n.t('Please select what best fits your current situation.')}
          </Text>
        </>
      }
      content={
        <SupportDialogContent
          toggleContactSales={props.toggleContactSales}
          toggleSupportDialog={props.setIsOpen}
        />
      }
    />
  );
}

function SupportDialogContent({
  toggleContactSales,
  toggleSupportDialog,
}: {
  toggleSupportDialog: (isOpen: boolean) => void;
  toggleContactSales: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      error: null;
      openedFromSupportDialog: boolean;
    }>
  >;
}) {
  return (
    <Flex flow="column" gap="sm" css={{ paddingBottom: '2rem' }}>
      <Text
        variant="secondary"
        size={3}
        css={{ fontWeight: '$medium', marginBottom: '1rem' }}
      >
        {i18n.t('Here are some options...')}
      </Text>

      <ButtonGroup
        compact
        css={{
          gap: '0.5rem',
          alignItems: 'start',
          height: '9.5rem',
          '& > button': {
            flex: 1,
            justifyContent: 'start',
            alignItems: 'start',
            paddingTop: '1rem',
            height: '100%',
            borderRadius: '8px !important',
          },
          '& > button:hover': {
            boxShadow: 'var(--shadows-primary_focus)',
            borderColor: 'var(--colors-input_default_border_active)',
          },
          '& > button:focus': {
            boxShadow: 'none',
            borderColor: 'none',
          },
        }}
        variant="input"
        data-testid="support-selector"
        buttons={[
          {
            label: (
              <Flex flow="column" gap="sm">
                <Icon name="magnifying-glass-dollar" size="md" />
                <Text css={{ fontWeight: '$medium' }}>
                  {i18n.t('Pricing question')}
                </Text>
                <Text variant="description">
                  {i18n.t('I have questions about pricing.')}
                </Text>
              </Flex>
            ),
            onClick: () => {
              window.open('https://www.kandji.io/pricing/', '_blank');
            },
          },
          {
            label: (
              <Flex flow="column" gap="sm">
                <Icon name="screwdriver-wrench" size="md" />
                <Text css={{ fontWeight: '$medium' }}>
                  {i18n.t('How to setup Kandji')}
                </Text>
                <Text variant="description">
                  {i18n.t('I need help setting up my Kandji account.')}
                </Text>
              </Flex>
            ),
            onClick: () => {
              window.open(
                'https://www.kandji.io/academy/5-quick-lessons/',
                '_blank',
              );
            },
          },
          {
            label: (
              <Flex flow="column" gap="sm">
                <Icon name="message-question" size="md" />
                <Text css={{ fontWeight: '$medium' }}>
                  {i18n.t('Talk to sales')}
                </Text>
                <Text variant="description">
                  {i18n.t('I would like to connect directly with sales.')}
                </Text>
              </Flex>
            ),
            onClick: () => {
              toggleContactSales({
                isOpen: true,
                error: null,
                openedFromSupportDialog: true,
              });
              toggleSupportDialog(false);
            },
          },
        ]}
      />
    </Flex>
  );
}
