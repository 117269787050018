import React, { useState } from 'react';

import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text } from '@kandji-inc/nectar-ui';

import { i18n } from 'i18n';
import { logOutUser } from 'src/app/components/computer/api';

const ComputerLogOutIPadUser = (props) => {
  const { info, getComputer, onHide } = props;
  const { computerId } = info;

  const [isLoading, setIsLoading] = useState(false);

  const onLogOutUser = () => {
    setIsLoading(true);
    logOutUser(computerId)
      .then(() => {
        getComputer(computerId);
        onHide();
        setIsLoading(false);
        toaster(i18n.t('Command initiated'));
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.common.error());
      });
  };

  const content = (
    <Text>
      {i18n.t(
        'Upon logout, MDM commands might not work for up to 2 minutes on the Shared iPad.',
      )}
    </Text>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        {i18n.t('Cancel')}
      </Button>
      <Button variant="primary" loading={isLoading} onClick={onLogOutUser}>
        {i18n.t('Log Out User')}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      onOpenChange={onHide}
      title={i18n.t('Log Out User')}
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerLogOutIPadUser;
