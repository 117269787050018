import type { IconNames } from '@kandji-inc/nectar-ui';

import { i18n } from 'src/i18n';
import type {
  LibraryItem,
  LibraryItemFilterSort,
} from './blueprint-flow.types';

const NODE_TYPES = {
  preStart: 'preStart',
  start: 'start',
  end: 'end',
  conditional: 'conditional',
  router: 'router',
  assignment: 'assignment',
};

const EDGE_TYPES = {
  root: 'root',
  branch: 'branch',
  placeholder: 'placeholder',
};

const ASSIGNMENT_TYPES = {
  if: 'if',
  else: 'else',
  elseif: 'elseif',
};

const DESCENDANT_TYPES = {
  assignment: 'assignment',
  conditional: 'conditional',
  router: 'router',
  edge: 'edge',
};

const ORIGIN_TYPES: {
  bank: LibraryItem['origin'];
  graph: LibraryItem['origin'];
} = {
  bank: 'bank',
  graph: 'graph',
};

const NOTIFICATION_POSITIONS = {
  top: 'top',
  'bottom-left': 'bottom-left',
};

const FLOW_PATHS = () => ({
  assignments: { path: 'assignments', label: i18n.t('Assignment Map') },
  library: { path: 'library', label: i18n.t('Library Items') },
  parameters: { path: 'parameters', label: i18n.t('Parameters') },
  devices: { path: 'devices', label: i18n.t('Devices') },
  activity: { path: 'activity', label: i18n.t('Activity') },
  notes: { path: 'notes', label: i18n.t('Notes') },
});

const ONLY_ALL_DEVICES_LIBRARY_ITEMS = ['dep-profile'];

const deviceFamiliesSelectOptions = [
  {
    key: 'MAC',
    label: 'Mac',
    value: 'mac',
  },
  {
    key: 'IPHONE',
    label: 'iPhone',
    value: 'iphone',
  },
  {
    key: 'IPAD',
    label: 'iPad',
    value: 'ipad',
  },
  {
    key: 'TV',
    label: 'Apple TV',
    value: 'tv',
  },
  {
    key: 'VISION',
    label: 'Vision',
    value: 'vision',
  },
];

const deviceFamilySelectOptionToRunsOn = {
  mac: 'runsOnMac',
  iphone: 'runsOnIphone',
  ipad: 'runsOnIpad',
  tv: 'runsOnTv',
  vision: 'runsOnVision',
};

const deviceToIcon: Record<string, IconNames> = {
  mac: 'sf-desktop-computer',
  ipad: 'sf-ipad-landscape',
  iphone: 'sf-iphone',
  tv: 'sf-apple-tv',
  vision: 'sf-apple-vision-pro',
};

const libraryItemSortOptions = (
  includeTypeSort: boolean = true,
): Array<{
  label: string;
  value: LibraryItemFilterSort;
}> => [
  ...(includeTypeSort
    ? [
        {
          label: i18n.t('Library Item type (A-Z)'),
          value: 'li_type_az' as LibraryItemFilterSort,
        },
        {
          label: i18n.t('Library Item type (Z-A)'),
          value: 'li_type_za' as LibraryItemFilterSort,
        },
      ]
    : []),
  {
    label: i18n.t('Library Item name (A-Z)'),
    value: 'li_name_az',
  },
  {
    label: i18n.t('Library Item name (Z-A)'),
    value: 'li_name_za',
  },
  {
    label: i18n.t('Newest Created'),
    value: 'newest',
  },
  {
    label: i18n.t('Oldest Created'),
    value: 'oldest',
  },
  {
    label: i18n.t('Recently Updated'),
    value: 'recently_updated',
  },
];

// istanbul ignore next
const libraryItemStateOptions = () => [
  {
    label: i18n.t('Active'),
    value: 'active',
  },
  {
    label: i18n.t('Inactive'),
    value: 'inactive',
  },
];

const sortMap: Record<LibraryItemFilterSort, string> = {
  li_name_az: '+name',
  li_name_za: '-name',
  newest: '-created_at',
  oldest: '+created_at',
  li_type_az: '+name',
  li_type_za: '-name',
  recently_updated: '-updated_at',
};

const deviceKinds = ['mac', 'ipad', 'iphone', 'tv', 'vision'] as const;

const SIDEBAR_DOCKED_WIDTH = 256;
const SIDEBAR_WIDTH = 77;
const SIDEBAR_TOASTER_PADDING = 12;

const GRAPH_CONTAINER_ID = 'assignment-map-graph-container';

const MIN_ZOOM = 0.1;
const MAX_ZOOM = 2;

const PENDO_ID_PREFIX = 'for-pendo__';
const PENDO_IDS = {
  allDevicesNode: `${PENDO_ID_PREFIX}am-all-devices-node`,
  startFromScratch: `${PENDO_ID_PREFIX}start-from-scratch`,
  createModalNextBtn: `${PENDO_ID_PREFIX}create-modal-next-btn`,
  editMapBtn: `${PENDO_ID_PREFIX}edit-map-btn`,
};

export {
  NODE_TYPES,
  EDGE_TYPES,
  ASSIGNMENT_TYPES,
  DESCENDANT_TYPES,
  ORIGIN_TYPES,
  NOTIFICATION_POSITIONS,
  FLOW_PATHS,
  ONLY_ALL_DEVICES_LIBRARY_ITEMS,
  deviceFamiliesSelectOptions,
  deviceFamilySelectOptionToRunsOn,
  deviceToIcon,
  libraryItemSortOptions,
  libraryItemStateOptions,
  sortMap,
  deviceKinds,
  SIDEBAR_DOCKED_WIDTH,
  SIDEBAR_WIDTH,
  SIDEBAR_TOASTER_PADDING,
  GRAPH_CONTAINER_ID,
  MIN_ZOOM,
  MAX_ZOOM,
  PENDO_IDS,
};
