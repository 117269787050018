import getDateRange from '../../../../common/utils/get-date-range';
import getSortBy from '../../../../common/utils/get-sort-by';
import type {
  FiltersState,
  GetBehavioralThreatGroupRequestParams,
  PaginationState,
  SortColumnState,
  ThreatClassification,
  ThreatSortByParamValue,
} from '../../../../threat.types';

const toRequestFormat = (
  filters: FiltersState,
  sort: SortColumnState,
  pagination: PaginationState,
): GetBehavioralThreatGroupRequestParams => {
  const limit = pagination.pageSize ? String(pagination.pageSize) : undefined;
  const offset = limit
    ? String(((pagination.page || 1) - 1) * Number(limit))
    : undefined;
  const classifications =
    filters.classification?.toLowerCase() as ThreatClassification;
  const statuses = filters.status
    ? filters.status?.map((item) => item.toLowerCase()).join(',')
    : undefined;
  const sortBy = getSortBy(sort) as ThreatSortByParamValue;
  const { from, to } = getDateRange(filters.detectionDate);
  const term = filters.query || undefined;
  const mitreIds = filters.mitre?.join(',') || undefined;

  const filtersTransformedToApi: GetBehavioralThreatGroupRequestParams = {
    limit,
    offset,
    sort_by: sortBy,
    statuses,
    classifications,
    search_term: term,
    detection_from: from,
    detection_to: to,
    mitre_ids: mitreIds,
  };

  return filtersTransformedToApi;
};

export default toRequestFormat;
