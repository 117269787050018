import { z } from 'zod';

const ThreatsGroupMitreTechniquesSchema = z.array(
  z.object({
    technique_id: z.string(),
    technique_name: z.string(),
  }),
);

export default ThreatsGroupMitreTechniquesSchema;
