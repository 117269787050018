import { useQuery } from '@tanstack/react-query';
import { api } from 'src/app/api/base';
import { URL_COMPUTERS } from 'src/app/api/urls';
import { WindowsDeviceDetailRootSchema } from 'src/schemas/device/windows-device-detail.schema';

export const useGetDeviceDetails = (deviceId: string) =>
  useQuery({
    queryKey: ['device-details', deviceId],
    queryFn: async () => {
      const apiRes = await api(`${URL_COMPUTERS}${deviceId}/details`).get({});

      const { details } = apiRes.data;

      // TODO: Apple/Android
      const { data, success, error } =
        WindowsDeviceDetailRootSchema.partial().safeParse(details);

      if (!success) {
        console.error(
          `Failed to translate complete device detail: ${JSON.stringify(
            error.errors,
          )}}`,
        );
      }

      return data;
    },
  });
