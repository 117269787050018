import { wasInactiveLogout } from 'app/auth';
import { i18n } from 'i18n';
import PropTypes from 'prop-types';
/* istanbul ignore file */
import React, { Component, useContext, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';

import { Button, Flex } from '@kandji-inc/bumblebee';
import { Flex as NectarFlex } from '@kandji-inc/nectar-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountContext } from 'src/contexts/account';
import Loader from 'src/theme/components/atoms/Loader';

import Auth0Login from '../app/components/common/Auth0Login';
import history from '../app/router/history';
import stackedLogo from '../assets/img/new_icons/kandji_logo_stacked_dark.svg';
import { useAuth0 } from '../auth0';

export class SignIn extends Component {
  componentDidMount() {
    const { loggedIn } = this.props;
    if (loggedIn) {
      history.push('/');
    }
  }

  render() {
    const { hasAuth0Config, errorMessage } = this.props;

    return (
      <Flex
        align="center"
        direction="col"
        // override global margin bottom on body
        style={{ minHeight: '100vh', marginBottom: '-48px' }}
      >
        <Flex align="center" direction="col" className="mt-5">
          <img
            style={{ marginBottom: '48px' }}
            src={stackedLogo}
            className="img-responsive center-block"
            width="175"
            height="120"
            alt={i18n.t('Kandji App')}
          />
          <Auth0Login disabled={!hasAuth0Config} />

          {/* Error during current auth0 authentication */}
          {errorMessage && (
            <p className="b-txt b-txt--error" style={{ textAlign: 'center' }}>
              {errorMessage}
            </p>
          )}

          <Button kind="link" onClick={() => history.push('/forgot-password')}>
            {i18n.t('Forgot password?')}
          </Button>
        </Flex>
        <Flex
          align="end"
          direction="row"
          justify="center"
          style={{
            flex: '1',
            width: '100%',
          }}
        >
          <Flex
            justify="center"
            direction="row"
            gapType="gap2"
            style={{
              padding: '20px 0',
              borderTop: '1px solid var(--b-primary-marengo-ultralight)',
              width: '100%',
            }}
          >
            <FooterLink
              text={i18n.t('Privacy Policy')}
              url="https://www.kandji.io/legal/privacy/"
            />
            <FooterLink
              text={i18n.t('Cookie Policy')}
              url="https://www.kandji.io/legal/cookie-policy/"
            />
            <FooterLink
              text={i18n.t('California Privacy Notice')}
              url="https://www.kandji.io/legal/ca-privacy-notice/"
            />
            <FooterLink
              text={i18n.t('Accessibility')}
              url="https://www.kandji.io/legal/accessibility-statement/"
            />
            <FooterLink
              text={i18n.t('Legal')}
              url="https://www.kandji.io/legal/"
            />
            <FooterLink
              text={i18n.t('Patent')}
              url="https://www.kandji.io/legal/patents/"
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

const FooterLink = ({ text, url }) => (
  <a target="_blank" rel="noreferrer" className="b-txt-ctrl9" href={`${url}`}>
    {text}
  </a>
);

FooterLink.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const mapStateToProps = ({ account }) => ({
  loggedIn: !!account.user.id,
});

SignIn.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  hasAuth0Config: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

const withAuth0Data = (BaseComponent) => (props) => {
  let errorMessage;

  const { error: auth0Error } = useAuth0();
  const hasAuth0Config = useSelector((state) => !!state.auth0.clientId);
  const hasAuth0ClientError = useSelector((state) => state.auth0.error);
  const wasInactive = wasInactiveLogout();

  if (hasAuth0ClientError) {
    errorMessage = i18n.t(
      'Unable to find your account. Please contact Kandji support.',
    );
  } else if (auth0Error?.message) {
    errorMessage = (
      <span>
        {i18n.t(
          'It looks like we&apos;ve encountered an error during authentication.',
        )}{' '}
        <br />
        {i18n.t(
          'Please reach out to your IT administrator or Kandji Support at {email} if the issue persists.',
          { email: 'support@kandji.io' },
        )}
      </span>
    );
  } else if (wasInactive && document.referrer) {
    errorMessage = i18n.t('You were logged out due to inactivity.');
  }

  return (
    <BaseComponent
      {...props}
      hasAuth0Config={hasAuth0Config}
      errorMessage={errorMessage}
    />
  );
};

export const RedirectToAuth0SigninFlow = () => {
  const { userId, auth0 } = useContext(AccountContext);
  const history = useHistory();
  const { state } = useLocation<{ from: string }>();
  const { error: auth0Error, loginWithRedirect, isLoading } = useAuth0();
  const wasInactive = wasInactiveLogout();
  const { clientId } = useSelector((state) => state.auth0);

  const hasAuth0Config = auth0?.clientId;
  const hasAuth0ClientError = auth0?.error;
  const isLoggedIn = Boolean(userId);

  useEffect(() => {
    if (clientId && !isLoggedIn && hasAuth0Config && !isLoading) {
      const hasBasicError =
        hasAuth0ClientError &&
        i18n.t('Unable to find your account. Please contact Kandji support.');
      const hasAuth0ErrorMessage =
        auth0Error?.message &&
        i18n.t(
          `It looks like we've encountered an error during authentication. Please reach out to your IT administrator or Kandji Support at {email} if the issue persists.`,
          { email: 'support@kandji.io' },
        );

      const wasInactiveLogout =
        wasInactive &&
        document.referrer &&
        i18n.t('You were logged out due to inactivity.');
      const hasAnError =
        hasBasicError || hasAuth0ErrorMessage || wasInactiveLogout;

      setTimeout(() =>
        loginWithRedirect({
          appState: {
            returnTo: state?.from,
          },
          ...(hasAnError ? { 'ext-error': hasAnError } : {}),
        }),
      );
    }

    if (isLoggedIn) {
      history.push('/');
    }
  }, [isLoggedIn, hasAuth0Config, isLoading]);

  return (
    <NectarFlex wFull hFull alignItems="center" justifyContent="center">
      <Loader type="splash" />
    </NectarFlex>
  );
};

export default compose(connect(mapStateToProps), withAuth0Data)(SignIn);
