import { useContext } from 'react';
import { AccountContext } from 'src/contexts/account';
import { VulnerabilitiesTable } from './all-vulnerabilities/vulnerabilities-table';
import { useFetchIcons } from './hooks/use-fetch-icons';
import { TrialPage } from './trial-page';

const MainVulnerabilityPage = () => {
  useFetchIcons();

  const { currentCompany } = useContext(AccountContext);
  const { feature_configuration } = currentCompany;
  const isVulnSkuEnabled =
    feature_configuration?.vulnerability_management?.enabled;

  isVulnSkuEnabled
    ? /* istanbul ignore next - not testing pendo */ pendo?.track(
        '[Vulnerability Management] Homepage accessed',
      )
    : /* istanbul ignore next - not testing pendo */ pendo?.track(
        '[Vulnerability Management] Trial page accessed',
      );

  return isVulnSkuEnabled ? <VulnerabilitiesTable /> : <TrialPage />;
};

export { MainVulnerabilityPage };
