import { Button, Dialog, Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';

const PromptTrialDialog = ({
  isOpen,
  onClose,
  onAccept,
  isRequesting,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  isRequesting: boolean;
}) => {
  const title = (
    <Heading size="3">{i18n.t('Trial Request Confirmation')}</Heading>
  );

  const content = (
    <Flex flow="column" gap="lg" css={{ width: '512px' }}>
      <Text>
        {i18n.t(
          'To proceed with your EDR trial request, click Confirm Request. No further details will be needed. Our Customer Success team will be reaching out shortly to help you get started and ensure you have all the support needed to make the most of your trial.',
        )}
      </Text>
    </Flex>
  );

  const footer = (
    <Flex justifyContent="end" mx3>
      <Flex gap="sm">
        <Button variant="subtle" onClick={onClose}>
          {i18n.t('Cancel')}
        </Button>
        <Button variant="primary" onClick={onAccept} loading={isRequesting}>
          {
            /* istanbul ignore next */ isRequesting
              ? i18n.t('Submitting Request...')
              : i18n.t('Confirm Request')
          }
        </Button>
      </Flex>
    </Flex>
  );

  return (
    <Dialog
      isOpen={isOpen}
      closeOnEscape
      onOpenChange={onClose}
      title={title}
      content={content}
      footer={footer}
    />
  );
};

export default PromptTrialDialog;
