import { Box, Flex, Icon, styled } from '@kandji-inc/nectar-ui';
import EDRTooltip from '../../../../common/components/EDRTooltip';

export type HeaderWithTooltipProps = {
  text: string;
  tooltip: string | undefined;
  tooltipWidth?: number | undefined;
};

const TextOverflowStyle = styled(Box, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const IconWrapper = styled(Box, {
  pl: '$1',
  '& > svg': { height: 16, width: 16, verticalAlign: 'top' },
});

const HeaderWithTooltip = ({
  text,
  tooltip,
  tooltipWidth,
}: HeaderWithTooltipProps) => (
  <Flex alignItems="center">
    <TextOverflowStyle title={text}>{text}</TextOverflowStyle>
    {tooltip && (
      <EDRTooltip
        label={tooltip}
        css={{ maxWidth: tooltipWidth || undefined, zIndex: 20 }}
      >
        <IconWrapper>
          <Icon name="circle-info" size="sm" />
        </IconWrapper>
      </EDRTooltip>
    )}
  </Flex>
);

export default HeaderWithTooltip;
