import { formatNumber } from '@kandji-inc/nectar-i18n';
import { Flex, Icon, Link, Text, Tooltip, styled } from '@kandji-inc/nectar-ui';
import { links } from 'src/app/common/constants';
import type {
  Software,
  SoftwareVersion,
} from 'src/features/edr/vulnerability/vulnerability.types';
import { i18n } from 'src/i18n';

type SoftwareVersionItemProps = {
  software: Software;
  softwareVersion: SoftwareVersion;
};

const DetailValue = styled(Text, {
  fontWeight: '$medium',
});

const SoftwareVersionItem = (props: SoftwareVersionItemProps) => {
  const { software, softwareVersion } = props;
  const { name } = software;
  const {
    latest_detection_date: latestDetectionDate,
    blueprints,
    version,
    devices_impacted: devicesImpacted,
  } = softwareVersion;

  return (
    <Flex flow="column" gap="md">
      <Flex gap="sm" alignItems="center">
        <Text variant="secondary" css={{ fontWeight: '$medium' }}>
          {name}
        </Text>
        <Flex
          css={{
            border: '1px solid $neutral30',
            borderRadius: '$rounded-sm',
            padding: '2px $1',
            gap: '2px',
          }}
        >
          <Text
            size="1"
            variant="description"
            css={{
              fontWeight: '$medium',
            }}
          >
            {version}
          </Text>
        </Flex>
      </Flex>
      <Flex css={{ gap: '70px' }}>
        <Flex flow="column" gap="sm">
          <Text variant="secondary">{i18n.t('Last detected')}</Text>
          <DetailValue>
            {i18n.format.datetime(new Date(latestDetectionDate))}
          </DetailValue>
        </Flex>

        <Flex flow="column" gap="sm">
          <Text variant="secondary">{i18n.t('Blueprint')}</Text>
          <Flex gap="xs">
            {blueprints.slice(0, 1).map((bp) => (
              <Link
                key={bp.id}
                href={`${links.blueprints}/${bp.id}`}
                target="_blank"
                rel="noopener noreferrer"
                variant="subtle"
              >
                <DetailValue>
                  {bp.name}
                  {blueprints.length > 1 && ', '}
                </DetailValue>
              </Link>
            ))}
            {blueprints.length > 1 && (
              <Tooltip
                side="bottom"
                theme="light"
                interactive
                css={{ zIndex: 2, padding: '$3 $4', maxHeight: '190px' }}
                content={
                  <Flex flow="column" css={{ gap: '6px' }}>
                    {blueprints.slice(1).map((bp) => (
                      <Flex key={bp.id} alignItems="center" gap="xs">
                        <Icon name="kandji-blueprint" size="xs" />
                        <Link
                          href={`${links.blueprints}/${bp.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          variant="subtle"
                        >
                          <Text>{bp.name}</Text>
                        </Link>
                      </Flex>
                    ))}
                  </Flex>
                }
              >
                <DetailValue>
                  {i18n.t('+{count}', {
                    count: formatNumber(blueprints.length - 1),
                  })}
                </DetailValue>
              </Tooltip>
            )}
          </Flex>
        </Flex>

        <Flex flow="column" gap="sm">
          <Text variant="secondary">{i18n.t('Devices impacted')}</Text>
          <Text css={{ fontWeight: '$medium' }}>
            {formatNumber(devicesImpacted)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { SoftwareVersionItem };
