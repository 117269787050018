import { formatNumber } from '@kandji-inc/nectar-i18n';
import { Flex, Loader, Text } from '@kandji-inc/nectar-ui';
import { useEffect, useRef, useState } from 'react';
import { i18n } from 'src/i18n';
import { useShallow } from 'zustand/react/shallow';
import { SoftwareIcon } from '../../../common/software-icon';
import { getNoVulnerabilitiesCopy } from '../../../helpers';
import { useGetTopSoftwareWithVulnerabilities } from '../../../hooks/dashboard/use-get-top-software-with-vulnerabilities';
import useVulnerability from '../../../store';
import { Tile, TileHeading } from './tile';

const Bar = /* istanbul ignore next */ (props: {
  software: { vulnCount: number; name: string; iconKey: string };
  longestBar: number;
}) => {
  const { software, longestBar } = props;
  const { vulnCount, name, iconKey } = software;

  const nameRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (nameRef.current) {
      setIsOverflowing(
        nameRef.current.scrollWidth > nameRef.current.clientWidth,
      );
    }
  }, [name]);

  return (
    <Flex
      alignItems="center"
      gap="sm"
      css={{
        padding: '6px',
        whiteSpace: 'nowrap',
        minWidth: '80px',
        width: `${(vulnCount / longestBar) * 100}%`,
        background:
          'linear-gradient(to right, var(--colors-neutral20), var(--colors-neutral5))',
        borderRadius: '$rounded',
        height: '32px',
        '&:hover': {
          // If a name is truncated, get the full name on hover
          minWidth: isOverflowing ? 'min-content' : '80px',
        },
      }}
    >
      <SoftwareIcon size="sm" iconKey={iconKey} />
      <Text
        ref={nameRef}
        css={{
          fontWeight: '$medium',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {name}
      </Text>
      <Flex flex={1} justifyContent="end">
        <Text css={{ fontSize: '10px' }}>{formatNumber(vulnCount)}</Text>
      </Flex>
    </Flex>
  );
};

const TopSoftwareWithVulnerabilitiesTile = () => {
  const limit = 4;
  const [timespanFilter, severityFilter] = useVulnerability(
    useShallow((state) => [
      state.allVulnerabilitiesFilter.latestDetected,
      state.allVulnerabilitiesFilter.severity,
    ]),
  );
  const { software, isLoading } = useGetTopSoftwareWithVulnerabilities(
    limit,
    timespanFilter,
    severityFilter,
  );
  const longestBar = software[0]?.vulnCount;

  return (
    <Tile>
      <Flex flow="column" gap="sm" hFull>
        <Flex alignItems="center" gap="xs">
          <TileHeading size="4">
            {i18n.t('Top software with vulnerabilities')}
          </TileHeading>
        </Flex>

        {isLoading && (
          <Flex hFull alignItems="center" justifyContent="center">
            <Loader data-testid="loading-top-software-with-vulnerabilities" />
          </Flex>
        )}

        {!isLoading && software.length > 0 && (
          <Flex flow="column" hFull wFull gap="sm" justifyContent="start">
            {software?.map((s) => (
              <Bar software={s} longestBar={longestBar} />
            ))}
          </Flex>
        )}

        {!isLoading && software.length === 0 && (
          <Flex hFull wFull alignItems="center" justifyContent="center">
            <Text>
              {getNoVulnerabilitiesCopy({
                lastDetectedFilter: timespanFilter,
                isOnDeviceRecord: false,
              })}
            </Text>
          </Flex>
        )}
      </Flex>
    </Tile>
  );
};

export { TopSoftwareWithVulnerabilitiesTile };
