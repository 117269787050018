import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import { Box, Flex, Icon, Tbody, Td, Tr } from '@kandji-inc/nectar-ui';
import { Fragment } from 'react';
import { i18n } from 'src/i18n';
import { constants } from '../../common';
import ThreatListDevicesCountChip from '../../common/components/ThreatListDevicesCountChip';
import ThreatListHighlightedText from '../../common/components/ThreatListHighlightedText';
import TableActionsDropdown from '../../common/components/ThreatListTable/TableActionsDropdown';
import ThreatListTableCheckbox from '../../common/components/ThreatListTable/ThreatListTableCheckbox';
import ThreatListTextCopyButton from '../../common/components/ThreatListTextCopyButton';
import getFormatCount from '../../common/utils/get-format-count';
import type {
  BehavioralThreatClassification,
  BehavioralThreatGroup,
  ThreatClassification,
  ThreatGroup,
} from '../../threat.types';
import ThreatListStatusSummaryBadges from '../ThreatListStatusSummaryBadges';
import AssociatedItemPopover from './AssociatedItemPopover';
import MitrePopover from './MitrePopover';

const getClassificationLabel = (
  classification: ThreatClassification | BehavioralThreatClassification,
  shortPUP: boolean = false,
) => {
  if (shortPUP && classification === 'PUP') {
    return i18n.t('PUP');
  }
  return constants.THREAT_CLASSIFICATION_LABELS(classification);
};

const getClassifactionIcon = (
  classification: ThreatClassification | BehavioralThreatClassification,
) => constants.THREAT_CLASSIFICATION_ICON_MAP[classification];

const getRowActionOptions = (item: ThreatGroup) => {
  const actionOptions: DropdownMenuOption[] = [
    {
      label: i18n.t('Search VirusTotal'),
      icon: 'virus-total',
      onClick: /* istanbul ignore next -- Linking */ () =>
        window.open(`${constants.VIRUS_TOTAL_URL}${item.threat_id}`),
    },
  ];

  return actionOptions;
};

type ThreatListTableBodyProps = Readonly<{
  threats: ThreatGroup[] | BehavioralThreatGroup[];
  query: string | undefined;
  isLoading: boolean;
  isSelectable: boolean;
  isRowSelected: (key: string) => boolean;
  onSelectRow: (key: string, isSelected: boolean) => void;
  isSelectionDisabled: boolean;
  onOpenSidePanel: (threat: ThreatGroup | BehavioralThreatGroup) => void;
  selectedThreatForSidePanel: ThreatGroup | BehavioralThreatGroup | undefined;
  isFileDetectionType: boolean;
}>;

const ThreatListTableBody = (props: ThreatListTableBodyProps) => {
  const {
    threats,
    query,
    isLoading,
    isSelectable,
    isRowSelected,
    onSelectRow,
    isSelectionDisabled,
    onOpenSidePanel,
    selectedThreatForSidePanel,
    isFileDetectionType,
  } = props;

  return (
    <Tbody>
      {threats.map((item) => {
        const id = item.threat_id;
        const isSelected = isRowSelected(id) && !isLoading;
        const isSidePanelSelected =
          selectedThreatForSidePanel?.threat_id === id;
        const isLastOfPage = threats.indexOf(item) === threats.length - 1;
        const actionOptions = getRowActionOptions(item);
        const devicesCount = item.device_count;
        const notQuarantinedCount = item.not_quarantined_count;
        const quarantinedCount = item.quarantined_count;
        const resolvedCount = item.resolved_count;
        const releasedCount = item.released_count;
        const detectedCount = item.detected_count;
        const blockedCount = item.blocked_count;
        const informationalCount = item.informational_count;
        const isMalicious =
          item.classification ===
          constants.THREAT_BEHAVIORAL_CLASSIFICATION.MAL;

        return (
          <Fragment key={id}>
            <Tr
              hoverAnchorUnderline={false}
              selected={isSelected || isSidePanelSelected}
              onClick={() => onOpenSidePanel(item)}
              css={{ cursor: 'pointer' }}
            >
              <Td title="">
                <Flex gap="xs" alignItems="center" pl3>
                  {/* istanbul ignore next - selected rows temporarily hidden */}
                  {isSelectable && (
                    <ThreatListTableCheckbox
                      selected={isSelected}
                      onToggle={(isSelected: boolean) =>
                        onSelectRow(id, isSelected)
                      }
                      disabled={isSelectionDisabled}
                      testId="select-row-button"
                    />
                  )}
                  {/* istanbul ignore next */}
                  {isFileDetectionType ? (
                    <>
                      <Icon
                        name="file"
                        size="sm"
                        style={{ flexShrink: 0, marginLeft: -7 }}
                      />
                      <ThreatListTextCopyButton
                        text={item.threat_id}
                        highlighted={query}
                        css={{ pr: '$3' }}
                      />
                    </>
                  ) : (
                    <>
                      <Icon
                        name="gear"
                        size="sm"
                        style={{ flexShrink: 0, marginLeft: -5 }}
                      />
                      <ThreatListHighlightedText search={query}>
                        {item.threat_id}
                      </ThreatListHighlightedText>
                    </>
                  )}
                </Flex>
              </Td>
              <Td title={isFileDetectionType && item.associated_item}>
                {isFileDetectionType ? (
                  <ThreatListHighlightedText search={query}>
                    {item.associated_item}
                  </ThreatListHighlightedText>
                ) : (
                  <AssociatedItemPopover
                    text={item.associated_item}
                    latestParentProcessName={item.latest_parent_process_name}
                    latestParentProcessArgs={item.latest_parent_process_args}
                    latestTargetProcessName={item.latest_target_process_name}
                    latestTargetProcessArgs={item.latest_target_process_args}
                  />
                )}
              </Td>
              <Td title={getClassificationLabel(item.classification)}>
                <Flex
                  gap="xs"
                  pr1
                  css={{ '& > svg': { height: 20, width: 20, flexShrink: 0 } }}
                >
                  <Icon
                    name={getClassifactionIcon(item.classification)}
                    size="sm"
                  />
                  {getClassificationLabel(item.classification, true)}
                </Flex>
              </Td>
              <Td title={i18n.format.datetime(item.last_detection_date)}>
                {i18n.format.date(item.last_detection_date)}
              </Td>
              <Td title={i18n.t('Devices impacted')}>
                <ThreatListDevicesCountChip
                  label={getFormatCount(devicesCount)}
                />
              </Td>
              {!isFileDetectionType && (
                <Td title="">
                  <MitrePopover techniques={item.mitre_techniques} />
                </Td>
              )}
              <Td title="">
                <ThreatListStatusSummaryBadges
                  notQuarantined={notQuarantinedCount}
                  quarantined={quarantinedCount}
                  resolved={resolvedCount}
                  released={releasedCount}
                  detected={detectedCount}
                  blocked={blockedCount}
                  informational={informationalCount}
                  isFileDetectionType={isFileDetectionType}
                  isMalicious={isMalicious}
                />
              </Td>
              {isFileDetectionType && (
                <Td title={i18n.t('Actions')}>
                  <Box pr5>
                    <TableActionsDropdown
                      options={actionOptions}
                      isAbove={isLastOfPage}
                      testId="action-ellipsis"
                    />
                  </Box>
                </Td>
              )}
            </Tr>
          </Fragment>
        );
      })}
    </Tbody>
  );
};

export default ThreatListTableBody;
