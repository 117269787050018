import { i18n } from 'i18n';
import constants from '../../common/constants';
import type {
  BehavioralThreatClassification,
  BehavioralThreatStatus,
  ThreatClassification,
  ThreatStatus,
} from '../../threat.types';

export const getClassificationLabel = (
  classification: ThreatClassification | BehavioralThreatClassification,
  shortPUP: boolean = false,
): string => {
  if (shortPUP && classification === 'PUP') {
    return i18n.t('PUP');
  }
  return constants.THREAT_CLASSIFICATION_LABELS(classification) || '';
};

export const getClassificationIcon = (
  classification: ThreatClassification | BehavioralThreatClassification,
): string => constants.THREAT_CLASSIFICATION_ICON_MAP[classification] || '';

export const getStatusLabel = (
  status: ThreatStatus | BehavioralThreatStatus,
): string => constants.THREAT_STATUS_DISPLAY_NAME_MAP(status) || '';

export const getStatusColor = (
  status: ThreatStatus | BehavioralThreatStatus,
): string => constants.THREAT_STATUS_COLOR_MAP[status] || '';

export const getStatusIcon = (
  status: ThreatStatus | BehavioralThreatStatus,
): string => constants.THREAT_STATUS_ICON_MAP[status] || '';
