/* istanbul ignore file */
import {
  Box,
  DropdownMenuPrimitives as DropdownMenu,
  Toaster_UNSTABLE as Toaster,
  Tooltip,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';

import {
  type ExportDataType,
  useDataExportContext,
} from 'src/contexts/data-export/data-export-context';
import TableHeaderIconButton from '../../features/visibility/prism/components/PrismTable/components/TableHeaderIconButton';
import ExportDropdownContent, {
  type ExportDropdownContentProps,
} from './ExportDropdownContent';
import ExportDropdownFooter from './ExportDropdownFooter';

export type ExportButtonProps = {
  exportTypeOptions: ExportDropdownContentProps['exportTypeOptions'];
  exportType: ExportDropdownContentProps['exportType'];
  setExportType: ExportDropdownContentProps['setExportType'];
  helpText: ExportDropdownContentProps['helpText'];
  title: string;
  onExport: () => any;
  exportDataType: ExportDataType;
};

const ExportButton = (props: ExportButtonProps) => {
  const {
    onExport,
    title,
    exportTypeOptions,
    exportType,
    setExportType,
    helpText,
    exportDataType,
  } = props;

  const {
    onCreateExport,
    isExporting: isDisabled,
    setExportDataType,
  } = useDataExportContext();

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  /* istanbul ignore next */
  const handleExport = () => {
    const payload = onExport();
    setExportDataType(exportDataType);
    onCreateExport(payload);
  };

  return (
    <>
      <Box>
        <DropdownMenu.Root
          open={isDropdownOpen}
          onOpenChange={setIsDropdownOpen}
          modal={false}
        >
          <Tooltip content={i18n.t('Export CSV')} side="top">
            <DropdownMenu.Trigger asChild>
              <TableHeaderIconButton
                role="button"
                name={i18n.t('Export')}
                icon="file-arrow-down"
                disabled={isDisabled}
              />
            </DropdownMenu.Trigger>
          </Tooltip>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              onCloseAutoFocus={(e) => {
                e.preventDefault();
              }}
              css={{ width: '520px', marginRight: '$7', zIndex: 2 }}
            >
              <ExportDropdownContent
                title={title}
                exportTypeOptions={exportTypeOptions}
                helpText={helpText}
                exportType={exportType}
                setExportType={setExportType}
              />
              <DropdownMenu.Divider />
              <ExportDropdownFooter
                exportType={exportType}
                onCancel={() => setIsDropdownOpen(false)}
                onExport={handleExport as () => void}
              />
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </Box>
      <Toaster />
    </>
  );
};

export default ExportButton;
