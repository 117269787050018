/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const HUBSPOT_URL = 'https://api.hsforms.com/submissions/v3/integration/submit';

const useRequestEdrTrial = (portalId: string, formId: string) =>
  useMutation({
    mutationKey: ['request-edr-trial'],
    mutationFn: async ({
      tenantSubdomain = '',
      tenantName = '',
      userEmail = '',
      pageName = '',
    }: {
      tenantSubdomain: string | undefined;
      tenantName: string | undefined;
      userEmail: string | undefined;
      pageName: string | undefined;
    }) => {
      if (!tenantSubdomain || !pageName) {
        throw new Error('Missing required parameters');
      }

      return axios.post(`${HUBSPOT_URL}/${portalId}/${formId}`, {
        submittedAt: Date.now(),
        context: {
          pageName,
        },
        fields: [
          {
            objectTypeId: '0-1',
            name: 'email',
            value: userEmail,
          },
          {
            objectTypeId: '0-1',
            name: 'edr_in_app_requested',
            value: 'true',
          },
          {
            objectTypeId: '0-1',
            name: 'tenant_subdomain',
            value: tenantSubdomain,
          },
          {
            objectTypeId: '0-1',
            name: 'name',
            value: tenantName,
          },
        ],
      });
    },
  });

export default useRequestEdrTrial;
