import { addDay } from 'components/table/components/filters/helpers';
/* istanbul ignore file */
import { i18n } from 'src/i18n';
import type { DetectionDateFilterFields } from '../common/common.types';
import { DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS } from './constants';

const getTextWidth = (text: string, element: HTMLElement = document.body) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Get the computed style of the element and set it on the canvas context
  const style = window.getComputedStyle(element);
  context.font = `${style.fontSize} ${style.fontFamily}`;

  return context.measureText(text).width;
};

export const displayAndTruncateList = (
  strings: string[],
  maxWidth: number = 530,
  separator = ', ',
  element = document.body,
) => {
  const displayed = [];
  let hidden = [];
  let totalWidth = 0;

  for (let i = 0; i < strings.length; i++) {
    const iconWidth = 20;
    const string = strings[i];
    const stringWidth = getTextWidth(string, element) + iconWidth;
    const separatorWidth = i > 0 ? getTextWidth(separator, element) : 0;

    if (totalWidth + stringWidth + separatorWidth > maxWidth) {
      hidden = strings.slice(i); // All remaining strings are hidden
      break;
    }

    displayed.push(string);
    totalWidth += stringWidth + separatorWidth;
  }

  return { displayed, hidden };
};

// Turns relative dates (ex. "24hours", "7days") into API format
export const transformDaysAgoFilterToApi = (
  dateFilter: DetectionDateFilterFields,
) => {
  if (!dateFilter) {
    return null;
  }

  const { value } = dateFilter;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (value === '24hours') {
    return { days_ago: 1, timezone };
  }

  // Retrive the number of days from "7days", "30days", etc. and convert to a number
  return { days_ago: parseInt(value.replace('days', ''), 10), timezone };
};

export const transformDetectionDateFilterToApi = (
  dateFilter: DetectionDateFilterFields,
) => {
  if (!dateFilter) {
    return null;
  }

  const { value, operator, dateRangeFrom, dateRangeTo, selectedDate } =
    dateFilter;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!value || value === 'all_time') {
    return null;
  }

  /* istanbul ignore if */
  if (operator) {
    if (operator === 'ib') {
      return {
        gte: dateRangeFrom,
        lt: addDay(dateRangeTo).toISOString(),
      };
    }

    if (operator === 'eq') {
      return {
        gte: selectedDate,
        lt: addDay(selectedDate).toISOString(),
      };
    }

    if (operator === 'lte') {
      return {
        lt: addDay(selectedDate).toISOString(),
      };
    }

    return { [operator]: selectedDate };
  }

  return { relative: { value, timezone } };
};

export const getNoVulnerabilitiesCopy = ({
  lastDetectedFilter,
  isOnDeviceRecord = false,
}: {
  lastDetectedFilter: DetectionDateFilterFields;
  isOnDeviceRecord?: boolean;
}) => {
  let copy = '';
  const target = isOnDeviceRecord
    ? i18n.t('on this device')
    : i18n.t('in your Mac fleet');

  // The Last Detected filter is not set
  if (!lastDetectedFilter?.value) {
    copy = i18n.t('No vulnerabilities have been detected {target}.', {
      target,
    });
  } else if (lastDetectedFilter.value === 'custom_date_range') {
    copy = i18n.t(
      'No vulnerabilities have been detected {target} within the specified detection period.',
      {
        target,
      },
    );
  } else if (lastDetectedFilter.value === 'all_time') {
    copy = i18n.t(
      'Across all time, no vulnerabilities have been detected {target}.',
      { target },
    );
  } else {
    const timePeriod = DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS.find(
      ({ value }) => value === lastDetectedFilter.value,
    ).getInlineLabel();

    copy = i18n.t(
      'In the {timePeriod}, no vulnerabilities have been detected {target}.',
      {
        timePeriod,
        target,
      },
    );
  }

  return copy;
};
