/* istanbul ignore file -- We have unit tests for this */
import { Dialog, Flex, Heading, Loader, Text } from '@kandji-inc/nectar-ui';
import { useQuery } from '@tanstack/react-query';
import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { fetchAccount } from 'src/app/_actions/account';
import { setSnackbar } from 'src/app/_actions/ui';
import { subscriptionsApi } from 'src/app/api/base';
import { useGetSearchParams } from 'src/hooks/useGetSearchParams';
import { i18n } from 'src/i18n';

const defaultValues = {
  session: {},
  maxRetryError: false,
};

const ShouldProvisionContext = createContext(defaultValues);

const ShouldProvisionProvider = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [checkoutSessionId] = useGetSearchParams('checkout_session_id');
  const [checkoutSession, setCheckoutSession] = useState('');
  const [maxRetryError, setMaxRetryError] = useState(false);
  const [startPolling, setStartPolling] = useState(false);
  const [loader, setLoader] = useState(false);

  const { data: session } = useQuery({
    queryKey: ['company-info-fetch'],

    queryFn: async () => {
      const session = await subscriptionsApi(
        `/stripe/session/checkout/${checkoutSession}`,
      ).get(null);
      return session.data;
    },
    refetchInterval: (query) => {
      if (query.state.dataUpdateCount === 10) {
        setMaxRetryError(true);

        return false;
      }

      return 3000;
    },
    enabled: startPolling,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('checkout_session_id') && checkoutSessionId) {
      setCheckoutSession(checkoutSessionId);

      queryParams.delete('checkout_session_id');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [checkoutSessionId, history.replace, location]);

  useEffect(() => {
    if (checkoutSession) {
      setLoader(true);
    }
  }, [checkoutSession]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (loader) {
        setStartPolling(true);
      }
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [loader]);

  useEffect(() => {
    if (session?.session_metadata?.status === 'paid' && startPolling) {
      setStartPolling(false);
      dispatch(fetchAccount());
      dispatch(
        setSnackbar(
          i18n.t('Your subscription has been successfully activated!'),
        ),
      );
      setLoader(false);
    }
  }, [session?.session_metadata?.status, dispatch, startPolling]);

  return (
    <ShouldProvisionContext.Provider value={{ session, maxRetryError }}>
      {children}
      <Dialog
        isOpen={loader}
        closeOnOutsideClick={false}
        closeOnEscape={false}
        contentCss={{ width: '480px' }}
        closeIcon={null}
        content={
          maxRetryError ? (
            <Flex flow="column" alignItems="center">
              <Flex
                css={{
                  padding: '20px',
                  paddingBottom: '36px',
                  width: '360px',
                  textAlign: 'center',
                  gap: '8px',
                }}
                flow="column"
                alignItems="center"
                justifyContent="center"
              >
                <Heading size="2">
                  {i18n.t('Oops! There was an error with your subscription')}
                </Heading>
                <Text>{i18n.t('Contact support for further assistance.')}</Text>
              </Flex>
            </Flex>
          ) : (
            <Flex flow="column" alignItems="center">
              <Flex
                css={{
                  background:
                    'linear-gradient(to right, #000000, #1a1a1a, #333333);',
                  width: '430px',
                  height: '230px',
                  borderRadius: '4px',
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Loader size="xl" color="yellow" />
              </Flex>
              <Flex
                css={{
                  padding: '20px',
                  paddingBottom: '36px',
                  width: '360px',
                  textAlign: 'center',
                  gap: '8px',
                }}
                flow="column"
                alignItems="center"
                justifyContent="center"
              >
                <Heading size="2"> {i18n.t("We're almost there!")} </Heading>
                <Text>
                  {i18n.t(
                    'We are finalizing some steps to provide you with the best experience possible.',
                  )}
                </Text>
              </Flex>
            </Flex>
          )
        }
      ></Dialog>
    </ShouldProvisionContext.Provider>
  );
};

export { ShouldProvisionContext, ShouldProvisionProvider };
