export const formatDiskSize = (
  size: number,
  unit: 'B' | 'KB' | 'MB' | 'GB' | 'TB' = 'B',
) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let sizeInBytes = size;

  const unitIndex = units.indexOf(unit);
  if (unitIndex > 0) {
    sizeInBytes = size * 1024 ** unitIndex;
  }

  let formattedSize = sizeInBytes;
  let formattedUnitIndex = 0;

  while (formattedSize >= 1024 && formattedUnitIndex < units.length - 1) {
    formattedSize /= 1024;
    formattedUnitIndex++;
  }

  return `${
    Number.isInteger(formattedSize) ? formattedSize : formattedSize.toFixed(1)
  } ${units[formattedUnitIndex]}`;
};
