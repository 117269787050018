import { formatNumber } from '@kandji-inc/nectar-i18n';
import {
  Box,
  Button,
  Card,
  Flex,
  Icon,
  Link,
  Text,
  Tooltip,
  styled,
} from '@kandji-inc/nectar-ui';
import { useEffect, useRef, useState } from 'react';
import { links } from 'src/app/common/constants';
import { SoftwareIcon } from 'src/features/edr/vulnerability/common/software-icon';
import {
  type Software,
  VulnerabilityType,
} from 'src/features/edr/vulnerability/vulnerability.types';
import { i18n } from 'src/i18n';
import { common } from 'src/i18n/common';
import { highlightedText } from 'src/pages/ADEListView/utils/highlightText';
import { SoftwareVersionItem } from './software-version-item';

const VerticalDivider = styled(Flex, {
  width: '1px',
  height: '16px',
  backgroundColor: '$neutral20',
  borderRadius: '$rounded',
});

const HorizontalDivider = styled(Box, {
  width: '100%',
  height: '1px',
  backgroundColor: '$neutral20',
});

type SoftwareCardProps = {
  software: Software;
  searchTerm: string;
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
};

const SoftwareCard = (props: SoftwareCardProps) => {
  const { software, searchTerm, isExpanded, setIsExpanded } = props;
  const {
    name,
    vulnerability_type: type,
    icon_key,
    blueprints,
    num_devices: numDevices,
    software_versions: softwareVersions,
  } = software;

  const SOFTWARE_VERSION_LIST_LIMIT = 5;
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const scrollToRef = useRef(null);
  const showLessButtonRef = useRef(null);
  const translatedTotalSoftwareVersions = i18n.format.number(
    softwareVersions.length,
  );

  const softwareTypeDisplay = {
    [VulnerabilityType.application]: i18n.t('Application'),
    [VulnerabilityType.operatingSystem]: i18n.t('Operating system'),
  };

  useEffect(
    /* istanbul ignore next */ () => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Set showArrow to true if the "Show less" button is not intersecting
          if (showLessButtonRef.current) {
            setShowArrow(
              // Checking that `top` is less than 150 prevents the arrow from appearing
              // when the "Show less" button is hidden at the bottom of the page
              showLessButtonRef.current.getBoundingClientRect().top < 150 &&
                !entry.isIntersecting,
            );
          }
        },
        { root: null, threshold: 1 }, // Trigger when the button is completely out of view
      );

      if (showLessButtonRef.current) {
        observer.observe(showLessButtonRef.current);
      }

      return () => observer.disconnect();
    },
    [isShowingMore],
  );

  const numVisibleBlueprints = 3;
  const visibleBlueprints = blueprints.slice(0, numVisibleBlueprints);
  const numHiddenBlueprints = blueprints.length - visibleBlueprints.length;

  return (
    <Card css={{ padding: '$0' }}>
      <Flex
        flow="column"
        gap="lg"
        css={{ scrollMarginTop: '115px' }}
        ref={scrollToRef}
      >
        <Flex gap="md" alignItems="center" p4 pb0>
          <SoftwareIcon iconKey={icon_key} size="xl" />
          <Flex flow="column" gap="xs">
            <Flex gap="sm" alignItems="center">
              <Text size="3" css={{ fontWeight: '$medium' }}>
                {highlightedText(name, searchTerm)}
              </Text>
              <Flex gap="xs">
                {softwareVersions.map((softwareVersion) => (
                  <Flex
                    css={{
                      border: '1px solid $neutral30',
                      borderRadius: '$rounded-sm',
                      padding: '2px $1',
                      gap: '2px',
                    }}
                  >
                    <Text
                      size="1"
                      variant="description"
                      css={{ fontWeight: '$medium' }}
                    >
                      {softwareVersion.version}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Text variant="secondary">{softwareTypeDisplay[type]}</Text>
          </Flex>
        </Flex>
        <Flex
          gap="lg"
          alignItems="center"
          css={{ padding: '0 $4', ...(!isExpanded && { paddingBottom: '$3' }) }}
        >
          <Flex
            gap="xs"
            alignItems="center"
            onClick={() => setIsExpanded(!isExpanded)}
            css={{ '&:hover': { cursor: 'pointer' } }}
          >
            <Box
              css={{
                width: 16,
                height: 16,
                svg: { width: 16, height: 16, verticalAlign: 'top' },
              }}
            >
              <Icon
                name={isExpanded ? 'fa-angle-up-small' : 'fa-angle-down-small'}
                color="var(--color-neutral-70)"
              />
            </Box>
            <Text variant="secondary" size="1" css={{ fontWeight: '$medium' }}>
              {isExpanded
                ? i18n.t('View less details')
                : i18n.t('View more details')}
            </Text>
          </Flex>

          <VerticalDivider />

          <Flex alignItems="center" gap="xs">
            <Icon
              name="sf-desktop-computer"
              size="xs"
              style={{ color: 'var(--colors-neutral70)' }}
            />
            <Text size="1" variant="secondary">
              {common.numDevices(numDevices)}
            </Text>
          </Flex>

          <VerticalDivider />

          <Flex alignItems="center" gap="xs">
            <Icon
              name="kandji-blueprint"
              size="xs"
              style={{ color: 'var(--colors-neutral70)' }}
            />
            {visibleBlueprints.map((bp, index) => {
              return (
                <Link
                  key={bp.id}
                  href={`${links.blueprints}/${bp.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="subtle"
                  css={{
                    '&:hover': {
                      textDecorationColor: '$neutral70',
                    },
                  }}
                >
                  <Text size="1" variant="secondary">
                    {`${bp.name}${index < visibleBlueprints.length - 1 || numHiddenBlueprints > 0 ? ',' : ''}`}
                  </Text>
                </Link>
              );
            })}
            {numHiddenBlueprints > 0 && (
              <Tooltip
                side="bottom"
                theme="light"
                interactive
                css={{ zIndex: 2, padding: '$3 $4', maxHeight: '190px' }}
                content={
                  <Flex flow="column" css={{ gap: '6px' }}>
                    {blueprints.slice(numVisibleBlueprints).map((bp) => (
                      <Flex alignItems="center" gap="xs">
                        <Icon
                          name="kandji-blueprint"
                          size="xs"
                          style={{ color: 'var(--colors-neutral70)' }}
                        />
                        <Link
                          key={bp.id}
                          href={`${links.blueprints}/${bp.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          variant="subtle"
                          css={{
                            '&:hover': {
                              textDecorationColor: '$neutral70',
                            },
                          }}
                        >
                          <Text size="1" variant="secondary">
                            {bp.name}
                          </Text>
                        </Link>
                      </Flex>
                    ))}
                  </Flex>
                }
              >
                <Text size="1" variant="secondary">
                  {i18n.t('+{count}', {
                    count: formatNumber(numHiddenBlueprints),
                  })}
                </Text>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Flex>
      {isExpanded && (
        <Flex flow="column" wFull css={{ padding: '20px $4 $4' }}>
          {/* First set of software versions shown by default */}
          {softwareVersions
            .slice(0, SOFTWARE_VERSION_LIST_LIMIT)
            .map((softwareVersion, idx) => {
              const isLastItem =
                idx ===
                Math.min(softwareVersions.length, SOFTWARE_VERSION_LIST_LIMIT) -
                  1;
              return (
                <Flex wFull flow="column">
                  <SoftwareVersionItem
                    software={software}
                    softwareVersion={softwareVersion}
                  />
                  {!isLastItem && (
                    <HorizontalDivider css={{ margin: '20px 0' }} />
                  )}
                </Flex>
              );
            })}

          {/* Show more/less button */}
          {softwareVersions.length > SOFTWARE_VERSION_LIST_LIMIT && (
            <Flex
              flow="row"
              alignItems="center"
              css={{
                gap: '20px',
                marginTop: '20px',
                marginBottom: isShowingMore ? '20px' : '',
              }}
              ref={showLessButtonRef}
            >
              <HorizontalDivider />
              <Button
                variant="link"
                compact
                icon={{ name: 'arrows-to-line' }}
                onClick={() => setIsShowingMore(!isShowingMore)}
              >
                {isShowingMore
                  ? i18n.$t('Show less')
                  : i18n.$t('Show all ({total})', {
                      total: translatedTotalSoftwareVersions,
                    })}
              </Button>
              <HorizontalDivider />
            </Flex>
          )}

          {/* Items after the Show more/less button */}
          {softwareVersions.length > SOFTWARE_VERSION_LIST_LIMIT &&
            isShowingMore &&
            softwareVersions
              .slice(SOFTWARE_VERSION_LIST_LIMIT)
              .map((softwareVersion, idx) => {
                const isLastItem =
                  idx + SOFTWARE_VERSION_LIST_LIMIT ===
                  softwareVersions.length - 1;
                return (
                  <Flex wFull flow="column">
                    <SoftwareVersionItem
                      software={software}
                      softwareVersion={softwareVersion}
                    />
                    {!isLastItem && (
                      <HorizontalDivider css={{ margin: '20px 0' }} />
                    )}
                  </Flex>
                );
              })}
        </Flex>
      )}

      {showArrow && (
        <Flex
          justifyContent="end"
          css={{ position: 'sticky', bottom: 60, height: 0, paddingRight: 53 }}
        >
          <Button
            icon={{ name: 'arrow-up-to-line' }}
            onClick={
              /* istanbul ignore next */ () => {
                if (scrollToRef.current) {
                  scrollToRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
              }
            }
            css={{ height: 36, marginTop: -50 }}
          />
        </Flex>
      )}
    </Card>
  );
};

export { SoftwareCard };
