import {
  Button,
  type ButtonProps,
  type CSS,
  Flex,
  Icon,
  Separator,
  Text,
  styled,
  theme,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { usePrismAIContext } from '../contexts/PrismAIContext';

interface AskKaiButtonProps extends ButtonProps {
  variant?: 'default' | 'inverse';
  disabled?: boolean;
  as?: keyof HTMLElementTagNameMap;
  css?: CSS;
}

export const AskKaiButton = ({
  variant = 'default',
  disabled = false,
  as,
  ...props
}: AskKaiButtonProps) => {
  const { onOpenChange } = usePrismAIContext();
  // istanbul ignore next
  const iconColor = variant === 'inverse' ? 'gradient' : theme.colors.neutral0;

  return (
    <KaiGradientButton
      variant={variant}
      onClick={onOpenChange}
      aria-label={i18n.t('Ask Kai Alpha')}
      data-testid="ask-kai-button"
      disabled={disabled}
      as={as}
      {...props}
    >
      <Flex alignItems="center" gap="sm">
        <Icon name="kandji-ai" size="sm" color={iconColor} />
        <Text as="span">{i18n.t('Ask Kai')}</Text>

        <Separator orientation="vertical" />

        <AlphaText as="span">{i18n.t('Alpha')}</AlphaText>
      </Flex>
    </KaiGradientButton>
  );
};

const AlphaText = styled(Text, {
  color: '$neutral0',
  variants: {
    variant: {
      inverse: {
        color: '$aiPurple1',
      },
    },
  },
});

const KaiGradientButton = styled(Button, {
  display: 'flex',
  alignItems: 'stretch',
  borderRadius: '$1',
  padding: '$1 $2',
  justifyContent: 'center',
  transition: '$cubicCurve',

  '&:hover': {
    opacity: 0.9,
  },

  [`${Text}`]: {
    color: '$neutral0',
    fontWeight: '$medium',
    fontFeatureSettings: '"liga" off',
    lineHeight: '$2',
  },

  [`${AlphaText}`]: {
    fontWeight: '$light',
  },

  [`${Separator}`]: {
    background: '$purple30',
  },

  variants: {
    variant: {
      default: {
        background: '$aiPurpleGradient1',
        color: '$neutral0',
      },
      inverse: {
        background: 'transparent',
        position: 'relative',

        '&::before': {
          content: '""',
          position: 'absolute',
          inset: 0,
          background: '$aiPurpleGradient1',
          borderRadius: '$1',
          padding: '1px',
          pointerEvents: 'none',
          mask: 'linear-gradient($colors$neutral0 0 0) content-box, linear-gradient($colors$neutral0 0 0)',
          maskComposite: 'exclude',
        },

        '&:hover, &:focus': {
          background: 'transparent',
        },

        [`${Text}`]: {
          background: '$aiPurpleGradient2',
          backgroundClip: 'text',
          color: 'transparent',
          webkitBackgroundClip: 'text',
          webkitTextFillColor: 'transparent',
        },

        [`${Separator}`]: {
          background: '$aiPurple1',
        },
      },
    },
  },

  defaultVariants: {
    variant: 'default',
  },
});
