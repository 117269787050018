import { TextField } from '@kandji-inc/nectar-ui';
import { useCallback, useEffect } from 'react';
import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import { i18n } from 'src/i18n';
import type { OnApply, OnClear } from '../../common.types';

const textFieldSearchCss = {
  '& > div > div:first-child svg': {
    width: 20,
    height: 20,
  },
  '& input': {
    paddingBlock: 2.5,
    paddingLeft: 32,
    paddingRight: 20,
  },
};

type SearchFilterProps = {
  name: string;
  value: string | null | undefined;
  onChange: OnApply;
  onClear: OnClear;
  disabled?: boolean;
  width?: string | number;
  placeholder?: string;
  debounceMs?: number;
};

export const EDRSearchFilter = (props: SearchFilterProps) => {
  const {
    name,
    value = '',
    onChange,
    onClear,
    disabled = false,
    width = 240,
    placeholder = i18n.t('Search'),
    debounceMs = 250,
  } = props;

  const [debouncedSearchValue, setSearchValue, searchValue] =
    useDebouncedState<string>(value, debounceMs);

  const handleChange = useCallback(
    (newValue: string) => {
      if (newValue === '') {
        onClear(name);
      } else {
        onChange(name, newValue);
      }
    },
    [onClear, onChange, name],
  );

  useEffect(
    /* istanbul ignore next*/ () => {
      handleChange(debouncedSearchValue);
    },
    [debouncedSearchValue],
  );

  useEffect(
    /* istanbul ignore next */ () => {
      setSearchValue(value);
    },
    [value],
  );

  return (
    <TextField
      css={{ ...textFieldSearchCss, width }}
      placeholder={placeholder}
      icon="magnifying-glass"
      onChange={(e) => setSearchValue(e.target.value)}
      showClearButton={Boolean(searchValue)}
      onClear={() => setSearchValue('')}
      value={searchValue}
      aria-label="Search"
      disabled={disabled}
    />
  );
};

export default EDRSearchFilter;
