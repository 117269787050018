import { Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Code,
  Dialog,
  Flex,
  Icon,
  Loader,
  Text,
} from '@kandji-inc/nectar-ui';
import { getComputerRecoveryPasswordHistory } from 'app/components/computer/api';
import React, { useEffect, useState } from 'react';

import { i18n } from 'i18n';

const RecoveryPasswordHistoryModal = (props) => {
  const { computerId, onHide } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [recoveryPasswords, setRecoveryPasswords] = useState([]);

  useEffect(() => {
    getComputerRecoveryPasswordHistory(computerId)
      .then((data) => {
        setRecoveryPasswords(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [computerId]);

  const recoveryPassword = recoveryPasswords?.[0]?.value;
  const receivedAt = recoveryPasswords?.[0]?.received_at;
  const state = recoveryPasswords?.[0]?.state;
  const formattedReceivedAt = i18n.format.datetime(receivedAt);
  const previousPasswords = recoveryPasswords?.slice(1);

  const handleOnCopy = (value) => {
    navigator.clipboard.writeText(value);
    toaster(i18n.t('Copied to clipboard.'));
  };

  let content;

  if (isLoading) {
    content = (
      <Flex justifyContent="center">
        <Loader />
      </Flex>
    );
  } else if (!receivedAt) {
    content = (
      <Text>
        {i18n.t('There are no Recovery Passwords for this computer.')}
      </Text>
    );
  } else {
    content = (
      <Flex
        flow="column"
        gap="xl"
        css={{ minWidth: '$15', maxHeight: '$15', overflowY: 'auto' }}
      >
        {!recoveryPassword && (
          <Flex flow="column" gap="sm">
            <Text>
              {i18n.t('Password cleared at {time}:', {
                time: formattedReceivedAt,
              })}
            </Text>
            <Flex flow="row" gap="sm">
              <Icon name="key" color="var(--colors-neutral90)" />
              <Code>
                {i18n.t('(cleared)', { _context: 'a password was erased' })}
              </Code>
            </Flex>
          </Flex>
        )}
        {recoveryPassword && (
          <Flex flow="column" gap="sm">
            <Text>
              {state === 'verified'
                ? i18n.t('Password verified at {time}:', {
                    time: formattedReceivedAt,
                  })
                : i18n.t('Password set at {time}:', {
                    time: formattedReceivedAt,
                  })}
            </Text>
            <Flex flow="row" gap="sm">
              <Icon name="key" color="var(--colors-neutral90)" />
              <Code>{recoveryPassword}</Code>
              <Box
                css={{ '&:hover': { cursor: 'pointer' } }}
                onClick={() => handleOnCopy(recoveryPassword)}
                data-testid="copy-recovery-key"
              >
                <Icon name="copy" color="var(--colors-neutral50)" size="sm" />
              </Box>
            </Flex>
          </Flex>
        )}
        <Accordion collapsible>
          <AccordionItem value="previous-keys">
            <AccordionTrigger css={{ paddingBottom: '$2' }}>
              {i18n.t('Previous Passwords')}
            </AccordionTrigger>
            <AccordionContent>
              {previousPasswords?.length > 0 && (
                <Flex flow="column" gap="xl">
                  {previousPasswords.map((key) => {
                    const { received_at, value, state } = key;
                    const formattedReceivedAtPreviousKey =
                      i18n.format.datetime(received_at);

                    if (!value) {
                      return (
                        <Flex flow="column" gap="sm" key={received_at}>
                          <Text>
                            {i18n.t('Password cleared at {time}:', {
                              time: formattedReceivedAtPreviousKey,
                            })}
                          </Text>
                          <Flex flow="row" gap="sm">
                            <Icon name="key" color="var(--colors-neutral90)" />
                            <Code>({i18n.t('cleared')})</Code>
                          </Flex>
                        </Flex>
                      );
                    }

                    return (
                      <Flex flow="column" gap="sm" key={received_at}>
                        <Text>
                          {state === 'verified'
                            ? i18n.t('Password verified at {time}:', {
                                time: formattedReceivedAtPreviousKey,
                              })
                            : i18n.t('Password set at {time}:', {
                                time: formattedReceivedAtPreviousKey,
                              })}
                        </Text>
                        <Flex flow="row" gap="sm">
                          <Icon name="key" color="var(--colors-neutral90)" />
                          <Code>{value}</Code>
                          <Box
                            css={{ '&:hover': { cursor: 'pointer' } }}
                            onClick={() => handleOnCopy(value)}
                            data-testid="copy-previous-recovery-key"
                          >
                            <Icon
                              name="copy"
                              color="var(--colors-neutral50)"
                              size="sm"
                            />
                          </Box>
                        </Flex>
                      </Flex>
                    );
                  })}
                </Flex>
              )}
              {previousPasswords?.length === 0 && (
                <Text>
                  {i18n.t(
                    'No additional Recovery Passwords have been set by Kandji.',
                  )}
                </Text>
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Flex>
    );
  }

  return (
    <Dialog
      isOpen
      closeOnEscape
      onOpenChange={onHide}
      title={i18n.t('Recovery Passwords')}
      content={content}
      css={{ zIndex: 2000 }}
    />
  );
};

export default RecoveryPasswordHistoryModal;
