/* istanbul ignore file */
import {
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogPrimitives,
  Flex,
  Icon,
  IconButton,
  Label,
  SelectV2,
  SelectV2Primitives,
  Text,
  TextField,
  Tooltip,
  TooltipProvider,
} from '@kandji-inc/nectar-ui';
import { getCode, getNames } from 'country-list';
import deepcopy from 'deepcopy';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AccountContext } from 'src/contexts/account';
import { useDebouncedState } from 'src/hooks/useDebouncedState';
import { useFocusInput } from 'src/hooks/useFocusInput';
import { i18n } from 'src/i18n';
import {
  type CheckoutQuery,
  INTERNAL_SERVER_ERROR,
  INVALID_ADDRESS_ERROR,
  type PricingQuery,
  useCheckoutPricing,
  useGoToCheckout,
} from './api';

enum PaymentTerm {
  ANNUAL = 'annual',
  MONTHLY = 'month',
}

const defaultModel = {
  zipcode: '',
  country: '',
  line1: '',
  macOsDeviceCount: '',
  iosAppleTvDeviceCount: '',
  paymentTerm: PaymentTerm.ANNUAL,
  hasEdr: false,
  hasVuln: false,
};

const MIN_DEVICE_COUNT = 1;
const MAX_DEVICE_COUNT = 300;

const EDR_TOOLTIP =
  'Add Endpoint Detection and Response to increase the security posture of your macOS fleet starting at $6 per device/month.';

const VULN_TOOLTIP =
  'Protect your macOS computers from malware and other threats starting at $2.50 per device/month.';

const Validation = ({ text }) => {
  if (!text) return null;
  return (
    <Text size="1" variant="danger" css={{ marginTop: '$1' }}>
      {text}
    </Text>
  );
};

const CountrySelect = ({ model, onChange, validation, resetValidation }) => {
  const countryNames = getNames();
  const sortedCountries = countryNames.sort((c1, c2) => c1.localeCompare(c2));
  const countries = sortedCountries.map((country) => ({
    value: i18n.t(getCode(country)),
    label: i18n.t(country),
  }));
  const USA = {
    value: i18n.t('US'),
    label: i18n.t('United States of America'),
  };

  const [filteredOptions, setFilteredOptions] = useState(countries);
  const [search, setSearch] = useState('');
  const inputRef = useFocusInput([filteredOptions, search]);

  return (
    <Flex
      flow="column"
      gap="sm"
      css={{ maxHeight: '200px', overflowY: 'auto', flex: 1 }}
    >
      <Label above>{i18n.t('Country')}</Label>
      <SelectV2.Default
        label={i18n.t('Country')}
        options={countries}
        value={model.country}
        onValueChange={(value) => {
          resetValidation('country');
          resetValidation('address');
          onChange('country', value);
        }}
        triggerProps={{
          'data-testid': 'country-select',
          variant: 'input',
          placeholder: i18n.t('Select country'),
        }}
        content={() => (
          <SelectV2Primitives.Content
            css={{
              width: 'var(--radix-select-trigger-width)',
              maxHeight: '320px',
            }}
            position="popper"
            onCloseAutoFocus={() => {
              setSearch('');
              setFilteredOptions(countries);
              document.body.style.pointerEvents = 'auto';
            }}
          >
            <SelectV2Primitives.Viewport>
              <TextField
                ref={inputRef}
                css={{
                  padding: '6px 12px',
                  position: 'fixed',
                  backgroundColor: 'white',
                }}
                autoComplete="off"
                icon="magnifying-glass"
                placeholder={i18n.t('Search')}
                compact
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setFilteredOptions(
                    countries.filter((country) =>
                      country.label
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()),
                    ),
                  );
                }}
              />
              <Flex flow="column" css={{ pt: '40px' }}>
                {!filteredOptions.length ? (
                  <SelectV2Primitives.Item disabled>
                    {i18n.t('No results found')}
                  </SelectV2Primitives.Item>
                ) : (
                  (search ? filteredOptions : [USA, ...filteredOptions]).map(
                    (option, idx) => (
                      <SelectV2Primitives.Item
                        key={`${option.value}-${idx}`}
                        value={option.value}
                      >
                        {option.label}
                      </SelectV2Primitives.Item>
                    ),
                  )
                )}
              </Flex>
            </SelectV2Primitives.Viewport>
          </SelectV2Primitives.Content>
        )}
      />
      <Validation text={validation} />
    </Flex>
  );
};

const DialogContent = ({
  model,
  onChange,
  validation,
  resetValidation,
  validateDeviceCount,
  onSubmit,
  isLoading,
  serverError,
}) => {
  const [debouncedPricingQuery, setPricingQuery] =
    useDebouncedState<PricingQuery>(
      {
        term: 'annual',
        add_ons: {
          vuln: false,
          edr: false,
        },
        device_counts: {
          mac_devices: model.mac || 0,
          other_devices: model.iosAppleTvDeviceCount || 0,
        },
      },
      200,
    );

  const pricing = useCheckoutPricing(debouncedPricingQuery);

  const formatToUSD = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  const isCheckoutDisabled = useMemo(() => {
    const hasDevices = model.macOsDeviceCount || model.iosAppleTvDeviceCount;
    const sumOfTwoCounts =
      parseInt(model.macOsDeviceCount || '0', 10) +
      parseInt(model.iosAppleTvDeviceCount || '0', 10);
    const wrongDeviceLimit =
      sumOfTwoCounts < MIN_DEVICE_COUNT || sumOfTwoCounts > MAX_DEVICE_COUNT;
    const hasAddress = model.line1 && model.country && model.zipcode;

    return !hasDevices || !hasAddress || wrongDeviceLimit;
  }, [model]);

  return (
    <Flex flow="column" gap="xl" css={{ padding: '0 60px' }}>
      {(pricing.error || serverError) && <GenericError />}

      <Flex flow="column" gap="md">
        <Text variant="secondary" css={{ fontWeight: '$medium' }}>
          {i18n.t('Payment Frequency')}
        </Text>
        <ButtonGroup
          compact
          css={{ '& > button': { flex: 1 } }}
          variant="input"
          buttons={[
            {
              label: i18n.t('Annual (Save 20%)'),
              onClick: () => {
                onChange('paymentTerm', PaymentTerm.ANNUAL);
                setPricingQuery((prev) => ({
                  ...prev,
                  term: 'annual',
                }));
              },
              selected: model.paymentTerm === PaymentTerm.ANNUAL,
              'data-testid': 'payment-term-annual',
            },
            {
              label: i18n.t('Monthly'),
              onClick: () => {
                onChange('paymentTerm', PaymentTerm.MONTHLY);
                setPricingQuery((prev) => ({
                  ...prev,
                  term: 'month',
                }));
              },
              selected: model.paymentTerm === PaymentTerm.MONTHLY,
              'data-testid': 'payment-term-monthly',
            },
          ]}
        />
      </Flex>
      <Flex flow="column" gap="md">
        <Text variant="secondary" css={{ fontWeight: '$medium' }}>
          {i18n.t('How many Apple devices will you manage?')}
        </Text>
        <Flex flow="row" gap="xl">
          <Flex
            flow="column"
            justifyContent="center"
            alignItems="center"
            gap="sm"
            css={{
              border: '1px solid $neutral20',
              borderRadius: '$1',
              padding: '$5 $4',
            }}
          >
            <Icon name="sf-desktop-computer" size={24} />
            <Label above css={{ fontWeight: '400' }}>
              {i18n.t('Mac')}
            </Label>
            <TextField
              placeholder={i18n.t('How many?')}
              value={model.macOsDeviceCount}
              onFocus={() => resetValidation('deviceCount')}
              onBlur={() => {
                if (!model.macOsDeviceCount) {
                  onChange('hasEdr', false);
                  onChange('hasVuln', false);
                  setPricingQuery((prev) => ({
                    ...prev,
                    add_ons: { edr: false, vuln: false },
                  }));
                }
              }}
              onChange={(e) => {
                const value = /^-?\d+$/.test(e.target.value)
                  ? Math.abs(parseInt(e.target.value))
                  : '';
                onChange('macOsDeviceCount', value);
                validateDeviceCount(model.iosAppleTvDeviceCount + value);
                setPricingQuery((prev) => ({
                  ...prev,
                  device_counts: {
                    ...prev.device_counts,
                    mac_devices: value || 0,
                  },
                }));
              }}
              type="number"
              min="0"
              data-testid="mac-device-count"
            />
          </Flex>
          <Flex
            flow="column"
            justifyContent="center"
            alignItems="center"
            gap="sm"
            css={{
              border: '1px solid $neutral20',
              borderRadius: '$1',
              padding: '$5 $4',
            }}
          >
            <Flex>
              <Icon name="sf-ipad-landscape" size={24} />
              <Icon name="sf-iphone" size={24} />
              <Icon name="sf-apple-tv" size={24} />
              <Icon name="sf-apple-vision-pro" size={24} />
            </Flex>
            <Label above css={{ fontWeight: '400' }}>
              {i18n.t('Other')}
            </Label>
            <TextField
              placeholder={i18n.t('How many?')}
              value={model.iosAppleTvDeviceCount}
              onFocus={() => resetValidation('deviceCount')}
              onChange={(e) => {
                const value = /^-?\d+$/.test(e.target.value)
                  ? Math.abs(parseInt(e.target.value))
                  : '';
                onChange('iosAppleTvDeviceCount', value);
                validateDeviceCount(model.macOsDeviceCount + value);
                setPricingQuery((prev) => ({
                  ...prev,
                  device_counts: {
                    ...prev.device_counts,
                    other_devices: value || 0,
                  },
                }));
              }}
              type="number"
              data-testid="other-device-count"
            />
          </Flex>
        </Flex>
        <Validation text={validation.deviceCount} />
      </Flex>
      <Flex flow="column" gap="md">
        <Flex gap="sm">
          <Text variant="secondary" css={{ fontWeight: '$medium' }}>
            {i18n.t('Would you like to add a security solution for macOS?')}
          </Text>
        </Flex>

        <Flex gap="sm">
          <Checkbox
            data-testid="edr-checkbox"
            disabled={!model.macOsDeviceCount}
            checked={Boolean(model.hasEdr && model.macOsDeviceCount)}
            onCheckedChange={(checked) => {
              onChange('hasEdr', checked);
              setPricingQuery((prev) => ({
                ...prev,
                add_ons: {
                  ...prev.add_ons,
                  edr: checked,
                },
              }));
            }}
          />
          <Text>{i18n.t('Endpoint Detection and Response')}</Text>
          <TooltipProvider>
            <Tooltip
              side="top"
              content={i18n.t(EDR_TOOLTIP)}
              shadow="no-shadow"
              theme="dark"
              css={{ zIndex: 1000 }}
              trigger="hover"
              interactive
            >
              <IconButton
                icon="circle-info"
                css={{
                  color: '$neutral60',
                  padding: 0,
                  '& svg': { height: '16px' },
                }}
              />
            </Tooltip>
          </TooltipProvider>
        </Flex>

        <Flex gap="sm">
          <Checkbox
            data-testid="vulnerability-checkbox"
            checked={Boolean(model.hasVuln && model.macOsDeviceCount)}
            disabled={!model.macOsDeviceCount}
            onCheckedChange={(checked) => {
              onChange('hasVuln', checked);
              setPricingQuery((prev) => ({
                ...prev,
                add_ons: {
                  ...prev.add_ons,
                  vuln: checked,
                },
              }));
            }}
          />
          <Text>{i18n.t('Vulnerability Management')}</Text>
          <TooltipProvider>
            <Tooltip
              side="top"
              content={i18n.t(VULN_TOOLTIP)}
              shadow="no-shadow"
              theme="dark"
              css={{ zIndex: 1000 }}
              trigger="hover"
              interactive
            >
              <IconButton
                icon="circle-info"
                css={{
                  color: '$neutral60',
                  padding: 0,
                  '& svg': { height: '16px' },
                }}
              />
            </Tooltip>
          </TooltipProvider>
        </Flex>
      </Flex>
      <Flex flow="column" gap="sm">
        <Flex flow="column" gap="sm">
          <Label above>{i18n.t('Street Address')}</Label>
          <TextField
            placeholder={i18n.t('Enter your address')}
            hint={{
              label: i18n.t('Street name and number only'),
              icon: 'omit',
            }}
            value={model.line1}
            onFocus={() => {
              resetValidation('address');
              resetValidation('line1');
            }}
            onChange={(e) => {
              const regex = /[^a-zA-Z0-9\s,.'#-]/g;
              const filteredValue = e.target.value.replace(regex, '');
              onChange('line1', filteredValue);
            }}
            data-testid="address"
          />
          <Validation text={validation.line1} />
        </Flex>
        <Flex flow="row" gap="xl">
          <CountrySelect
            model={model}
            onChange={onChange}
            validation={validation.country}
            resetValidation={resetValidation}
          />
          <Flex flow="column" gap="sm" css={{ flex: 1 }}>
            <Label above>{i18n.t('Zipcode')}</Label>
            <TextField
              placeholder={i18n.t('Enter your zipcode')}
              maxLength={11}
              value={model.zipcode}
              onChange={(e) => {
                const regex = /[^a-zA-Z0-9\s.'#-]/g;
                const filteredValue = e.target.value.replace(regex, '');
                onChange('zipcode', filteredValue);
              }}
              onFocus={() => {
                resetValidation('address');
                resetValidation('zipcode');
              }}
              data-testid="zipcode"
            />
            <Validation text={validation.zipcode} />
          </Flex>
        </Flex>
        <Validation text={validation.zipAndCountry} />
        <Validation text={validation.address} />
      </Flex>

      <div
        className="divider"
        style={{ backgroundColor: '#CAD5E5', height: '1px' }}
      />

      <Flex justifyContent="space-between">
        <Text size="3" css={{ fontWeight: '$medium' }}>
          {i18n.t('Subtotal')}:
        </Text>
        <Flex flow="column" gap="sm">
          <Text size="3" css={{ fontWeight: '$medium' }}>
            {formatToUSD(pricing.data?.total || 0)}/{' '}
            {model.paymentTerm === PaymentTerm.ANNUAL
              ? i18n.t('year')
              : i18n.t('month')}
          </Text>
          <Text size="1" variant="subtle">
            Taxes applied at checkout
          </Text>
        </Flex>
      </Flex>

      <Button
        variant="primary"
        onClick={onSubmit}
        loading={isLoading}
        data-testid="checkout-button"
        disabled={isCheckoutDisabled}
      >
        {i18n.t('Checkout')}
      </Button>
    </Flex>
  );
};

export const CheckoutDialog = ({ onClose, onToggleContactSales }) => {
  const initialValiation = {
    deviceCount: '',
    zipAndCountry: '',
    country: '',
    zipcode: '',
    line1: '',
    address: '',
  };
  const [model, setModel] = useState(deepcopy(defaultModel));
  const [validation, setValidation] = useState(initialValiation);
  const [serverError, setServerError] = useState(false);
  const [checkoutQuery, setCheckoutQuery] = useState<CheckoutQuery>(undefined);

  const { userEmail, currentCompany } = useContext(AccountContext);
  const { data, isLoading, error } = useGoToCheckout(checkoutQuery);

  const handleChange = (key, value) => {
    setModel((prev) => ({ ...prev, [key]: value }));
  };

  const sumOfTwoCounts =
    parseInt(model.macOsDeviceCount || '0', 10) +
    parseInt(model.iosAppleTvDeviceCount || '0', 10);

  const validate = (addressError?: string) => {
    const errors: Record<string, string> = {};

    if (sumOfTwoCounts < MIN_DEVICE_COUNT) {
      errors.deviceCount = i18n.t('Minimum of 1 device required.');
    }
    if (sumOfTwoCounts > MAX_DEVICE_COUNT) {
      errors.deviceCount = i18n.t('Maximum of 300 devices exceeded.');
    }
    if (!model.line1) {
      errors.line1 = i18n.t('Please enter your address.');
    }
    if (!model.zipcode) {
      errors.zipcode = i18n.t('Please enter your zipcode.');
    }
    if (!model.country) {
      errors.country = i18n.t('Please select your country.');
    }
    if (addressError) {
      errors.address = i18n.t(addressError);
    }

    if (Object.keys(errors).length) {
      setValidation((prev) => ({
        ...prev,
        ...errors,
      }));
      return false;
    }

    return true;
  };

  const validateDeviceCount = (totalDeviceCount) => {
    let message: string;
    if (totalDeviceCount < MIN_DEVICE_COUNT) {
      message = i18n.t('Minimum of 1 device required.');
    }
    if (totalDeviceCount > MAX_DEVICE_COUNT) {
      message = i18n.t('Maximum of 300 devices exceeded.');
    }
    setValidation((prev) => ({ ...prev, deviceCount: message }));
  };

  const resetValidation = (input?: keyof typeof validation) => {
    if (input) {
      setValidation((prev) => ({ ...prev, [input]: '' }));
    } else {
      setValidation(initialValiation);
    }
  };

  const onSubmit = () => {
    resetValidation();
    const isValid = validate();
    if (isValid) {
      setCheckoutQuery({
        tenant: {
          id: currentCompany.id as string,
          subdomain: currentCompany.subdomain as string,
        },
        customer_details: {
          email: userEmail,
          address: {
            country: model.country,
            line1: model.line1,
            postal_code: model.zipcode,
          },
        },
        device_counts: {
          mac_devices: parseInt(model.macOsDeviceCount || '0'),
          other_devices: parseInt(model.iosAppleTvDeviceCount || '0'),
        },
        add_ons: {
          edr: model.hasEdr,
          vuln: model.hasVuln,
        },
        term: model.paymentTerm,
      });
    }
  };

  // on success
  useEffect(() => {
    if (data?.url) {
      window.location.href = data.url;
    }
  }, [data?.url]);

  // on error
  useEffect(() => {
    if (error?.message === INVALID_ADDRESS_ERROR) {
      validate(error.message);
    } else if (error?.message === INTERNAL_SERVER_ERROR) {
      setServerError(true);
    }
  }, [error?.message]);

  const content = (
    <DialogContent
      model={model}
      onChange={handleChange}
      resetValidation={resetValidation}
      validateDeviceCount={validateDeviceCount}
      validation={validation}
      onSubmit={onSubmit}
      isLoading={isLoading}
      serverError={serverError}
    />
  );

  const header = (
    <>
      <DialogPrimitives.Header
        css={{ padding: 0, width: '100%', justifyContent: 'center' }}
      >
        {i18n.t('A few more details before you checkout')}
      </DialogPrimitives.Header>
      <IconButton
        icon="xmark"
        data-testId="exit-button"
        onClick={onClose}
        css={{ '& svg': { height: '16px' }, padding: '$1' }}
      />
    </>
  );

  return (
    <Dialog
      css={{ width: '560px' }}
      headerCss={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '32px',
        paddingLeft: '28px',
      }}
      bodyCss={{ height: '100%', overflow: 'auto', paddingBottom: '40px' }}
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onClose}
      content={content}
      closeIcon={null}
      title={header}
    />
  );
};

function GenericError() {
  return (
    <Flex
      gap="sm"
      style={{
        backgroundColor: '#FFE3E3',
        padding: '16px',
        borderRadius: '4px',
      }}
    >
      <Icon name="circle-info" size="sm" color="#DB3025" />
      <Flex flow="column" gap="xs">
        <Text variant="danger" css={{ fontWeight: '$medium' }}>
          {i18n.t('It seems something went wrong')}
        </Text>

        <Text variant="danger" css={{}}>
          {i18n.t('Try to submit again in a moment')}
        </Text>
      </Flex>
    </Flex>
  );
}

export default CheckoutDialog;
