import type { TPlatform } from '@shared/types/platform.types';
import type { IDetailCategory } from './common';
import { WindowsCategory } from './windows.mapping';

export type DeviceDetailsCategory = Array<{
  category: string;
  fields: IDetailCategory;
}>;

export const windowsDeviceDetailsCategories: DeviceDetailsCategory = [
  {
    category: 'General',
    fields: WindowsCategory.General,
  },
  {
    category: 'MDM',
    fields: WindowsCategory.MDM,
  },
  {
    category: 'Hardware Overview',
    fields: WindowsCategory.HawrdwareOverview,
  },
  {
    category: 'Network',
    fields: WindowsCategory.Network,
  },
];

export const appleDeviceDetailsCategories: DeviceDetailsCategory = [];
export const androidDeviceDetailsCategories: DeviceDetailsCategory = [];

export const platformDeviceDetails: Record<TPlatform, DeviceDetailsCategory> = {
  Windows: windowsDeviceDetailsCategories,
  Apple: appleDeviceDetailsCategories,
  Android: androidDeviceDetailsCategories,
};
