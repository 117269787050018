import { Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Code,
  Dialog,
  Flex,
  Icon,
  Loader,
  Text,
} from '@kandji-inc/nectar-ui';
import React, { useEffect, useState } from 'react';

import { getComputerFileVaultPRK } from 'app/_actions/computer';
import { i18n } from 'i18n';

const RecoveryKeyModal = (props) => {
  const { info, onHide } = props;
  const { computerId, recoveryKey, receivedAt } = info;
  const formattedReceivedAt = i18n.format.datetime(receivedAt);
  const [isViewingPreviousKeys, setIsViewingPreviousKeys] = useState(false);
  const [previousKeys, setPreviousKeys] = useState();

  useEffect(() => {
    if (!previousKeys && isViewingPreviousKeys) {
      getComputerFileVaultPRK(computerId, true)
        .then((data) => {
          setPreviousKeys(data);
        })
        .catch(
          /* istanbul ignore next */ () => {
            setPreviousKeys([]);
          },
        );
    }
  }, [isViewingPreviousKeys]);

  const handleOnCopy = (value) => {
    navigator.clipboard.writeText(value);
    toaster(i18n.t('Copied to clipboard.'));
  };

  const content = recoveryKey ? (
    <Flex
      flow="column"
      gap="xl"
      css={{ minWidth: '$15', maxHeight: '$15', overflowY: 'auto' }}
    >
      <Flex flow="column" gap="sm">
        {receivedAt && <Text>{`Key received on ${formattedReceivedAt}:`}</Text>}
        <Flex flow="row" gap="sm">
          <Icon name="key" color="var(--colors-neutral90)" />
          <Code>{recoveryKey}</Code>
          <Box
            css={{ '&:hover': { cursor: 'pointer' } }}
            onClick={() => handleOnCopy(recoveryKey)}
            data-testid="copy-recovery-key"
          >
            <Icon name="copy" color="var(--colors-neutral50)" size="sm" />
          </Box>
        </Flex>
      </Flex>

      <Accordion
        collapsible
        onValueChange={(item) => setIsViewingPreviousKeys(Boolean(item))}
      >
        <AccordionItem value="previous-keys">
          <AccordionTrigger css={{ paddingBottom: '$2' }}>
            Previous Keys
          </AccordionTrigger>
          <AccordionContent>
            {!previousKeys && (
              <Flex justifyContent="center">
                <Loader />
              </Flex>
            )}
            {previousKeys?.length > 0 && (
              <Flex flow="column" gap="xl">
                {previousKeys.map((key) => {
                  const { received_at, value } = key;
                  const formattedReceivedAtPreviousKey =
                    i18n.format.datetime(received_at);

                  return (
                    <Flex flow="column" gap="sm" key={value}>
                      <Text>{`Key received on ${formattedReceivedAtPreviousKey}:`}</Text>
                      <Flex flow="row" gap="sm">
                        <Icon name="key" color="var(--colors-neutral90)" />
                        <Code>{value}</Code>
                        <Box
                          css={{ '&:hover': { cursor: 'pointer' } }}
                          onClick={() => handleOnCopy(value)}
                          data-testid="copy-previous-recovery-key"
                        >
                          <Icon
                            name="copy"
                            color="var(--colors-neutral50)"
                            size="sm"
                          />
                        </Box>
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            )}
            {previousKeys?.length === 0 && (
              <Text>
                No additional FileVault Recovery Keys have been escrowed into
                Kandji.
              </Text>
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Flex>
  ) : (
    <Text>There is no recovery key for this computer.</Text>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      onOpenChange={onHide}
      title="FileVault Recovery Key"
      content={content}
      css={{ zIndex: 2000 }}
    />
  );
};

export default RecoveryKeyModal;
