/* istanbul ignore file */
import { formatNumber } from '@kandji-inc/nectar-i18n';
import {
  FilterButton,
  Flex,
  MultiSelect,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import { useMemo, useState } from 'react';
import { i18n } from 'src/i18n';
import { useGetSoftware } from '../../../hooks/dashboard/use-get-software';
import {
  type AllVulnerabilitiesFilter,
  VulnerabilityType,
} from '../../../vulnerability.types';
import { SoftwareIcon } from '../../software-icon';

type SoftwareMultiSelectProps = {
  software: AllVulnerabilitiesFilter['softwareName']['appName' | 'osName'];
  setSoftware: (
    software: AllVulnerabilitiesFilter['softwareName']['appName' | 'osName'],
  ) => void;
  type: VulnerabilityType;
};

const SoftwareMultiSelect = (props: SoftwareMultiSelectProps) => {
  const { software, setSoftware, type } = props;
  const { software: softwareList, isLoading } = useGetSoftware();
  const [softwareSearchInput, setSoftwareSearchInput] = useState('');

  const visibleItems = software.slice(0, 3);
  const hiddenItemCount = software.length - visibleItems.length;

  const typeLabel = {
    [VulnerabilityType.application]: (plural: boolean) =>
      plural ? i18n.t('All applications') : i18n.t('Application'),
    [VulnerabilityType.operatingSystem]: (plural: boolean) =>
      plural ? i18n.t('All operating systems') : i18n.t('Operating system'),
  };

  const [softwareOptions, allSoftwareOptions] = useMemo(() => {
    const options = softwareList
      .filter(({ vulnerability_type }) => vulnerability_type === type)
      .map((s) => ({
        label: s.name,
        richLabel: (
          <Flex alignItems="center" gap="xs">
            <SoftwareIcon iconKey={s.icon_key} size="xs" />
            <Text>{s.name}</Text>
          </Flex>
        ),
        value: s.name,
      }));

    if (softwareSearchInput) {
      return [
        options.filter((option) =>
          option.label
            .toLowerCase()
            .includes(softwareSearchInput.toLowerCase()),
        ),
        options,
      ];
    }

    return [options, options];
  }, [softwareList, softwareSearchInput]);

  return (
    <MultiSelect
      /* Show "Select All" when there is no search input */
      {...(softwareSearchInput
        ? {}
        : {
            selectAll: {
              selectAllLabel: i18n.t('Select all ({count})', {
                count: formatNumber(allSoftwareOptions.length),
              }),
            },
          })}
      disabled={isLoading}
      options={softwareOptions}
      value={software}
      onChange={(value) => setSoftware(value)}
      customHeader={
        <TextField
          compact
          p2
          placeholder={i18n.t('Search')}
          icon="magnifying-glass"
          value={softwareSearchInput}
          onChange={(e) => setSoftwareSearchInput(e.target.value)}
          onClear={() => setSoftwareSearchInput('')}
          showClearButton={Boolean(softwareSearchInput)}
        />
      }
      footer={{
        showClear: true,
        clearLabel: i18n.t('Clear'),
        handleClear: () => setSoftware([]),
      }}
      componentCss={{ menu: { zIndex: 3 } }}
    >
      <FilterButton
        filtersSelected={Boolean(software.length)}
        showRemove={false}
        maxWidth={400}
        css={{ height: 28 }}
        variant="subtle"
      >
        <Flex flow="row" alignItems="center">
          {allSoftwareOptions.length > 0 &&
            software.length === allSoftwareOptions.length && (
              <Text css={{ fontWeight: '$medium' }}>
                {typeLabel[type](true)}
              </Text>
            )}
          {software.length === 0 && (
            <Text css={{ fontWeight: '$medium' }}>
              {typeLabel[type](false)}
            </Text>
          )}
          {software.length !== allSoftwareOptions.length &&
            software.length !== 0 && (
              <Flex flow="row" gap="xs">
                <Flex alignItems="center" css={{ flexWrap: 'nowrap' }}>
                  <Text css={{ fontWeight: '$medium' }}>
                    {visibleItems.join(', ')}
                  </Text>
                </Flex>
                {hiddenItemCount > 0 && (
                  <Text>{i18n.t('+{count}', { count: hiddenItemCount })}</Text>
                )}
              </Flex>
            )}
        </Flex>
      </FilterButton>
    </MultiSelect>
  );
};

export { SoftwareMultiSelect };
