import type { FIXME_Any } from '@kandji-inc/nectar-ui';
import {
  BoolFilterType,
  DateFilterType,
  EnumFilterType,
  FilterSearchButton,
  Flex,
  NumFilterType,
  StringFilterType,
  TimeFilterType,
  VersionFilterType,
  isNullOrNotNullOperator,
} from '@kandji-inc/nectar-ui';
import type { Table } from '@tanstack/react-table';
import { i18n } from 'i18n';
import _ from 'lodash';
import * as React from 'react';

import { usePrismContext } from '../../contexts/PrismContext';
import { usePrismUrlContext } from '../../contexts/PrismUrlContext';
import type { PrismCategoryFilterProperties as ColFilters } from '../../types/filter.types';
import {
  createApplyFilter,
  createClearFilter,
  createRemoveFilter,
  getInitialFilterOptions,
  getNewFilterByType,
  parseFilterOptionMetaFromColumnDefs,
  parseFilterableFromSchema,
} from './utils/prismFiltersUtils';

type Props = Readonly<{
  table: Table<FIXME_Any>;
}>;

// This is to prevent endless renders when the filter options change.
// Open to better solutions.
function useDeepCompareEffect(callback: any, dependencies: any): any {
  const firstRenderRef = React.useRef(true);
  const dependenciesRef = React.useRef(dependencies);

  if (!_.isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
  }

  React.useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    return callback();
  }, [dependenciesRef.current]);
}

const PrismCategoryFilters = ({ table }: Props) => {
  const isMounted = React.useRef(false);
  const { selectedPrismCategory } = usePrismContext();

  const { setFilter, filter, removeFilter, viewId } = usePrismUrlContext();

  const { columnFilters } = table.getState<{
    columnFilters: Array<ColFilters['unionOf']['details']>;
  }>();

  // a hack to reset the column filters when the view changes
  React.useEffect(() => {
    queueMicrotask(() => {
      table.resetColumnFilters(true);
    });
  }, [viewId, table]);

  const columnSchema = React.useMemo(() => {
    if (!selectedPrismCategory) {
      return undefined;
    }

    return parseFilterableFromSchema(selectedPrismCategory);
  }, [selectedPrismCategory]);

  const [sectionedOptions, filterOptions] = React.useMemo(() => {
    const columns = table.getAllColumns();
    return parseFilterOptionMetaFromColumnDefs({
      columns,
      columnSchema,
      columnFilters,
    });
  }, [columnSchema, columnFilters, table]);

  const updateTableFilters = React.useMemo(() => {
    // istanbul ignore next
    if (filter == null) {
      return [];
    }
    const initialFilterOptions = Object.entries(filter)
      .map(getInitialFilterOptions(filterOptions))
      .filter(Boolean);

    return initialFilterOptions;
  }, [filter, filterOptions]);

  useDeepCompareEffect(() => {
    // istanbul ignore next
    table.setColumnFilters(updateTableFilters);
    table.resetPageIndex();
  }, [table, updateTableFilters]);

  React.useEffect(() => {
    // don't reset the column filters on the first render in order to prevent
    // prematurely resetting saved view initial filters state
    /* istanbul ignore next */
    if (isMounted.current) {
      return;
    }

    table.resetColumnFilters(true);
  }, [selectedPrismCategory?.uri, table]);

  React.useEffect(() => {
    isMounted.current = true;
  }, []);

  return columnSchema ? (
    <Flex flow="row" wrap="wrap" gap="sm" css={{ flexGrow: 1, flexBasis: 0 }}>
      {columnFilters?.map((filter: ColFilters['unionOf']['details']) => {
        const handleApply = createApplyFilter(
          selectedPrismCategory?.uri,
          filter,
          setFilter,
        );

        const handleRemove = createRemoveFilter(
          selectedPrismCategory?.uri,
          filter,
          removeFilter,
          table.setColumnFilters,
        );

        const handleClear = createClearFilter(
          selectedPrismCategory?.uri,
          filter,
          removeFilter,
        );

        const handleCancel = ({ isFilterEmpty }) => {
          if (isFilterEmpty) {
            handleRemove();
          }
        };

        const shouldHideNullOperators = filter.key === 'blueprint_name';

        const commonProps = {
          showRemove: true,
          key: filter.key,
          handleApply,
          handleCancel,
          handleClear,
          handleRemove,
          dropdownProps: {
            css: {
              zIndex: 10,
            },
            defaultOpen:
              !isNullOrNotNullOperator(filter.operator) &&
              (filter.value == null || filter.value.length === 0),
            contentProps: {
              align: 'start' as const,
              preventOutsideInteraction: true,
            },
          },
        };

        switch (filter.type) {
          case 'string': {
            return (
              <StringFilterType
                hideNullOperators={shouldHideNullOperators}
                filter={filter}
                {...commonProps}
              />
            );
          }

          case 'enum':
            return (
              <EnumFilterType
                multi
                filter={filter}
                enumOptions={filter.enumOptions}
                {...commonProps}
              />
            );

          case 'date-time':
            return <DateFilterType filter={filter} {...commonProps} />;

          case 'boolean': {
            return (
              <BoolFilterType
                filter={filter}
                boolOptions={filter.boolOptions}
                {...commonProps}
              />
            );
          }

          case 'number': {
            return <NumFilterType filter={filter} {...commonProps} />;
          }

          case 'time': {
            return <TimeFilterType filter={filter} {...commonProps} />;
          }

          case 'version': {
            return <VersionFilterType filter={filter} {...commonProps} />;
          }

          default:
            return null;
        }
      })}

      <FilterSearchButton
        text={columnFilters.length ? '' : i18n.t('Add filter')}
        asButton={columnFilters.length ? 'button' : 'filter-button'}
        options={sectionedOptions}
        onFilterSelect={(key) => {
          const option = filterOptions.find((opt) => opt?.value === key);
          const newFilter = getNewFilterByType(key as string, option);
          if (newFilter) {
            table.setColumnFilters<Array<ColFilters['unionOf']['details']>>(
              (prev) => [...prev, newFilter],
            );
          }
        }}
        componentCss={{
          menu: {
            zIndex: 11,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '$1',
            },
            '&:hover': {
              '&::-webkit-scrollbar-track': {
                background: 'rgba(243, 247, 250)',
                borderRadius: '$rounded',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(80, 94, 113, 0.24)',
                borderRadius: '$rounded',
                height: '50px',
              },
            },
          },
        }}
      />
    </Flex>
  ) : null;
};

export default PrismCategoryFilters;
