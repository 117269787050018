import { Box, Tabs, styled } from '@kandji-inc/nectar-ui';
import type React from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import {
  links,
  tabsNames,
  tabsNamesTranslationMap,
} from 'src/app/common/constants';
import useAccount from 'src/contexts/account';
import DeviceStatusTab from 'src/features/device-status-tab';
import ThreatList from 'src/features/edr/threat';
import { VulnerabilitiesTable } from 'src/features/edr/vulnerability/single-device-record/vulnerabilities-table';
import { useGetLibrary } from 'src/features/library-items/data-service/library/useGetLibraryItems';
import {
  apiTypes,
  deviceTypes,
} from 'src/features/library-items/library/common';
import { getLostModeStatus } from 'src/hooks/useLostMode';

import { i18n } from 'i18n';
import DeviceDetails from 'src/features/single-device/details/DeviceDetails';
import ActivityTabNew from '../ActivityTabNew';
import ApplicationInventoryTab from '../ApplicationInventoryTab';
import { Loader } from '../interface/Loader';
import NotesTab from '../notes/NotesTab';
import ParameterHistory from './parameter-history';
import LostModeTab from './tabs/LostModeTab';
import useComputer from './useComputer';

const TabsWrapper = styled(Box, {
  "& [data-nectar-styled-name='group']": {
    position: 'sticky',
    top: '88px',
    backgroundColor: '$neutral0',
    zIndex: 10,
  },
  "& [role='tablist']": {
    padding: '0 $5',
    paddingTop: '$4',
  },
});

const SingleDeviceTabs = (props: any) => {
  const { setEditor } = props;
  const { id: computerId, tab } = useParams<{ id: string; tab: string }>();
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();
  const {
    currentCompany: { feature_configuration },
  } = useAccount();
  const isVulnEnabled =
    feature_configuration?.vulnerability_management?.enabled;
  const { data: computer } = useComputer(computerId);
  const { data: threatRes } = useGetLibrary({
    loc: 'list',
    apiParams: {
      blueprint__in: computer?.blueprint_id,
      type__in: apiTypes.THREAT_SECURITY_POLICY,
    },
    options: {
      enabled: !!computer?.blueprint_id,
    },
  });

  const { isLostModePending, isLostModeEnabled, isLostModeErrored } =
    getLostModeStatus(computer);
  const shouldShowLostMode =
    isLostModePending || isLostModeEnabled || isLostModeErrored;
  const prevIsLostModeShowing = useRef(null);

  const isThreatEnabled = feature_configuration?.edr?.enabled;
  const hasThreatInstalled = Boolean((threatRes as any)?.data?.count);

  /* istanbul ignore next */
  useEffect(() => {
    if (prevIsLostModeShowing.current === undefined) {
      prevIsLostModeShowing.current = shouldShowLostMode;
      return;
    }

    if (prevIsLostModeShowing.current && !shouldShowLostMode) {
      goToTab('status');
    } else if (!prevIsLostModeShowing.current && shouldShowLostMode) {
      goToTab('lost-mode');
    }
    prevIsLostModeShowing.current = shouldShowLostMode;
  }, [shouldShowLostMode]);

  const goToTab = (tab: string) =>
    history.push(`${links.devices}/${computerId}/${tab}`);

  const getTab = (url: string, defaultTab = 'status') => {
    const tab = tabs.find(
      (tab) => url.includes(tab.path) || url.includes(tab.altPath),
    );

    if (!tab) {
      return defaultTab;
    }

    if (tab.isHidden && !tab.isHiddenTabNotRedirectedToDefaultTab) {
      goToTab(defaultTab);
      return defaultTab;
    }

    return tab.path;
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (tab !== 'threats' || !threatRes) return;

    const isDelayedRedirectFromThreat = !(
      isThreatEnabled &&
      computer?.device_family === deviceTypes.MAC &&
      hasThreatInstalled
    );

    if (isDelayedRedirectFromThreat) goToTab('status');
  }, [threatRes]);

  const routerProps = {
    location,
    match,
    history,
  };

  const TabLostMode = useMemo(() => {
    if (shouldShowLostMode) {
      return <LostModeTab computer={computer} />;
    }

    return null;
  }, [shouldShowLostMode, computer]);

  const TabStatus = useMemo(() => {
    /* istanbul ignore next */
    if (tab === 'paramHistoryStatus') {
      return (
        <ParameterHistory
          onGoToNotes={(withNote) => {
            setEditor(withNote);
            goToTab('notes');
          }}
        />
      );
    }

    return <DeviceStatusTab />;
  }, [computer, routerProps]);

  const TabActivity = useMemo(
    () => (
      <ActivityTabNew
        computerId={computerId}
        activityTabType="computer"
        filters={['type', 'period']}
        {...routerProps}
      />
    ),
    [computer, routerProps],
  );

  const TabAppInventory = useMemo(
    () => (
      <ApplicationInventoryTab computer={{ ...computer }} {...routerProps} />
    ),
    [computer, routerProps],
  );

  const TabThreats = useMemo(() => {
    if (
      !isThreatEnabled ||
      computer?.device_family !== deviceTypes.MAC ||
      !hasThreatInstalled
    ) {
      return null;
    }

    return <ThreatList computer={computer} isEventsView />;
  }, [computer, isThreatEnabled, hasThreatInstalled]);

  const TabNotes = useMemo(
    () => <NotesTab computerId={computerId} />,
    [computer, routerProps],
  );

  const TabVulnerabilities = useMemo(
    () => <VulnerabilitiesTable computerId={computerId} />,
    [computer, routerProps],
  );

  if (!computer) {
    return <Loader />;
  }

  const tabs = [
    {
      label: tabsNamesTranslationMap(tabsNames.lostMode),
      tabId: tabsNames.lostMode,
      path: 'lost-mode',
      render: TabLostMode,
      isHidden: !shouldShowLostMode,
    },
    {
      label: tabsNamesTranslationMap(tabsNames.status),
      tabId: tabsNames.status,
      path: 'status',
      altPath: 'paramHistoryStatus',
      render: TabStatus,
    },
    {
      label: tabsNamesTranslationMap(tabsNames.activity),
      tabId: tabsNames.activity,
      path: 'activity',
      render: TabActivity,
    },
    {
      label: tabsNamesTranslationMap(tabsNames.deviceDetails),
      tabId: tabsNames.deviceDetails,
      path: 'details',
      render: (
        <DeviceDetails
          deviceId={computer.id}
          deviceFamily={computer.device_family}
        />
      ),
    },
    {
      label: tabsNamesTranslationMap(tabsNames.notes),
      tabId: tabsNames.notes,
      path: 'notes',
      render: TabNotes,
    },
    {
      label:
        computer?.device_family === deviceTypes.MAC
          ? tabsNamesTranslationMap(tabsNames.applicationInventoryTab)
          : tabsNamesTranslationMap(tabsNames.applicationInventoryTabForIOS),
      tabId: tabsNames.applicationInventoryTab,
      path: 'application-inventory',
      render: TabAppInventory,
    },
    {
      label: i18n.t('Threats'),
      tabId: tabsNames.threats,
      path: 'threats',
      render: TabThreats,
      isHidden: !(
        isThreatEnabled &&
        computer?.device_family === deviceTypes.MAC &&
        hasThreatInstalled
      ),
      isHiddenTabNotRedirectedToDefaultTab: true,
    },
    {
      label: i18n.t('Vulnerabilities'),
      tabId: tabsNames.vulnerabilities,
      path: 'vulnerabilities',
      render: TabVulnerabilities,
      css: { padding: 0 },
      isHidden: !(isVulnEnabled && computer?.device_family === deviceTypes.MAC),
    },
  ];

  const visibleTabs = tabs.filter((tab) => !tab.isHidden);

  return (
    <TabsWrapper>
      <Tabs.Container
        compact
        tabs={visibleTabs.map((tab) => ({
          ...tab,
          tabId: tab.path,
          onClick: () => {
            window.scrollTo(0, 0);
            goToTab(tab.path);
          },
        }))}
        defaultTabId={getTab(match.url)}
        tabId={getTab(match.url)}
      >
        {visibleTabs.map((tab) => {
          return (
            <Tabs.Content tabId={tab.path} key={tab.path}>
              <Box css={{ padding: '0 24px', ...tab.css }}>
                {tab.render as React.ReactNode}
              </Box>
            </Tabs.Content>
          );
        })}
      </Tabs.Container>
    </TabsWrapper>
  );
};

export default connect()(SingleDeviceTabs);
