import { Flex } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { DataTable } from 'src/components';
import type {
  PulseFileCheckDeviceSchema,
  PulseLoggedInUsersDeviceSchema,
  PulsePreferencesDeviceSchema,
  PulseProcessCheckDeviceSchema,
} from 'src/features/visibility/pulse/types/pulse.types';
import { NoResultsReturned, Pending } from './pulse-check-details-shared';

type PulseResultsDataSchema =
  | PulseFileCheckDeviceSchema[]
  | PulseLoggedInUsersDeviceSchema[]
  | PulsePreferencesDeviceSchema[]
  | PulseProcessCheckDeviceSchema[];

export const PulseResultsTable = ({
  jobDevicesData = [],
  isLoading,
  columns,
  onRerunJob,
}: {
  jobDevicesData: PulseResultsDataSchema;
  isLoading: boolean;
  columns: any[];
  onRerunJob: () => void;
}) => {
  const isEmpty = jobDevicesData.length === 0;
  const showNoResults = !isLoading && isEmpty;

  return (
    <>
      {/* <Flex css={{ alignSelf: 'stretch' }} alignItems="center" gap="sm">
        <TextField
          iconLeft
          icon="magnifying-glass"
          placeholder="Search by device, user, or Blueprint"
          css={{ minWidth: '180px', flex: '1 0 0', maxWidth: '480px' }}
          compact
          disabled={showNoResults || isLoading}
        />
        <Button
          compact
          iconRight
          icon={{ name: 'plus', position: 'right' }}
          css={{ '& svg': { width: '16px', height: '16px' } }}
          disabled={showNoResults || isLoading}
        >
          Add filter
        </Button>
      </Flex> */}
      <Flex flow="column">
        <DataTable
          columns={columns}
          pinnedColumns={['device__name']}
          data={jobDevicesData}
          offsets={{ container: 294 }}
        />
      </Flex>
      {isLoading ? <Pending /> : null}
      {showNoResults ? <NoResultsReturned onRerun={onRerunJob} /> : null}
    </>
  );
};
