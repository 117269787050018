/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import { transformDetectionDateFilterToApi } from '../../helpers';
import type { AllVulnerabilitiesFilter } from '../../vulnerability.types';

const useGetExploitabilityScore = (
  softwareName: AllVulnerabilitiesFilter['softwareName'] = null,
  timespan: AllVulnerabilitiesFilter['latestDetected'] = null,
  severity: AllVulnerabilitiesFilter['severity'] = null,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: [
      'vulnerabilities-by-software',
      softwareName,
      timespan,
      severity,
      ...(keys || []),
    ],
    queryFn: () =>
      vulnerabilityApi('v2/dashboards/vulnerabilities_by_software').get({
        ...params,
        filter: {
          vulnerable_software: {
            in:
              softwareName?.appName?.length || softwareName?.osName?.length
                ? [...softwareName.appName, ...softwareName.osName]
                : null,
          },
          severity: { in: severity.length ? severity : null },
          timespan: transformDetectionDateFilterToApi(timespan),
        },
      }),
  });

  const data = apiRes?.data;
  const numVulnerabilities: number = data?.count || 0;
  const KEVScore: number = data?.kev_score || 0;
  const trend: number = data?.trend || 0;

  return { numVulnerabilities, KEVScore, trend, isLoading };
};

export { useGetExploitabilityScore };
