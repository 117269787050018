import type { Paths } from '@shared/types/zod.common.types';
import type { WindowsDeviceDetailRootSchema } from 'src/schemas/device/windows-device-detail.schema';
import { formatDiskSize } from 'src/utils/SizeConversionUtils';
import type z from 'zod';
import type { IDetailCategory } from './common';

export type WindowsDetailsDataPaths = Paths<
  typeof WindowsDeviceDetailRootSchema
>;
export type TWindowsDeviceDetail = z.infer<
  typeof WindowsDeviceDetailRootSchema
>;

export const general: IDetailCategory<WindowsDetailsDataPaths> = {
  deviceType: {
    dataKey: 'info.device_type',
    title: 'Device type',
  },
  modelNumberOrName: {
    dataKey: 'info.device_model',
    title: 'Model number or name',
  },
  oemName: {
    dataKey: ['csps.DevDetail.content.OEM', 'csps.DevInfo.content.Man'],
    title: 'Original Equipment Manufacturer (OEM) name',
  },
  deviceName: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.DeviceName',
    title: 'Device name',
  },
  localTime: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.LocalTime',
    title: 'Local time',
  },
  currentLanguage: {
    dataKey: 'csps.DevInfo.content.Lang',
    title: 'Current language',
  },
  firmwareVersion: {
    dataKey: 'csps.DevDetail.content.FwV',
    title: 'Firmware version',
  },
  hardwareVersion: {
    dataKey: 'csps.DevDetail.content.HwV',
    title: 'Hardware version',
  },
  softwareVersion: {
    dataKey: 'csps.DevDetail.content.SwV',
    title: 'Software version',
  },
  supportsOmaDmLargeObjectHandling: {
    dataKey: 'csps.DevDetail.content.LrgObj',
    title: 'Supports OMA DM Large Object Handling',
    transform: String,
  },
  maxDepthOfTheManagementTree: {
    dataKey: 'csps.DevDetail.content.URI.MaxDepth',
    title: 'Maximum depth of the management tree',
  },
  maxSegmentLength: {
    dataKey: 'csps.DevDetail.content.URI.MaxSegLen',
    title: 'Maximum segment length',
  },
  maxTotalLength: {
    dataKey: 'csps.DevDetail.content.URI.MaxTotLen',
    title: 'Maximum total length',
  },
};

export const mdm: IDetailCategory<WindowsDetailsDataPaths> = {
  deviceIdentifier: {
    dataKey: 'id',
    title: 'Device ID (Kandji)',
  },
  deviceIdentifierWindows: {
    dataKey: 'csps.DevInfo.content.DevId',
    title: 'Device identifier (Windows)',
  },
  currentManagementClientRevision: {
    dataKey: 'csps.DevInfo.content.DmV',
    title: 'Current management client revision of the device',
  },
};

export const hardwareOverview: IDetailCategory<WindowsDetailsDataPaths> = {
  commercializationOperator: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.CommercializationOperator',
    title: 'Commercialization operator',
  },
  freeStorageSpace: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.FreeStorage',
    title: 'Free storage space',
    transform: (value) => formatDiskSize(value, 'MB'),
  },
  osPlatform: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.OSPlatform',
    title: 'Operating system platform',
  },
  processorArchitecture: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.ProcessorArchitecture',
    title: 'Processor architecture',
  },
  processorType: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.ProcessorType',
    title: 'Processor type',
  },
  radioSoftwareVersion: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.RadioSwV',
    title: 'Radio software version',
  },
  screenResolution: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.Resolution',
    title: 'Screen resolution',
  },
  smbiosSerialNumber: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.SMBIOSSerialNumber',
    title: 'SMBIOS serial number',
  },
  smbiosVersion: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.SMBIOSVersion',
    title: 'SMBIOS version',
  },
  systemSku: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.SystemSKU',
    title: 'System SKU',
  },
  totalRam: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.TotalRAM',
    title: 'Total RAM',
    transform: (value) => formatDiskSize(value, 'MB'),
  },
  totalStorageCapacity: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.TotalStorage',
    title: 'Total storage capacity',
    transform: (value) => formatDiskSize(value, 'MB'),
  },
};

export const network: IDetailCategory<WindowsDetailsDataPaths> = {
  dnsComputerName: {
    dataKey: 'csps.DevDetail.content.Ext.Microsoft.DNSComputerName',
    title: 'DNS computer name',
  },
  // iccid: { // TODO: Can't find this in example data
  //   dataKey: "csps.DevDetail.content.Ext.Microsoft.",
  //   title: "Integrated Circuit Card Identifier (ICCID) of the first adapter",
  // },
  volteServiceSetting: {
    dataKey: 'csps.DevDetail.content.Ext.VoLTEServiceSetting',
    title: 'VoLTE service setting',
    transform: String,
  },
  wlanDnsSuffix: {
    dataKey: 'csps.DevDetail.content.Ext.WlanDnsSuffix',
    title: 'Wi-Fi DNS suffix',
  },
  wlanIPv4Address: {
    dataKey: 'csps.DevDetail.content.Ext.WlanIPv4Address',
    title: 'Wi-Fi IPv4 address',
  },
  wlanIPv6Address: {
    dataKey: 'csps.DevDetail.content.Ext.WlanIPv6Address',
    title: 'Wi-Fi IPv6 address',
  },
  wlanMACAddress: {
    dataKey: 'csps.DevDetail.content.Ext.WLANMACAddress',
    title: 'Wi-Fi MAC address',
  },
  wlanSubnetMask: {
    dataKey: 'csps.DevDetail.content.Ext.WlanSubnetMask',
    title: 'Wi-Fi subnet mask',
  },
};

export const WindowsCategory = {
  General: general,
  MDM: mdm,
  HawrdwareOverview: hardwareOverview,
  Network: network,
};
