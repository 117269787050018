/* istanbul ignore file */
import { blueprintLibraryCategories } from 'features/blueprints/common';
import { i18n } from 'i18n';
import {
  apiTypes,
  canAddLibraryTypes,
  categories,
  deviceTypes,
  i18nOsRequirements,
  osRequirements,
  publishers,
  publishersTranslations,
} from '../../common';
import svgs from '../icons';
import LibraryItemConfig from './item-config.class';

const securityApps = {
  Avert: new LibraryItemConfig({
    getName: () => i18n.t('Avert'),
    getDescription: () =>
      i18n.t(
        'Configure EDR settings, including the response postures for malware, PUP, and behavioral detections, and manage the allow or block list for applications and files.',
      ),
    name: 'Avert',
    description:
      'Configure EDR settings, including the response postures for malware, PUP, and behavioral detections, and manage the allow or block list for applications and files.',
    type: apiTypes.THREAT_SECURITY_POLICY,
    publisher: publishers.KANDJI,
    getPublisher: () => publishersTranslations(publishers.KANDJI),
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_15],
    getOsRequirements: () => [i18nOsRequirements(osRequirements.MAC_10_15)],
    category: categories.SECURITY,
    blueprintCategory: blueprintLibraryCategories.SECURITY,
    icon: svgs.Avert,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'avert',
    getUrl: ({ id }) => `/library/avert/${id}`,
    getAddUrl: () => `/library/avert/add`,
    isHidden: ({ account }) =>
      !account.currentCompany?.feature_configuration?.edr?.enabled,
    isOldPath: () => false,
    singleBlueprintAllowed: true,
  }),
  DeviceControl: new LibraryItemConfig({
    getName: () => i18n.t('Accessory & Storage Access'),
    getDescription: () =>
      i18n.t(
        'Manage removable storage settings, including DMG file types, external volumes, and accessory access controls.',
      ),
    getPublisher: () => publishersTranslations(publishers.KANDJI),
    getOsRequirements: () => [i18nOsRequirements(osRequirements.MAC_10_15)],
    name: 'Accessory & Storage Access',
    description:
      'Manage removable storage settings, including DMG file types, external volumes, and accessory access controls.',
    type: apiTypes.DEVICE_CONTROL,
    publisher: publishers.KANDJI,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_15],
    category: categories.SECURITY,
    blueprintCategory: blueprintLibraryCategories.SECURITY,
    icon: svgs['Media Access'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'device-control',
    getUrl: ({ id }) => `/library/device-control/${id}`,
    getAddUrl: () => `/library/device-control/add`,
    isHidden: ({ account }) =>
      !account.currentCompany?.feature_configuration?.edr?.enabled,
    isOldPath: () => false,
    singleBlueprintAllowed: true,
  }),
};

export default securityApps;
