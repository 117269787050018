// istanbul ignore file
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import type { GetMitreTechniquesResponse } from '../../../../threat.types';
import mitreTechniquesQuery from './mitreTechniquesQuery';

type UseMitreTechniques = () => {
  data: GetMitreTechniquesResponse | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: () => Promise<void>;
};

/**
 * Retrieves unique paths based on the provided file hash.
 *
 * @param filters - The filters to apply to the unique paths.
 * @returns An object containing the unique paths data and a boolean indicating if the data is currently being loaded.
 */
const useGetMitreTechniques: UseMitreTechniques = () => {
  const { data, isPlaceholderData, isLoading, isError, refetch } = useQuery({
    queryKey: ['mitre-techniques'],
    queryFn: mitreTechniquesQuery,
    placeholderData: keepPreviousData,
    retry: 2,
    retryDelay: 1000,
  });

  return {
    data: data?.data,
    isLoading: isLoading || isPlaceholderData,
    isError,
    refetch: refetch as unknown as () => Promise<void>,
  };
};

export default useGetMitreTechniques;
