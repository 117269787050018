import { Button, Flex, Icon, TextArea, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';

export const ChatInput = ({
  onSubmit,
  disabled,
}: { onSubmit: (message: string) => void; disabled: boolean }) => {
  const [messageInput, setMessageInput] = React.useState<string>('');
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageInput(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(messageInput);
    setMessageInput('');
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <TextInputContainer>
        <Flex css={{ flex: 1 }}>
          <TextArea
            data-testid="kai-input"
            rows={1}
            autoFocus
            value={messageInput}
            onChange={handleInputChange}
            onKeyDown={
              // istanbul ignore next
              (e) => {
                if (e.key === 'Enter' && messageInput.trim() !== '') {
                  if (!e.metaKey) {
                    e.preventDefault();
                    onSubmit(messageInput);
                    setMessageInput('');
                  } else {
                    setMessageInput((prev) => `${prev}\n`);
                  }
                  window.setTimeout(() => {
                    const target = e.target as HTMLTextAreaElement;
                    target?.dispatchEvent(
                      new Event('input', { bubbles: true }),
                    );
                  }, 50);
                }
              }
            }
            minHeight={40}
            maxHeight={140}
            placeholder={i18n.t('Ask Kai...')}
            css={{ width: '100%' }}
          />
        </Flex>
        <Button
          data-testid="kai-send-button"
          variant="primary"
          type="submit"
          css={{ '& svg': { width: '20px', height: '20px' }, height: '40px' }}
          disabled={messageInput.length === 0 || disabled}
        >
          <Icon name="arrow-up" />
        </Button>
      </TextInputContainer>
    </StyledForm>
  );
};

const StyledForm = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const TextInputContainer = styled(Flex, {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '$2',
  alignSelf: 'stretch',
  padding: '$2',
});
