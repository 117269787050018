import { Flex, Heading, Icon, Text } from '@kandji-inc/nectar-ui';

import { SoftwareIcon } from '../../common/software-icon';
import type { Vulnerability } from '../../vulnerability.types';

const Header = (props: { vulnerability: Vulnerability }) => {
  const { vulnerability } = props;
  const { cve_id, name, icon_key, version } = vulnerability;

  return (
    <Flex
      justifyContent="start"
      gap="sm"
      alignItems="center"
      css={{ flexGrow: 1 }}
    >
      <Flex
        p1
        css={{
          backgroundColor: '$red10',
          borderRadius: '$round',
          height: '40px',
          width: '40px',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Icon
          name="shield-magnifying-glass-virus"
          color="var(--colors-red60)"
          style={{ height: 26, width: 26 }}
        />
      </Flex>
      <Flex flow="column">
        <Flex gap="sm">
          <Heading size="3" css={{ fontWeight: '$medium' }}>
            {cve_id}
          </Heading>
        </Flex>
        <Flex gap="xs" alignItems="center">
          <SoftwareIcon iconKey={icon_key} size="sm" />
          <Text variant="secondary">{name}</Text>
          <Text variant="secondary">|</Text>
          <Text variant="secondary">{version}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { Header };
