import { Chip, Flex, Text, styled } from '@kandji-inc/nectar-ui';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { OnApply, OnClear } from 'src/features/edr/common/common.types';
import EDRTooltip from 'src/features/edr/common/components/EDRTooltip';
import EDRSearchFilter from 'src/features/edr/common/components/Filters/EDRSearchFilter';
import { i18n } from 'src/i18n';
import type { MitreTechnique } from '../../../threat.types';
import MultiselectFilter from './MultiselectFilter';

const OptionLabelMitreNameText = styled(Text, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: '$1',
});

const OptionItemLabel = ({ id, name }: { id: string; name: string }) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsTextTruncated(
        textRef.current.scrollWidth > textRef.current.clientWidth,
      );
    }
  }, [name]);

  return (
    <Flex flex={1} gap="xs" alignItems="center">
      <Chip label={id} size="compact" />
      <EDRTooltip side="right" label={isTextTruncated ? name : ''}>
        <OptionLabelMitreNameText ref={textRef}>
          {name}
        </OptionLabelMitreNameText>
      </EDRTooltip>
    </Flex>
  );
};

const FilterHeader = ({ onSearch }: { onSearch: (term: string) => void }) => {
  return (
    <Flex flow="column" px3 pb1 css={{ pt: 6 }}>
      <EDRSearchFilter
        name="technique_id"
        value=""
        onChange={(_, value) => onSearch(value as string)}
        onClear={() => onSearch('')}
        width="100%"
      />
    </Flex>
  );
};

type MitreFiltersProps = {
  values: string[] | undefined;
  techniques: MitreTechnique[];
  onChange: OnApply;
  onClear: OnClear;
  label?: string;
  isLoading?: boolean;
};

const MitreFilter = (props: MitreFiltersProps) => {
  const {
    values: newValues,
    onChange,
    onClear,
    techniques = [],
    isLoading,
  } = props;

  const options = useMemo(
    () =>
      techniques.map((path) => ({
        key: path.technique_id,
        value: path.technique_id,
        label: (
          <OptionItemLabel id={path.technique_id} name={path.technique_name} />
        ),
        buttonLabel: path.technique_id,
      })),
    [techniques],
  );

  const [searchInput, setSearchInput] = useState('');
  const [menuOptions, setMenuOptions] = useState(options);

  useEffect(() => {
    if (options.length === 0) return;

    const filteredOptions = options.filter(
      (option) =>
        option.buttonLabel
          .toLowerCase()
          .includes(searchInput.trim().toLowerCase()) ||
        option.label.props.name
          .toLowerCase()
          .includes(searchInput.trim().toLowerCase()),
    );

    setMenuOptions(filteredOptions);
  }, [searchInput, options]);

  const handleSearch = (search: string) => {
    setSearchInput(search);
  };

  return (
    <MultiselectFilter
      name="mitre"
      label={i18n.t('MITRE')}
      values={newValues}
      onChange={onChange}
      onClear={onClear}
      options={isLoading ? [] : menuOptions}
      buttonOptions={options}
      optionCss={{
        '& > div': {
          gap: 6,
          pl: 2,
          overflow: 'hidden',
        },
        '& > div > span': {
          overflow: 'hidden',
        },
        '& > div > span > span': {
          width: '100%',
        },
      }}
      menuCss={{ width: 345 }}
      header={!isLoading && <FilterHeader onSearch={handleSearch} />}
      isLoading={isLoading}
      onOpenChange={(open) => {
        if (!open) {
          setSearchInput('');
        }
      }}
    />
  );
};

export default MitreFilter;
