/* istanbul ignore file */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { subscriptionsApi } from 'src/app/api/base';

export const INVALID_ADDRESS_ERROR = 'Invalid address.';
export const INTERNAL_SERVER_ERROR = 'Invalid address.';

type SubtotalResponse = {
  subtotal: number;
  total: number;
  discount: number;
};

export type PricingQuery = {
  term: 'annual' | 'month';
  add_ons: {
    vuln: boolean;
    edr: boolean;
  };
  device_counts: {
    mac_devices: number;
    other_devices: number;
  };
};

type CheckoutResponse = {
  url: string;
};

export type CheckoutQuery = {
  tenant: {
    id: string;
    subdomain: string;
  };
  customer_details: {
    email: string;
    address: {
      country: string;
      line1: string;
      postal_code: string;
    };
  };
} & PricingQuery;

const getCheckoutSubtotal = async (
  pricingQuery: PricingQuery,
): Promise<SubtotalResponse | undefined> => {
  const response = await subscriptionsApi('calculate/totals').get(pricingQuery);

  if (response.status !== 200 || !response.data) {
    throw new Error('Internal server error');
  }

  return response.data;
};

export const useCheckoutPricing = (pricingQuery: PricingQuery) => {
  const {
    term,
    add_ons: { edr, vuln },
    device_counts: { mac_devices, other_devices },
  } = pricingQuery;

  return useQuery({
    queryKey: [term, edr, vuln, mac_devices, other_devices],
    queryFn: () => getCheckoutSubtotal(pricingQuery),
    placeholderData: keepPreviousData,
  });
};

const goToCheckout = async (
  checkoutQuery: CheckoutQuery,
): Promise<CheckoutResponse | undefined> => {
  return await subscriptionsApi('/stripe/session/checkout')
    .post(checkoutQuery)
    .then((res) => res.data)
    .catch((error) => {
      if (error.response.status === 400) {
        throw new Error(INVALID_ADDRESS_ERROR);
      }
      throw new Error(INTERNAL_SERVER_ERROR);
    });
};

export const useGoToCheckout = (checkoutQuery?: CheckoutQuery) => {
  return useQuery({
    queryKey: ['checkout-session', JSON.stringify(checkoutQuery)],
    queryFn: () => goToCheckout(checkoutQuery),
    enabled: !!checkoutQuery,
  });
};
