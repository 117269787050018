import { Flex } from '@kandji-inc/nectar-ui';
import { isEqual, isObject, transform } from 'lodash';
/* istanbul ignore file */
import React from 'react';
import type { Node } from 'reactflow';

import { displayLibraryCategory } from 'src/features/library-items/library/common';
import {
  lockEmptyLg,
  lockEmptySm,
  lockFullLg,
  lockFullSm,
  lockHalfFullLg,
  lockHalfFullSm,
} from './assets';

import type {
  Blueprint,
  DraggableLibraryItem,
  LibraryItem,
  LibraryItemFilterDevice,
  LibraryItemFilterSort,
} from './blueprint-flow.types';

import { i18n } from 'i18n';
import featureFlags from 'src/config/feature-flags';
import {
  apiTypes,
  getItemCategory,
  installTypes,
  updateOnlyIconName,
} from '../library-items/library/common';
import allLibraryItemsConfiguration from '../library-items/library/library-item-configurations';
import {
  ASSIGNMENT_TYPES,
  deviceFamilySelectOptionToRunsOn,
  type deviceKinds,
} from './constants';

/**
 * @returns The true height of an element, taking into account margins and
 * padding.
 */
export const calculateHeight = (el: HTMLElement) => {
  if (el) {
    const style = getComputedStyle(el);
    const marginTop = parseInt(style.marginTop, 10);
    const marginBottom = parseInt(style.marginBottom, 10);
    const height = el.offsetHeight + marginTop + marginBottom;
    return height;
  }
  return 0;
};

export const getItemConfig = (item) =>
  Object.values(allLibraryItemsConfiguration).find((d) => {
    if (d.type === apiTypes.PROFILE) {
      return d.type === item.type && d.identifier === item.identifier;
    }
    return d.type === item?.type;
  });

export const getLibraryItem = (
  itemId: string,
  libraryItems: Array<LibraryItem>,
  extras = {},
) => {
  const li = libraryItems.find(({ id }) => id === itemId);
  if (!li) {
    return li;
  }

  return {
    ...li,
    ...extras,
  };
};

/**
 * @returns An object denoting the given devices as an object of runs_on data.
 */
export const devicesToRunsOn = (devices: Array<LibraryItemFilterDevice>) => {
  const runsOn = {
    runs_on_mac: false,
    runs_on_iphone: false,
    runs_on_ipad: false,
    runs_on_tv: false,
    runs_on_vision: false,
  };

  if (!devices.length) {
    return {};
  }

  Object.keys(runsOn).forEach((kind) => {
    const runsOnDevice = kind.split('_').at(-1) as LibraryItemFilterDevice;
    if (devices.includes(runsOnDevice)) {
      runsOn[kind] = true;
    }
  });

  return runsOn;
};

const sortItems = (
  a: LibraryItem,
  b: LibraryItem,
  key: 'name' | 'type',
  order: 'az' | 'za',
  secondaryKey: 'name' | 'type',
) => {
  const primaryA = key === 'name' ? a[key] : getItemConfig(a).name;
  const primaryB = key === 'name' ? b[key] : getItemConfig(b).name;

  const secondaryA =
    secondaryKey === 'name' ? a[secondaryKey] : getItemConfig(a).name;
  const secondaryB =
    secondaryKey === 'name' ? b[secondaryKey] : getItemConfig(b).name;

  const primaryComparison =
    order === 'az'
      ? primaryA.localeCompare(primaryB)
      : primaryB.localeCompare(primaryA);
  if (primaryComparison === 0) {
    return order === 'az'
      ? secondaryA.localeCompare(secondaryB)
      : secondaryB.localeCompare(secondaryA);
  }
  return primaryComparison;
};

export const sortLibraryItems = (
  by: LibraryItemFilterSort,
  items: Array<LibraryItem>,
) => {
  switch (by) {
    case 'li_name_az':
      return items.sort((a, b) => sortItems(a, b, 'name', 'az', 'type'));
    case 'li_name_za':
      return items.sort((a, b) => sortItems(a, b, 'name', 'za', 'type'));
    case 'li_type_az':
      return items.sort((a, b) => sortItems(a, b, 'type', 'az', 'name'));
    case 'li_type_za':
      return items.sort((a, b) => sortItems(a, b, 'type', 'za', 'name'));
    case 'newest':
      return items.sort((a, b) => {
        const dateComparison =
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        if (dateComparison === 0) {
          return a.name.localeCompare(b.name);
        }
        return dateComparison;
      });
    case 'oldest':
      return items.sort((a, b) => {
        const dateComparison =
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        if (dateComparison === 0) {
          return a.name.localeCompare(b.name);
        }
        return dateComparison;
      });
    case 'recently_updated':
      return items.sort((a, b) => {
        const dateComparison =
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
        if (dateComparison === 0) {
          return a.name.localeCompare(b.name);
        }
        return dateComparison;
      });
    default:
      return items;
  }
};

export const getDeleteCopy = (
  kind: 'conditional' | 'assignment',
  childCount: number,
  assignmentType?: string,
) => {
  if (kind === 'conditional') {
    switch (childCount) {
      case 0:
        return i18n.t(
          'Deleting this Conditional Block will delete all its rules and Library Item assignments. Are you sure you want to delete it?',
        );
      default:
        return i18n.ut(
          `Deleting this Conditional Block will also delete its {childCount, plural, one {# child} other {# children}}, including all Library Item assignments contained within {pluralizeThem}. Are you sure you want to delete it?`,
          {
            childCount: childCount,
            pluralizeThem: pluralizeString(childCount, 'it', 'them'),
          },
        );
    }
  }

  const rulesPresent =
    assignmentType !== ASSIGNMENT_TYPES.else ? i18n.t('rules and') : '';

  if (kind === 'assignment') {
    switch (childCount) {
      case 0:
        return i18n.ut(
          'Deleting this Assignment Node will delete all its {rulesPresent} Library Item assignments. Are you sure you want to delete it?',
          { rulesPresent },
        );
      default:
        return i18n.ut(
          `Deleting this Assignment Node will also delete its {childCount, plural, one {# child} other {# children}}, including all Library Item assignments contained within {pluralizeThem}. Are you sure you want to delete it?`,
          {
            childCount,
            pluralizeThem: pluralizeString(childCount, 'it', 'them'),
          },
        );
    }
  }

  return '';
};

/**
 * Gets the Assignment type of the given Assignment node by determining its placement amongst its Assignment node peers
 * @param nodes a list of all nodes in the graph
 * @param node the node to find the Assignment type (if | elseif | else) of
 * @returns an object with the assignment type of the node and a list of its peers
 */
export const getAssignmentTypeAndPeers = (nodes: Node[], node: Node) => {
  const { parentNode } = node;
  const peers = nodes.filter((n) => n.parentNode === parentNode);
  const index = peers.findIndex((n) => n.id === node.id);
  let assignmentType = ASSIGNMENT_TYPES.elseif;

  if (index === 0) {
    assignmentType = ASSIGNMENT_TYPES.if;
  }

  if (index === peers.length - 1) {
    assignmentType = ASSIGNMENT_TYPES.else;
  }

  return { assignmentType, peers };
};

/**
 * Given a library item, find its occurrence in the the given set of nodes'
 * assignments.
 * @returns The count of the given library item in the list of nodes.
 */
export const countLibraryItemInNodes = (
  nodes: Blueprint['nodes'],
  libraryItemId: string,
): number =>
  nodes?.reduce((a, c) => {
    const items = c.data?.items || [];

    return a + items.filter((item) => item.data.id === libraryItemId).length;
  }, 0);

export const differenceInNodes = (
  listA: Array<object>,
  listB: Array<object>,
) => {
  const changes = (object, base) =>
    transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });

  return changes(listA, listB) as [];
};

export const getDeviceRunsOnCamelCase = (
  device: (typeof deviceKinds)[number],
) => `runsOn${device.charAt(0).toUpperCase() + device.slice(1)}`;

export const numberToCommaString = (n: number) =>
  n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const pluralizeWord = (
  word: string,
  count: number,
  suffix: string = i18n.t('s'),
) => `${word}${count === 1 ? '' : suffix}`;

export const escapeSpecialCharacters = (text: string) =>
  text?.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');

export const doesTextHaveMatch = (text: string, term: string) => {
  if (!text) {
    return false;
  }

  const parts = text.split(
    new RegExp(`(${escapeSpecialCharacters(term)})`, 'gi'),
  );

  return parts.some((part) => part.toLowerCase() === term?.toLowerCase());
};

export const highlightedText = (
  text: string,
  term: string,
  color = 'var(--colors-yellow30)',
  boldOptions?: {
    color: string;
    matchIndex: number;
  },
) => {
  if (term) {
    const parts = text?.split(
      new RegExp(`(${escapeSpecialCharacters(term)})`, 'gi'),
    );

    let matchCount = 0;
    return (
      <span>
        {parts?.map((part, i) => {
          const isMatchingSearchText =
            part.toLowerCase() === term?.toLowerCase();

          const isBold =
            isMatchingSearchText && boldOptions?.matchIndex === matchCount;
          const highlightColor = isBold ? boldOptions.color : color;

          if (isMatchingSearchText) {
            matchCount += 1;
          }

          return (
            <span
              key={i}
              style={{
                background: isMatchingSearchText ? highlightColor : '',
              }}
            >
              {part}
            </span>
          );
        })}
      </span>
    );
  }

  return (
    <span>
      <span>{text}</span>
    </span>
  );
};

export const getLibraryItemTypesForFilter = (libraryItems: LibraryItem[]) => {
  // Create a mapping of category name to a list of Library Items in that category
  // Ex. { "Enrollment Configurations": ["Automated Device Enrollment", "Liftoff", "Passport]}
  const LICategoryMap = {};
  libraryItems.forEach((item) => {
    const humanReadableName =
      getItemConfig(item)?.getName() ||
      getItemConfig(item)?.name ||
      item?.name ||
      '';
    const category = getItemCategory(item.type);

    // If the map already has the given category but does not have the specific LI, add it to the existing list.
    // Otherwise, create a new category and list with the specific LI in it.
    if (
      LICategoryMap[category] &&
      !LICategoryMap[category].includes(humanReadableName)
    ) {
      LICategoryMap[category].push(humanReadableName);
    } else if (!LICategoryMap[category]) {
      LICategoryMap[category] = [humanReadableName];
    }
  });

  let numTypeOptions = 0;
  // Sort the LI type categories and the LI types within those categories
  const typeOptions = Object.keys(LICategoryMap)
    .sort()
    .map((category) => {
      const categoryOptions = LICategoryMap[category]
        .sort()
        .map((type: string) => ({
          label: type,
          value: type,
        }));

      numTypeOptions += categoryOptions.length;

      return {
        section: displayLibraryCategory(category),
        showSectionLabel: true,
        options: categoryOptions,
      };
    });

  return typeOptions;
};

export const pluralizeString = (count: number, singularWord, pluralWord) => {
  if (count === 1) {
    return singularWord;
  }
  return pluralWord;
};

export const getRatingLocks = (items: number, size: 'sm' | 'lg' = 'sm') => {
  if (items <= 20) {
    return Array.from({ length: 4 }).map(() =>
      size === 'sm' ? lockEmptySm : lockEmptyLg,
    );
  }

  const isFraction = items % 10 !== 0;
  const rating = Math.floor((items - 20) / 10) + (isFraction ? 0.5 : 0);
  const fullLocks = Array.from({ length: rating });
  const halfLocks = isFraction ? [1] : [];
  const emptyLocks = Array.from({
    length: 4 - fullLocks.length - halfLocks.length,
  });

  const ratingLocks = [
    ...fullLocks.map(() => (size === 'sm' ? lockFullSm : lockFullLg)),
    ...halfLocks.map(() => (size === 'sm' ? lockHalfFullSm : lockHalfFullLg)),
    ...emptyLocks.map(() => (size === 'sm' ? lockEmptySm : lockEmptyLg)),
  ];

  return <Flex gap="xs">{ratingLocks.map((svg) => svg)}</Flex>;
};

export const getSecurityRatingDescription = (items: number) => {
  if (items <= 30) {
    return i18n.t('Least restrictive');
  }
  if (items <= 40) {
    return i18n.t('Minimally restrictive');
  }
  if (items <= 50) {
    return i18n.t('Moderately restrictive');
  }

  return i18n.t('Most restrictive');
};

// A short term, non-dynamic solution to show the SCLI icon when there is at least one conflicting item in the map
export const HOTFIX__hasConflictingItemInMap = /* istanbul ignore next */ (
  item: LibraryItem,
  nodes: Blueprint['nodes'],
  libraryItems: LibraryItem[],
) => {
  const itemConfig = getItemConfig(item);

  // There are no conflicting items if more than one is allowed per device
  if (
    !itemConfig ||
    (!itemConfig.singleBlueprintAllowed &&
      !itemConfig.singleAssignmentMappingAllowed &&
      !(
        itemConfig.type == apiTypes.APP_BLOCKING &&
        !featureFlags.getFlag('dc-1451-send-app-blocking-param', false)
      ))
  ) {
    return false;
  }

  return nodes?.some((node) => {
    const items = node.data?.items || [];

    return items.some((otherItem) => {
      const otherId = otherItem.data.id;
      const otherLibraryItem = getLibraryItem(otherId, libraryItems);

      if (!otherLibraryItem) {
        return false;
      }

      const otherConfig = getItemConfig(otherLibraryItem);

      // We are looking at the current item, therefore it is not conflicting
      if (item.id === otherId) {
        return false;
      }

      // For Profiles, compare identifier
      if (itemConfig.type === apiTypes.PROFILE) {
        return otherConfig?.identifier === itemConfig.identifier;
      }

      // For Auto Apps, compare Managed Library Item ID
      if (itemConfig.type === apiTypes.AUTO_APP) {
        return (
          item.managedLibraryItemId === otherLibraryItem.managedLibraryItemId
        );
      }

      if (
        itemConfig.type === apiTypes.IPA_APP_V2 &&
        otherConfig?.type === apiTypes.IPA_APP_V2
      ) {
        return otherLibraryItem?.identifier === item.identifier;
      }

      // For all other Library Items, compare type
      return otherConfig?.type === itemConfig.type;
    });
  });
};

export const getInstallIconsConfig = (item: LibraryItem) => {
  return [
    {
      label: i18n.t('Self Service'),
      icon: 'kandji-logo',
      isVisible: item.isSelfService,
    },
    {
      label: i18n.t('Update Only'),
      icon: updateOnlyIconName,
      isVisible:
        item.installEnforcement === installTypes.CONTINUOUS &&
        item.isUpdateOnly,
    },
    {
      label: i18n.t('Continuously enforced'),
      icon: 'infinity',
      isVisible:
        item.installEnforcement === installTypes.CONTINUOUS &&
        !item.isUpdateOnly,
    },
    {
      label: i18n.t('Install once'),
      icon: 'install-once-16px',
      isVisible: item.installEnforcement === installTypes.ONCE,
    },
  ].sort((a, b) => {
    if (a.isVisible === b.isVisible) {
      return 0;
    }

    return a.isVisible ? -1 : 1;
  });
};

export const isItemInDevicesFilter = (
  item: LibraryItem,
  devices: Array<LibraryItemFilterDevice>,
) => devices.some((device) => item[deviceFamilySelectOptionToRunsOn[device]]);

export const isItemInTypeFilter = (item: LibraryItem, types: Array<string>) =>
  types.includes(getItemConfig(item).getName());
