import { Flex } from '@kandji-inc/nectar-ui';
import useAddBodyClass from 'src/features/util/hooks/use-add-body-class';
import useWhiteBg from 'src/features/util/hooks/use-white-bg';

import BackgroundImage from './assets/background.jpg';
import { HeroSection } from './sections/hero-section';
import { IdentifyAssessResolveSection } from './sections/identify-assess-resolve-section';
import { WhyKandjiSection } from './sections/why-kandji-section';

const TrialPage = () => {
  useAddBodyClass('vuln-trial');
  useWhiteBg();

  return (
    <Flex
      justifyContent="center"
      flow="column"
      css={{
        padding: '$8',
        margin: '0 calc(var(--k-main-layout-side-padding) * -1) -48px',
        gap: 100,

        backgroundImage: `url(${BackgroundImage})`,
        backgroundPositionX: 'calc(50% + 146px)',
        backgroundPositionY: 'top',
        backgroundSize: '1548px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <HeroSection />

      <IdentifyAssessResolveSection />

      <WhyKandjiSection />
    </Flex>
  );
};

export { TrialPage };
