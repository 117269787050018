import {
  Button,
  Dialog,
  Flex,
  Heading,
  Text,
  Toaster_UNSTABLE as Toaster,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import isEqual from 'lodash/isEqual';
import React, { useContext, useEffect, useState } from 'react';

import { InterfaceContext } from 'src/contexts/interface';
import TagsDropdown from 'src/features/tags/tags-dropdown';
import { i18n } from 'src/i18n';

const Modal = (props: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  computer: any;
  updateComputerFields: any;
}) => {
  const { isOpen, setIsOpen, computer, updateComputerFields } = props;
  const { id } = computer;

  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;
  const { sidebarDocked } = useContext(InterfaceContext);

  const [isWorking, setIsWorking] = useState(false);
  const [initialTags, setInitialTags] = useState(null);
  const [tags, setTags] = useState(initialTags || []);
  const { toast } = useToast();

  const isSaveDisabled = isWorking || isEqual(initialTags, tags);

  const displayToast = (title, variant) =>
    toast({
      title,
      variant,
      style: {
        left: /* istanbul ignore next */ sidebarDocked
          ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
          : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
        bottom: '12px',
        position: 'absolute',
      },
    });

  const onClose = () => {
    setIsOpen(false);
  };

  const onCancel = () => {
    onClose();
  };

  const onSave = async () => {
    try {
      setIsWorking(true);
      await updateComputerFields(id, { tags });
      setIsWorking(false);
      displayToast(i18n.t('Device tags updated.'), 'success');
      onClose();
    } catch (error) {
      setIsWorking(false);
      displayToast(i18n.t('There was an error updating device tags.'), 'error');
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setInitialTags(computer.tags);
      setTags(computer.tags);
    }
  }, [isOpen]);

  const title = <Heading size="3">{i18n.t('Assign tags')}</Heading>;

  const content = (
    <Flex flow="column" gap="lg" css={{ width: '512px' }}>
      <Text size="1">
        {i18n.t('Editing tags may result in changes to scoping.')}
      </Text>
      <TagsDropdown onTagsSelect={setTags} tags={tags} />
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end">
      <Button variant="subtle" onClick={onCancel}>
        {i18n.t('Cancel')}
      </Button>
      <Button
        variant="primary"
        loading={isWorking}
        disabled={isSaveDisabled}
        onClick={onSave}
      >
        {i18n.t('Save')}
      </Button>
    </Flex>
  );

  return (
    <>
      <Dialog
        isOpen={isOpen}
        closeOnEscape
        onOpenChange={onClose}
        title={title}
        content={content}
        footer={footer}
      />
      <Toaster />
    </>
  );
};

export default Modal;
