import type { HeaderContext } from '@tanstack/react-table';
import { i18n } from 'i18n';
import type {
  BehavioralThreatClassification,
  BehavioralThreatStatus,
  ThreatClassification,
  ThreatDetail,
  ThreatStatus,
} from '../../../threat.types';
import {
  ActionsCell,
  AssociatedItemCell,
  ClassificationCell,
  StatusCell,
  ThreatIdCell,
} from '../components/Cells';
import HeaderWithTooltip from '../components/HeaderWithTooltip';
import type { CellProps, ThreatItem } from '../types';
import getRowActionOptions from '../utils/get-row-action-options';

// Cell rendering functions with safety checks
const createCellRenderers = (
  isFileDetectionType: boolean,
  query: string | undefined,
  onGetReleaseDetails: (threat: ThreatDetail) => void,
  onRecheckStatus: (threat: ThreatDetail) => void,
  isThreatBeingChecked: boolean,
) => {
  const renderThreatIdHeader = (info: HeaderContext<ThreatItem, string>) => {
    const text = info.column.columnDef.meta?.displayName;
    // istanbul ignore next
    if (!text) return null;

    const tooltip = isFileDetectionType
      ? i18n.t('SHA-256 hash of the detected file')
      : i18n.t('Behavioral detection rule name');

    return (
      <HeaderWithTooltip text={text} tooltip={tooltip} tooltipWidth={150} />
    );
  };

  const renderThreatIdCell = (info: CellProps<string>) => {
    const item = info.row.original;

    return (
      <ThreatIdCell
        item={item}
        isFileDetectionType={isFileDetectionType}
        query={query}
      />
    );
  };

  const renderAssociatedItemHeader = (
    info: HeaderContext<ThreatItem, string>,
  ) => {
    const text = info.column.columnDef.meta?.displayName;
    // istanbul ignore next
    if (!text) return null;

    const tooltip = isFileDetectionType
      ? i18n.t('The most recent file name')
      : i18n.t('The most recent parent process name');

    return <HeaderWithTooltip text={text} tooltip={tooltip} />;
  };

  const renderAssociatedItemCell = (info: CellProps<string>) => {
    const value = info.getValue();
    if (value === undefined || value === null) return '-';

    return <AssociatedItemCell value={value} query={query} />;
  };

  const renderClassificationCell = (
    info: CellProps<ThreatClassification | BehavioralThreatClassification>,
  ) => {
    const classification = info.getValue();
    if (!classification) return '-';

    return <ClassificationCell classification={classification} />;
  };

  const renderStatusCell = (
    info: CellProps<ThreatStatus | BehavioralThreatStatus>,
  ) => {
    const status = info.getValue();
    if (!status) return '-';

    return <StatusCell status={status} />;
  };

  const renderDetectionDateCell = (info: CellProps<string>) => {
    const date = info.getValue();
    if (!date) return '-';

    return i18n.format.date(date);
  };

  const renderActionsCell = (info: CellProps<undefined>) => {
    const item = info?.row?.original;

    const actionOptions = getRowActionOptions(
      item as ThreatDetail,
      onGetReleaseDetails,
      onRecheckStatus,
      isThreatBeingChecked,
    );

    return <ActionsCell actionOptions={actionOptions} />;
  };

  return {
    renderThreatIdHeader,
    renderThreatIdCell,
    renderAssociatedItemCell,
    renderAssociatedItemHeader,
    renderClassificationCell,
    renderStatusCell,
    renderDetectionDateCell,
    renderActionsCell,
  };
};

export default createCellRenderers;
