import {
  Button,
  DateRangePicker,
  DropdownMenuPrimitives,
  FilterButton,
  Flex,
  Icon,
} from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useState } from 'react';
import { i18n } from 'src/i18n';

const DateFilter = (props) => {
  const { onChange, defaultFilter, period } = props;

  const dateEnumOptions = () => [
    {
      label: i18n.t('All time'),
      value: 'ALL',
    },
    {
      label: i18n.t('Last 24 hours'),
      value: '1',
    },
    {
      label: i18n.t('Last 48 hours'),
      value: '2',
    },
    {
      label: i18n.t('Last 7 days'),
      value: '7',
    },
    {
      label: i18n.t('Last 30 days'),
      value: '30',
    },
    {
      label: i18n.t('Custom date range'),
      value: 'custom_date_range',
    },
  ];

  const dateEnumOptionsByValue = dateEnumOptions().reduce((acc, opt) => {
    acc[opt.value] = opt.label;
    return acc;
  }, {});

  const [isOpen, setIsOpen] = useState(false);
  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [toDate, setToDate] = useState<Date | null>(new Date());

  const handleApply = () => {
    onChange({
      ...period,
      dateRange: {
        from: fromDate,
        to: toDate,
      },
    });
    setIsOpen(false);
  };

  const isFilterActive =
    JSON.stringify(period) !== JSON.stringify(defaultFilter);

  const buttonLabel =
    period.enumValue === 'custom_date_range' &&
    period.dateRange.from &&
    period.dateRange.to
      ? `${formatDateRangeLabel(fromDate)} - ${
          toDate && formatDateRangeLabel(toDate)
        }`
      : dateEnumOptionsByValue[period.enumValue];

  const handleFromChange = (newFromDate) => {
    setFromDate(newFromDate[0]);
  };

  const handleToChange = (newToDate) => {
    setToDate(newToDate[0]);
  };

  return (
    <DropdownMenuPrimitives.Root
      open={isOpen}
      onOpenChange={() => setIsOpen(!isOpen)}
    >
      <DropdownMenuPrimitives.Trigger asChild on>
        <FilterButton
          filtersSelected={isFilterActive}
          showRemove={false}
          aria-label="date"
          onClick={() => setIsOpen(!isOpen)}
        >
          {buttonLabel}
        </FilterButton>
      </DropdownMenuPrimitives.Trigger>
      <DropdownMenuPrimitives.Content
        onInteractOutside={() => {
          if (period.enumValue === 'custom_date_range') {
            // Reset when date range hasn't been applied:
            if (!period.dateRange.from && !period.dateRange.to) {
              onChange(defaultFilter);
              return;
            }

            if (toDate !== period.dateRange.to) {
              setToDate(period.dateRange.to);
            }

            if (fromDate !== period.dateRange.from) {
              setFromDate(period.dateRange.from);
            }
          }
        }}
        align="start"
        css={{
          width: '300px',
          zIndex: 3,
        }}
      >
        {dateEnumOptions().map((option) => (
          <DropdownMenuPrimitives.CheckboxItem
            checked={period.enumValue === option.value}
            onClick={(e) => {
              if (option.value === 'custom_date_range') {
                onChange({ ...period, enumValue: option.value });
                e.preventDefault();
              } else {
                onChange({ ...period, enumValue: option.value });
              }
            }}
            css={{
              cursor: 'pointer',
            }}
          >
            {option.label}
            <DropdownMenuPrimitives.ItemIndicator
              css={{
                '& > svg': {
                  height: '14px',
                  width: '14px',
                },
              }}
            >
              <Icon name="check" />
            </DropdownMenuPrimitives.ItemIndicator>
          </DropdownMenuPrimitives.CheckboxItem>
        ))}

        {period.enumValue === 'custom_date_range' && (
          <div>
            <DateRangePicker
              data-testid="date-range-picker"
              containerCss={{
                width: '100%',
                gap: '$2',
                margin: '$2 0',
                justifyContent: 'center',
              }}
              from={{
                value: [fromDate],
                onChange: handleFromChange,
                onClear: () =>
                  onChange({
                    ...period,
                    dateRange: { from: null, to: [period.dateRange.to] },
                  }),
              }}
              to={{
                value: [toDate],
                onChange: handleToChange,
                onClear: () =>
                  onChange({
                    ...period,
                    dateRange: { from: [period.dateRange.from], to: null },
                  }),
              }}
            />
            <Flex gap="sm" justifyContent="end" p2>
              <Button
                compact
                variant="default"
                onClick={() => {
                  onChange(defaultFilter);
                  setIsOpen(false);
                }}
              >
                {i18n.t('Cancel')}
              </Button>
              <Button
                compact
                variant="primary"
                onClick={handleApply}
                disabled={!(fromDate || toDate)}
              >
                {i18n.t('Apply')}
              </Button>
            </Flex>
          </div>
        )}
      </DropdownMenuPrimitives.Content>
    </DropdownMenuPrimitives.Root>
  );
};

export default DateFilter;

function formatDateRangeLabel(date: Date) {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}
