/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import { transformDetectionDateFilterToApi } from '../../helpers';
import type {
  AllVulnerabilitiesFilter,
  Software,
} from '../../vulnerability.types';

const useGetTopSoftwareWithVulnerabilities = (
  limit: number,
  timespan: AllVulnerabilitiesFilter['latestDetected'] = null,
  severity: AllVulnerabilitiesFilter['severity'] = null,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: [
      'top-software-with-vulnerabilities',
      limit,
      timespan,
      severity,
      ...(keys || []),
    ],
    queryFn: () =>
      vulnerabilityApi('v2/dashboards/top_software_with_vulnerabilities').get({
        ...params,
        limit,
        filter: {
          severity: { in: severity.length ? severity : null },
          timespan: transformDetectionDateFilterToApi(timespan),
        },
      }),
  });

  const data = apiRes?.data || [];
  const software = data.map(
    (s: {
      name: Software['name'];
      vuln_count: number;
      icon_key: Software['icon_key'];
    }) => ({
      name: s.name,
      vulnCount: s.vuln_count,
      iconKey: s.icon_key,
    }),
  );

  return { software, isLoading };
};

export { useGetTopSoftwareWithVulnerabilities };
