import {
  Button,
  ButtonSelect,
  DropdownMenuPrimitives as DropdownMenu,
  Flex,
  Heading,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';
import { ViewNameInput } from './ViewNameInput';

type PrismViewSaveDropdownProps = {
  isOpen?: boolean;
  viewName: string;
  viewNameError?: string;
  setViewNameError: (viewNameError: string) => void;
  handleViewCreate: (newName) => void;
  handleViewUpdate: () => void;
};

const StyledP = styled('p', {
  color: '$neutral70',
  fontWeight: '$regular',
  fontSize: '$1',
  marginBottom: '0',
});

const PrismViewSaveDropdown = ({
  isOpen,
  viewName,
  viewNameError,
  handleViewCreate,
  handleViewUpdate,
  setViewNameError,
}: PrismViewSaveDropdownProps) => {
  const [open, setOpen] = React.useState(!!isOpen);
  const [newName, setNewName] = React.useState(viewName);
  const [saveMode, setSaveMode] = React.useState<'update' | 'create'>('update');

  const description = React.useMemo(
    () =>
      saveMode === 'update'
        ? i18n.t(
            'Selecting this option will save any modifications you made and update your existing saved view.',
          )
        : i18n.t(
            'Provide a name and create a new saved view. It will include your filters, column order and sort selections.',
          ),
    [saveMode],
  );

  return (
    <DropdownMenu.Root open={open} modal={false}>
      <DropdownMenu.Trigger asChild>
        <Button
          data-testid="save-view-trigger"
          variant="default"
          compact
          onClick={() => setOpen(!open)}
        >
          {i18n.t('Save as')}
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          onCloseAutoFocus={(e) => {
            e.preventDefault();
          }}
          onInteractOutside={() => {
            setNewName('');
            setViewNameError('');
            setOpen(false);
          }}
          css={{
            width: '356px',
            marginTop: '4px',
            marginRight: '48px',
            zIndex: 991,
          }}
        >
          <Flex flow="column" gap="md" css={{ p: '16px 24px 0 24px' }}>
            <Heading size="4" css={{ fontWeight: '$medium' }}>
              {i18n.t('Save view options')}
            </Heading>
            <ButtonSelect.Root
              type="single"
              value={saveMode}
              onValueChange={(option) => {
                if (option === 'update') {
                  setSaveMode('update');
                } else {
                  setSaveMode('create');
                }
              }}
            >
              <ButtonSelect.Item
                css={{ width: '50%' }}
                value="update"
                testId="saved-prism-view-update-button"
              >
                {i18n.t('Save to this view')}
              </ButtonSelect.Item>
              <ButtonSelect.Item
                css={{ width: '50%' }}
                value="create"
                testId="saved-prism-view-create-button"
              >
                {i18n.t('Create new view')}
              </ButtonSelect.Item>
            </ButtonSelect.Root>
            <StyledP>{description}</StyledP>
            {saveMode === 'create' && (
              <ViewNameInput
                viewName={newName}
                viewNameError={viewNameError}
                placeholder={i18n.t('Enter a unique name')}
                setViewName={setNewName}
                setViewNameError={setViewNameError}
              />
            )}
          </Flex>
          <Flex
            gap="sm"
            justifyContent="end"
            alignItems="center"
            css={{ p: '16px 12px' }}
          >
            <Button
              testId="saved-prism-view-cancel-button"
              compact
              onClick={() => {
                setSaveMode('update');
                setNewName('');
                setViewNameError('');
                setOpen(false);
              }}
            >
              {i18n.t('Cancel')}
            </Button>
            <Button
              testId="saved-prism-view-save-button"
              compact
              onClick={() => {
                if (saveMode === 'create') {
                  handleViewCreate(newName);
                } else {
                  handleViewUpdate();
                }
                setOpen(false);
                setNewName('');
                setViewNameError('');
              }}
              variant="primary"
            >
              {i18n.t('Save')}
            </Button>
          </Flex>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default PrismViewSaveDropdown;
