import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import { Flex, Icon, Tbody, Td, Tr } from '@kandji-inc/nectar-ui';
import { Fragment } from 'react';
import { i18n } from 'src/i18n';
import { constants } from '../common';
import ThreatListHighlightedText from '../common/components/ThreatListHighlightedText';
import ThreatListStatusBadge from '../common/components/ThreatListStatusBadge';
import TableActionsDropdown from '../common/components/ThreatListTable/TableActionsDropdown';
import ThreatListTextCopyButton from '../common/components/ThreatListTextCopyButton';
import {
  getClassificationLabel,
  getStatusColor,
  getStatusIcon,
  getStatusLabel,
} from '../common/utils/constants-getters';
import type {
  BehavioralThreatClassification,
  BehavioralThreatDetail,
  BehavioralThreatStatus,
  ThreatClassification,
  ThreatDetail,
  ThreatStatus,
} from '../threat.types';

const getClassifactionIcon = (
  classification: ThreatClassification | BehavioralThreatClassification,
) => constants.THREAT_CLASSIFICATION_ICON_MAP[classification];

type RowActionOptions = Partial<{
  [key in ThreatStatus | BehavioralThreatStatus]: DropdownMenuOption;
}>;

type ThreatListTableBodyProps = {
  threats: ThreatDetail[] | BehavioralThreatDetail[];
  onGetReleaseDetails: (threat: ThreatDetail) => void;
  query: string | undefined;
  onOpenSplitView: (
    threat: ThreatDetail | BehavioralThreatDetail,
    isFileDetectionType: boolean,
  ) => void;
  isMoreActionsShown?: boolean;
  onRecheckStatus: (threat: ThreatDetail) => void;
  isThreatBeingChecked: boolean;
  selectedThreatForSplitView: ThreatDetail | BehavioralThreatDetail | undefined;
  isFileDetectionType: boolean;
};

const ThreatListTableBody = (props: ThreatListTableBodyProps) => {
  const {
    threats,
    onGetReleaseDetails,
    query,
    onOpenSplitView,
    isMoreActionsShown,
    onRecheckStatus,
    isThreatBeingChecked,
    selectedThreatForSplitView,
    isFileDetectionType,
  } = props;
  const getRowActionOptions = (item: ThreatDetail): DropdownMenuOption[] => {
    const options: RowActionOptions = {
      [constants.THREAT_STATUS.QUARANTINED]: {
        label: i18n.t('Release threat'),
        icon: 'square-arrow-up',
        onClick: () => onGetReleaseDetails(item),
      },
      [constants.THREAT_STATUS.NOT_QUARANTINED]: {
        label: i18n.t('Recheck status'),
        icon: 'arrows-rotate',
        onClick: /* istanbul ignore next */ () =>
          isThreatBeingChecked ? null : onRecheckStatus(item),
        disabled: isThreatBeingChecked,
      },
    };

    const virusTotalOption = {
      label: i18n.t('Search VirusTotal'),
      icon: 'virus-total',
      onClick: /* istanbul ignore next -- Linking */ () =>
        window.open(`${constants.VIRUS_TOTAL_URL}${item.file_hash}`),
    };

    if (!(item.status in options)) {
      return [virusTotalOption];
    }

    return [options[item.status], virusTotalOption];
  };

  const handleOpenSplitView = (item: ThreatDetail) => {
    onOpenSplitView(item, isFileDetectionType);
  };

  return (
    <Tbody>
      {threats.map((item) => {
        const id = `${item.file_hash}${item.file_path}${item.device_id}${item.detection_date}`;
        const selectedId = `${selectedThreatForSplitView?.file_hash}${selectedThreatForSplitView?.file_path}${selectedThreatForSplitView?.device_id}${selectedThreatForSplitView?.detection_date}`;
        const isSplitViewSelected = selectedId === id;
        const actionOptions = getRowActionOptions(item);
        const statusEnumValue = getStatusLabel(item.status);

        const TrCss = {
          cursor: 'pointer',
        };

        return (
          <Fragment key={id}>
            <Tr
              css={TrCss}
              hoverAnchorUnderline={false}
              selected={isSplitViewSelected}
              onClick={() => handleOpenSplitView(item)}
            >
              <Td title="">
                <Flex
                  gap="xs"
                  alignItems="center"
                  css={{ '& svg': { flexShrink: 0 } }}
                >
                  {isFileDetectionType ? (
                    <>
                      <Icon name="file" size="sm" />
                      <ThreatListTextCopyButton
                        text={item.file_hash}
                        highlighted={query}
                        css={{ pr: '$3' }}
                      />
                    </>
                  ) : (
                    <>
                      <Icon name="gear" size="sm" />
                      <ThreatListHighlightedText search={query}>
                        {item.file_hash}
                      </ThreatListHighlightedText>
                    </>
                  )}
                </Flex>
              </Td>
              <Td title={item.associated_item}>
                <ThreatListHighlightedText search={query}>
                  {item.associated_item}
                </ThreatListHighlightedText>
              </Td>
              <Td title={getClassificationLabel(item.classification)}>
                <Flex
                  gap="xs"
                  alignItems="center"
                  css={{ '& svg': { flexShrink: 0 } }}
                >
                  <Icon
                    name={getClassifactionIcon(item.classification)}
                    size="sm"
                  />
                  {getClassificationLabel(item.classification, true)}
                </Flex>
              </Td>
              <Td title={i18n.format.datetime(item.detection_date)}>
                {i18n.format.date(item.detection_date)}
              </Td>
              <Td title={statusEnumValue}>
                <ThreatListStatusBadge
                  color={getStatusColor(item.status)}
                  icon={getStatusIcon(item.status)}
                >
                  {getStatusLabel(item.status)}
                </ThreatListStatusBadge>
              </Td>
              {isMoreActionsShown && (
                <Td title={i18n.t('Actions')}>
                  {Boolean(actionOptions.length) && (
                    <TableActionsDropdown
                      options={actionOptions}
                      testId="action-ellipsis"
                    />
                  )}
                </Td>
              )}
            </Tr>
          </Fragment>
        );
      })}
    </Tbody>
  );
};

export default ThreatListTableBody;
