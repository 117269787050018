import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Flex,
  Heading,
  Icon,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

import { useState } from 'react';
import AssessTheirSeverityImage from '../assets/assess-their-severity.png';
import SeeTheVulnerabilitiesImages from '../assets/see-the-vulnerabilities.png';
import TakeImmediateActionImage from '../assets/take-immediate-action.png';

const AccordionItemStyled = styled(AccordionItem, {
  borderBottom: '1px solid $neutral20',
  '&:last-child': {
    borderBottom: 'none',
  },
});

const AccordionContentStyled = styled(AccordionContent, {
  pr: '$5',
  pb: '$2',
  color: '$neutral90',
});

const AccordionTriggerStyled = styled(AccordionTrigger, {
  fontSize: 20,
  lineHeight: '30px',
  letterSpacing: '-0.8px',
  justifyContent: 'space-between',
  '& div.stateIcon': {
    p: 0,
    width: 20,
    height: 20,
    '& svg': {
      verticalAlign: 6,
    },
  },
});

const AngleDownIcon = () => (
  <Icon name="fa-angle-down-small" style={{ width: 20, height: 20 }} />
);

const IdentifyAssessResolveSection = () => {
  const [image, setImage] = useState<string>('item-1');

  return (
    <Flex
      wFull
      flow="column"
      alignItems="center"
      justifyContent="center"
      css={{
        gap: 45,
      }}
    >
      <Heading size="2" css={{ lineHeight: '36px', letterSpacing: '-0.8px' }}>
        {i18n.t('Identify. Assess. Resolve.')}
      </Heading>
      <Flex
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          gap: 60,
        }}
      >
        <Accordion
          collapsible
          type="single"
          css={{ maxWidth: '600px' }}
          defaultValue="item-1"
          onValueChange={(value: string) => setImage(value)}
        >
          <AccordionItemStyled value="item-1">
            <AccordionTriggerStyled customIcon={<AngleDownIcon />}>
              {i18n.t('See the vulnerabilities')}
            </AccordionTriggerStyled>
            <AccordionContentStyled>
              {i18n.t(
                'Prioritize your remediation efforts at a glance with a comprehensive view that shows you the risks affecting your security posture.',
              )}
            </AccordionContentStyled>
          </AccordionItemStyled>
          <AccordionItemStyled value="item-2">
            <AccordionTriggerStyled customIcon={<AngleDownIcon />}>
              {i18n.t('Assess their severity')}
            </AccordionTriggerStyled>
            <AccordionContentStyled>
              {i18n.t(
                'Investigate the devices and apps affected by a vulnerability. Get the information you need to remediate the vulnerability and mitigate risk.',
              )}
            </AccordionContentStyled>
          </AccordionItemStyled>
          <AccordionItemStyled value="item-3">
            <AccordionTriggerStyled customIcon={<AngleDownIcon />}>
              {i18n.t('Take immediate action')}
            </AccordionTriggerStyled>
            <AccordionContentStyled>
              {i18n.t(
                "Block software, push out a newer version, or automate all updates with Kandji's App Blocking, Custom App, or Auto App Library Items.",
              )}
            </AccordionContentStyled>
          </AccordionItemStyled>
        </Accordion>
        <Flex justifyContent="center">
          {image === 'item-1' && (
            <img
              src={SeeTheVulnerabilitiesImages}
              style={{
                width: 654,
                marginLeft: 20,
              }}
              alt="see-the-vulnerabilities"
            />
          )}
          {image === 'item-2' && (
            <img
              src={AssessTheirSeverityImage}
              style={{ width: 674 }}
              alt="assess-their-severity"
            />
          )}
          {image === 'item-3' && (
            <img
              src={TakeImmediateActionImage}
              style={{ width: 674 }}
              alt="take-immediate-action"
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export { IdentifyAssessResolveSection };
