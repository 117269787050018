/* istanbul ignore file */
import { blueprintLibraryCategories } from 'src/features/blueprints/common';
import { getEnabledPlatforms } from 'src/hooks/useGetEnabledPlatforms';
import { i18n } from 'src/i18n';
import {
  canAddLibraryTypes,
  categories,
  platformPublishers,
  platformTypes,
} from '../../common';
import svgs from '../icons';
import LibraryItemConfig from './item-config.class';

export default {
  'Google Play Store': new LibraryItemConfig({
    getName: () => i18n.t('Google Play Store App'),
    getDescription: () =>
      i18n.t(
        'Kandji will direct you to browse the Play Store to pick the app you want to deploy.',
      ),
    name: 'Google Play Store App',
    isHidden: () => {
      const { androidEnabled } = getEnabledPlatforms();
      return !androidEnabled;
    },
    description: `Kandji will direct you to browse the Play Store to pick the app you want to deploy.`,
    type: null,
    publisher: platformPublishers.GOOGLE,
    devices: [platformTypes.ANDROID],
    osRequirements: [],
    getOsRequirements: () => [],
    category: categories.GENERAL,
    blueprintCategory: blueprintLibraryCategories.PROFILES,
    icon: svgs['google_play'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'google-play-store-app',
    getUrl: ({ id }) => `/library/google-play-store-app/${id}`,
    getAddUrl: () => '/library/google-play-store-app/add',
    singleBlueprintAllowed: true,
  }),
};
