/* istanbul ignore file */
import {
  Badge,
  FilterButton,
  Flex,
  MultiSelect,
  Text,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import {
  translatedSeverity,
  translatedSeverityRanges,
} from '../../../constants';
import type { Severity } from '../../../vulnerability.types';

type SeverityMultiSelectProps = {
  severity: Severity[];
  setSeverity: (severity: Severity[]) => void;
  subtle?: boolean;
};

const SeverityMultiSelect = (props: SeverityMultiSelectProps) => {
  const { severity = [], setSeverity, subtle = false } = props;

  const severityOptions = [
    {
      label: i18n.t('Critical [{low} - {high}]', {
        low: translatedSeverityRanges.criticalBottom(),
        high: translatedSeverityRanges.criticalTop(),
      }),
      value: 'Critical',
    },
    {
      label: i18n.t('High [{low} - {high}]', {
        low: translatedSeverityRanges.highBottom(),
        high: translatedSeverityRanges.highTop(),
      }),
      value: 'High',
    },
    {
      label: i18n.t('Medium [{low} - {high}]', {
        low: translatedSeverityRanges.mediumBottom(),
        high: translatedSeverityRanges.mediumTop(),
      }),
      value: 'Medium',
    },
    {
      label: i18n.t('Low [{low} - {high}]', {
        low: translatedSeverityRanges.lowBottom(),
        high: translatedSeverityRanges.lowTop(),
      }),
      value: 'Low',
    },
    {
      label: i18n.t('None [{low}]', {
        low: translatedSeverityRanges.noneBottom(),
      }),
      value: 'None',
    },
  ];

  const getLabel = () => {
    // The subtle version of this filter uses the subtle styling, shows all the selected items, and does not show the label
    if (subtle) {
      return (
        <Flex flow="row" alignItems="center">
          {severity.length === severityOptions.length && (
            <Text css={{ fontWeight: '$medium' }}>
              {i18n.t('All severities')}
            </Text>
          )}
          {severity.length === 0 && (
            <Text css={{ fontWeight: '$medium' }}>{i18n.t('Severity')}</Text>
          )}
          {severity.length !== severityOptions.length &&
            severity.length !== 0 && (
              <Flex flow="row" gap="xs">
                <Flex
                  alignItems="center"
                  css={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Text
                    css={{
                      fontWeight: '$medium',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {severity
                      .map((item) => translatedSeverity[item]())
                      .join(', ')}
                  </Text>
                </Flex>
              </Flex>
            )}
        </Flex>
      );
    }

    // The non-subtle version of this filter uses default styling, shows the label, and shows a limited number of selected items
    const visibleItems = severity.slice(0, 3);
    const hiddenItemCount = severity.length - visibleItems.length;

    return (
      <Flex flow="row" alignItems="center">
        <Text>{i18n.t('Severity')}</Text>
        {severity.length === severityOptions.length && (
          <Flex flow="row" gap="xs">
            <Text>: </Text>
            <Text css={{ fontWeight: '$medium' }}> {i18n.t('All')}</Text>
          </Flex>
        )}
        {severity.length !== severityOptions.length && severity.length > 0 && (
          <Flex flow="row" gap="xs">
            <Text>:</Text>
            <Flex
              alignItems="center"
              css={{
                display: 'flex',
                flexWrap: 'nowrap',
              }}
            >
              <Text
                css={{
                  fontWeight: '$medium',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {visibleItems
                  .map((item) => translatedSeverity[item]())
                  .join(', ')}
              </Text>
            </Flex>
            {hiddenItemCount > 0 && (
              <Badge
                compact
                css={{
                  color: '$neutral0',
                  backgroundColor: '$blue50',
                }}
              >
                {i18n.t('+{count}', { count: hiddenItemCount })}
              </Badge>
            )}
          </Flex>
        )}
      </Flex>
    );
  };

  return (
    <MultiSelect
      multi
      options={severityOptions}
      value={severity}
      onChange={(value) => setSeverity(value)}
      footer={{
        showClear: true,
        clearLabel: i18n.t('Clear'),
        handleClear: () => setSeverity([]),
      }}
      componentCss={{ menu: { zIndex: 3 } }}
    >
      <FilterButton
        filtersSelected={Boolean(severity.length)}
        showRemove={false}
        maxWidth={400}
        css={{ height: 28 }}
        {...(subtle && { variant: 'subtle' })}
      >
        {getLabel()}
      </FilterButton>
    </MultiSelect>
  );
};

export { SeverityMultiSelect };
