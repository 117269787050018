import { Box, type CSS, Flex, Icon, Text, styled } from '@kandji-inc/nectar-ui';
import { useEffect, useState } from 'react';
import type { MouseEvent } from 'react';
import { i18n } from 'src/i18n';
import EDRTooltip from './EDRTooltip';

const Trigger = styled(Box, {
  svg: { width: 20, height: 20, display: 'block' },
  height: 20,
  width: 20,
  cursor: 'pointer',
  color: '$neutral70',

  '&:hover': {
    color: '$neutral60',
  },

  '&:active': {
    color: '$neutral80',
  },

  variants: {
    isShowingCopied: {
      true: {
        color: '$neutral80',

        '&:hover': {
          color: '$neutral80',
        },
      },
    },
  },
});

const CopiedText = styled(Text, {
  fontWeight: '$regular',
  letterSpacing: 0,
});

const Popover = styled(Flex, {
  backgroundColor: '$green60',
  color: '$neutral0',
  height: 24,
  fontSize: '$1',
  lineHeight: '$sm',
  borderRadius: 2,
  padding: '$1 $2',
  position: 'absolute',
  marginLeft: -27,
  marginTop: 8,
  pointerEvents: 'none',
  svg: { width: 12, height: 12 },
  variants: {
    position: {
      left: {
        marginLeft: -81,
        marginTop: -19,
      },
      right: {
        marginLeft: 25,
        marginTop: -19,
      },
    },
  },
});

const EDRCopyButton = ({
  value,
  tooltipSide = 'bottom',
  testId = 'hash-copy-button',
  css = { tooltipCss: {}, triggerCss: {} },
}: {
  value: string;
  tooltipSide?: 'top' | 'bottom' | 'left' | 'right';
  testId?: string;
  css?: { tooltipCss?: CSS; triggerCss?: CSS };
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (visible) {
      // istanbul ignore next
      timeoutId = setTimeout(() => setVisible(false), 1000);
    }
    return () => clearTimeout(timeoutId);
  }, [visible]);

  const handleCopyClick = (e: MouseEvent) => {
    e.stopPropagation();
    // istanbul ignore next
    global.navigator.clipboard.writeText(value || '');
    setVisible(true);
  };

  return (
    <EDRTooltip
      side={tooltipSide}
      label={i18n.t('Click to copy')}
      css={css.tooltipCss}
    >
      <Trigger
        onClick={handleCopyClick}
        data-testid={testId}
        isShowingCopied={visible}
        css={css.triggerCss}
      >
        <Icon name="copy" />
        {visible && (
          <Popover gap="xs" position={tooltipSide} alignItems="center">
            <Icon name="circle-check" />
            <CopiedText>{i18n.t('Copied')}</CopiedText>
          </Popover>
        )}
      </Trigger>
    </EDRTooltip>
  );
};

export default EDRCopyButton;
