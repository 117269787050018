import { Box, Button, Flex, Text, TextField } from '@kandji-inc/nectar-ui';
import { forgotPassword } from 'app/_actions/app';
import { i18n } from 'i18n';
/* istanbul ignore file */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useBodyNoMargin } from 'src/hooks/useBodyNoMargin';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { useTemporaryCss } from 'src/hooks/useTemporaryCss';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import Loader from 'src/theme/components/atoms/Loader';
import KandjiBee from './assets/kandji-logo.svg';
import { footerLinks } from './common';

const ForgotPassword = () => {
  const isScreenSmall = useMediaQuery('(max-width: 768px)');
  useWhiteBackground();
  useBodyNoMargin();
  useTemporaryCss('#app', { minWidth: 'unset' });

  const { clientId } = useSelector((state) => state.auth0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();

  const onSubmit = ({ email }) => {
    if (!email) {
      setError('email', { message: i18n.t('Required.') });
      return;
    }

    return forgotPassword({ email })
      .then(() => setIsSuccess(true))
      .catch(() =>
        setError('email', {
          message: i18n.t(
            'Email address not found. Please enter a valid email address.',
          ),
        }),
      );
  };

  const linkStyle = {
    color: '#505E71',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
  };

  if (!clientId) {
    return (
      <Flex wFull hFull alignItems="center" justifyContent="center">
        <Loader type="splash" />
      </Flex>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex wFull hFull css={{ height: '100vh' }}>
        <Flex flex="1" alignItems="center" justifyContent="center">
          <Box
            hFull
            css={{
              display: 'grid',
              gridTemplateRows: '1fr auto',
              padding: '48px',
              paddingBottom: 0,
            }}
          >
            <Flex
              flow="column"
              css={{
                alignSelf: 'center',
                justifySelf: 'center',
                width: '340px',
              }}
            >
              {isSuccess && (
                <>
                  <img
                    src={KandjiBee}
                    alt="kandji-bee"
                    style={{
                      height: '48px',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      alignSelf: 'flex-start',
                      marginBottom: '24px',
                    }}
                  />
                  <Text
                    size="5"
                    css={{ marginBottom: '18px', letterSpacing: '-1.2px' }}
                  >
                    {i18n.t('Email sent.')}
                  </Text>
                  <Text css={{ lineHeight: '20px', marginBottom: '28px' }}>
                    {i18n.t(
                      'Check your email for a link to reset your password.',
                    )}
                  </Text>
                  <Button
                    variant="primary"
                    onClick={() => history.push('/signin')}
                  >
                    {i18n.t('Back to log in')}
                  </Button>
                </>
              )}

              {!isSuccess && (
                <>
                  <img
                    src={KandjiBee}
                    alt="kandji-bee"
                    style={{
                      height: '48px',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      alignSelf: 'flex-start',
                      marginBottom: '24px',
                    }}
                  />
                  <Text
                    size="5"
                    css={{ marginBottom: '16px', letterSpacing: '-1.2px' }}
                  >
                    {i18n.t('Forgot password')}
                  </Text>
                  <Text css={{ lineHeight: '20px', marginBottom: '28px' }}>
                    {i18n.t(
                      'Enter the email associated with your account to receive a link to reset your password.',
                    )}
                  </Text>
                  <TextField
                    {...register('email')}
                    label={i18n.t('Email address')}
                    placeholder={i18n.t('Enter your email address')}
                    hint={
                      errors.email
                        ? {
                            label: errors.email.message,
                          }
                        : undefined
                    }
                    state={errors.email ? 'error' : 'default'}
                    onKeyDownCapture={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit(onSubmit)();
                      }
                    }}
                    css={{ marginBottom: '18px' }}
                  />

                  <Flex flow="column" gap="sm">
                    <Button variant="primary" type="submit">
                      {i18n.t('Continue')}
                    </Button>
                    <Button
                      variant="subtle"
                      onClick={() => history.push('/signin')}
                    >
                      {i18n.t('Cancel')}
                    </Button>
                  </Flex>
                </>
              )}
            </Flex>
            <Flex
              gap="sm"
              wrap="wrap"
              justifyContent="center"
              css={{ paddingBottom: '42px' }}
            >
              {footerLinks.map(({ getLabel, link }) => (
                <a
                  key={getLabel()}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  style={linkStyle}
                >
                  {getLabel()}
                </a>
              ))}
            </Flex>
          </Box>
        </Flex>
        {!isScreenSmall && (
          <Flex
            flex="1"
            css={{
              background: 'linear-gradient(180deg, #000 -15.27%, #2A2A2A 100%)',
              flexShrink: 2,
            }}
          />
        )}
      </Flex>
    </form>
  );
};

export default ForgotPassword;
