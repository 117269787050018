/* istanbul ignore file */
import { Banner, Flex, Text, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { getDevicePlatform } from 'src/utils/PlatformUtils';
import DeferredInstallNotification from './notifications/DeferredInstallNotification';
import DuplicatesNotification from './notifications/DuplicatesNotification';
import ErasedNotification from './notifications/ErasedNotification';
import LockedNotification from './notifications/LockedNotification';

const Wrapper = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$2',
});

const ComputerNotifications = ({ computer }) => {
  if (isEmpty(computer)) {
    return null;
  }

  const {
    erase_status: eraseStatus,
    lock_status: lockStatus,
    device_family: deviceFamily,
    model: deviceModel,
    os_version: osVersion,
    last_checkins: lastCheckIns,
    activation_lock,
  } = computer;
  const platform = getDevicePlatform(deviceFamily);

  const lastMdmCheckIn = lastCheckIns['mdm-command'];
  const activationLockSupported = activation_lock?.activation_lock_supported;

  const mdmUnenrolled =
    !computer.is_mdm &&
    !computer.is_missing &&
    eraseStatus === 'no' &&
    platform !== 'Windows';
  const isLocked = lockStatus !== 'no';
  const isErased = eraseStatus !== 'no';
  const isDeferred = computer.deferred_install;
  const isMissing = computer.is_missing && !computer.erased;
  const hasDuplicates = computer.duplicates && computer.duplicates.length > 0;

  const hasAnyStatus =
    mdmUnenrolled ||
    isLocked ||
    isErased ||
    isDeferred ||
    isMissing ||
    hasDuplicates;

  if (!hasAnyStatus) {
    return null;
  }

  return (
    <Wrapper>
      {/* ------ MDM Un-Enrolled ----- */}
      {/* TODO: This should be handled more gracefully. For now, we hide this banner on Windows. */}
      {mdmUnenrolled && (
        <Banner
          theme="warning"
          text={
            <Flex alignItems="center" gap="xs">
              {i18n.t('MDM Profile Removed.')}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.kandji.io/kb/configuring-device-enrollment"
              >
                <Text
                  variant="primary"
                  css={{
                    fontWeight: '$medium',
                    display: 'inline-block',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  {i18n.t('Learn more')}
                </Text>
              </a>
            </Flex>
          }
        />
      )}
      {/* ---------- Locked ---------- */}
      {isLocked && (
        <LockedNotification
          computerId={computer.id}
          lockStatus={lockStatus}
          deviceModel={deviceModel}
          osVersion={osVersion}
        />
      )}
      {/* ---------- Erased ---------- */}
      {isErased && (
        <ErasedNotification
          computerId={computer.id}
          eraseStatus={eraseStatus}
          deviceFamily={deviceFamily}
          deviceModel={deviceModel}
          osVersion={osVersion}
          activationLockSupported={activationLockSupported}
        />
      )}
      {/* ---------- Deferred ---------- */}
      {isDeferred && (
        <DeferredInstallNotification lastEnroll={computer.enrolled_at} />
      )}
      {/* ---------- Missing ---------- */}
      {isMissing && (
        <Banner
          theme="warning"
          text={i18n.t(
            `{computerName} has not checked in with MDM{lastMdmCheckIn}.`,
            {
              computerName: computer.computerName,
              lastMdmCheckIn: handleDatetime(lastMdmCheckIn),
            },
          )}
        />
      )}
      {/* ---------- Duplicates ---------- */}
      {hasDuplicates && <DuplicatesNotification computer={computer} />}
    </Wrapper>
  );
};

function handleDatetime(checkIn) {
  const datetime = i18n.format.datetime(checkIn);
  if (datetime === 'Invalid timestamp') {
    return '';
  }
  return i18n.t(` since {datetime}`, { datetime });
}

const mapStateToProps = (state) => ({
  computer: state.computerRecord,
});

export default connect(mapStateToProps)(ComputerNotifications);
