import { constants } from '../../../common';
import getDateRange from '../../../common/utils/get-date-range';
import getSortBy from '../../../common/utils/get-sort-by';
import type {
  FiltersState,
  GetThreatDetailsRequestParams,
  PaginationState,
  SortColumnState,
  ThreatClassification,
} from '../../../threat.types';

const toRequestFormat = (
  filters: FiltersState,
  sort: SortColumnState,
  pagination: PaginationState,
  deviceId?: string,
): GetThreatDetailsRequestParams => {
  const limit = String(pagination.pageSize);
  const offset = String(((pagination.page || 1) - 1) * Number(limit));
  // TODO: Remove casting when old UI is removed and ThreatClassification can be refactored to be lowercase
  const classification =
    filters.classification?.toLowerCase() as ThreatClassification;
  const statuses = (filters.status || constants.THREAT_STATUS_LIST)
    ?.map((item) => item.toLowerCase())
    .join(',');
  const newDeviceId = deviceId || '';
  const sortBy = getSortBy(sort);
  const { from, to } = getDateRange(filters.detectionDate);
  const term = filters.query || '';

  const filtersTransformedToApi: GetThreatDetailsRequestParams = {
    limit,
    offset,
    sort_by: sortBy,
    device_id: newDeviceId,
    statuses,
    classification,
    term,
    detection_date_from: from,
    detection_date_to: to,
  };

  return filtersTransformedToApi;
};

export default toRequestFormat;
