import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { i18n } from 'i18n';
import type { GetColumnsParams, ThreatItem } from '../types';
import createCellRenderers from '../utils/create-cell-renderers';

const getColumns = ({
  isFileDetectionType,
  query,
  onRelease,
  onRecheckStatus,
  isThreatBeingChecked,
  isMoreActionsShown,
}: GetColumnsParams): ColumnDef<ThreatItem, unknown>[] => {
  const {
    renderThreatIdHeader,
    renderThreatIdCell,
    renderAssociatedItemCell,
    renderAssociatedItemHeader,
    renderClassificationCell,
    renderStatusCell,
    renderDetectionDateCell,
    renderActionsCell,
  } = createCellRenderers(
    isFileDetectionType,
    query,
    onRelease,
    onRecheckStatus,
    isThreatBeingChecked,
  );

  const columnHelper = createColumnHelper<ThreatItem>();

  const columns = [
    columnHelper.accessor('file_hash', {
      id: 'threat_name',
      header: renderThreatIdHeader,
      cell: renderThreatIdCell,
      ...(!isFileDetectionType ? {} : { minSize: 180 }),
      ...(isFileDetectionType ? {} : { maxSize: 110 }),
      meta: {
        displayName: i18n.t('Threat ID'),
      },
    }),
    columnHelper.accessor('associated_item', {
      header: renderAssociatedItemHeader,
      cell: renderAssociatedItemCell,
      maxSize: 85,
      meta: {
        displayName: i18n.t('Associated Item'),
      },
    }),
    columnHelper.accessor('classification', {
      header: i18n.t('Classification'),
      cell: renderClassificationCell,
      maxSize: 65,
      meta: {
        displayName: i18n.t('Classification'),
      },
    }),
    columnHelper.accessor('detection_date', {
      header: i18n.t('Detection Date'),
      cell: renderDetectionDateCell,
      maxSize: 65,
      meta: {
        displayName: i18n.t('Detection Date'),
      },
    }),
    columnHelper.accessor('status', {
      header: i18n.t('Threat Status'),
      cell: renderStatusCell,
      maxSize: 85,
      meta: {
        displayName: i18n.t('Threat Status'),
      },
    }),
  ];

  // Add actions column conditionally
  if (isMoreActionsShown) {
    columns.push(
      columnHelper.display({
        id: 'actions',
        cell: renderActionsCell,
        enableSorting: false,
        maxSize: 40,
      }),
    );
  }

  return columns;
};

export default getColumns;
