import type { Computer } from '@shared/types/common.types';
import useAddBodyClass from 'features/util/hooks/use-add-body-class';
import useAdjustSidebarChatBubble from 'features/util/hooks/use-adjust-sidebar-chat-bubble';
import { parse, stringify } from 'query-string';
import { StrictMode, memo, useCallback } from 'react';
import featureFlags from 'src/config/feature-flags';
import useAccount from 'src/contexts/account';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import ThreatListErrorBoundary from './common/components/ThreatListErrorBoundary';
import ThreatListSuspense from './common/components/ThreatListSuspense';
import EdrTrial from './edr-trial/EdrTrial';
import ThreatListEvents from './threat-events/ThreatList';
import ThreatListGroups from './threat-groups/ThreatList';

import './styles.css';

type ThreatListProps = Readonly<{
  computer?: Computer;
  isEventsView?: boolean;
}>;

const queryParamConfig = {
  searchStringToObject: parse,
  objectToSearchString: stringify,
  removeDefaultsFromUrl: true,
} as const;

const ThreatList = memo(({ computer, isEventsView }: ThreatListProps) => {
  const { currentCompany } = useAccount();
  const feature_configuration = (currentCompany as any)?.feature_configuration;

  const isEdrTrialOn = featureFlags.getFlag('edr_110824_edr-trial-enabled-ui');
  const isSkuEnabled = Boolean(feature_configuration?.edr?.enabled);
  const isEdrTrialPageShown = isEdrTrialOn && !isSkuEnabled;

  useAddBodyClass('threat', !isEventsView);
  useAddBodyClass('threat-v2', Boolean(isEventsView));
  useAdjustSidebarChatBubble();

  const renderContent = useCallback(() => {
    if (isEdrTrialPageShown) {
      return <EdrTrial />;
    }

    if (isEventsView) {
      return <ThreatListEvents computer={computer} />;
    }

    return <ThreatListGroups />;
  }, [isEdrTrialPageShown, isEventsView, computer]);

  return (
    <StrictMode>
      <ThreatListErrorBoundary css={{ position: 'absolute', inset: 0 }}>
        <ThreatListSuspense>
          <QueryParamProvider
            adapter={ReactRouter5Adapter}
            options={queryParamConfig}
          >
            {renderContent()}
          </QueryParamProvider>
        </ThreatListSuspense>
      </ThreatListErrorBoundary>
    </StrictMode>
  );
});

export default ThreatList;
