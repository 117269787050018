import { Box, Flex, Heading, Paragraph, styled } from '@kandji-inc/nectar-ui';
import type React from 'react';
import { i18n } from 'src/i18n';

const StyledParagraph = styled(Paragraph, {
  color: '$neutral90',
  whiteSpace: 'normal',
  textAlign: 'center',
  maxWidth: 486,
});

const StyledHeading = styled(Heading, {
  color: '$neutral90',
  fontWeight: '$medium',
  letterSpacing: '-$3',
});

export type ThreatListEmptyTableProps = {
  header: string;
  icon?: string;
  iconWidth?: number | undefined;
  body: string | React.ReactNode;
  slot?: React.ReactNode;
  compact?: boolean;
};

const ThreatListEmptyTable = ({
  icon,
  iconWidth,
  header,
  body,
  slot,
  compact = false,
}: ThreatListEmptyTableProps) => (
  <Flex
    flex={1}
    flow="column"
    alignItems="center"
    justifyContent="center"
    gap={compact ? 'lg' : 'xl'}
  >
    {icon && (
      <img
        src={icon}
        alt={i18n.t('No results')}
        style={{ width: iconWidth || 'auto' }}
      />
    )}
    <Flex flow="column" alignItems="center" gap={compact ? 'xs' : 'md'}>
      <StyledHeading as="h4" css={{ fontSize: compact ? '$3' : '$4' }}>
        {header}
      </StyledHeading>
      <StyledParagraph>{body}</StyledParagraph>
      <Box mt2>{slot}</Box>
    </Flex>
  </Flex>
);

export default ThreatListEmptyTable;
