import type { DetectionDateFilterFields } from '../common/common.types';

export enum Severity {
  undefined = 'Undefined',
  none = 'None',
  low = 'Low',
  medium = 'Medium',
  high = 'High',
  critical = 'Critical',
}

export enum VulnerabilityType {
  operatingSystem = 'OPERATING_SYSTEM',
  application = 'APPLICATION',
}

export type Vulnerability = {
  cve_id: string;
  cve_link: string;
  cve_published_at: string;
  cve_modified_at: string;
  description: string;
  cvss_score: number;
  severity: Severity;
  kev_score: 0 | 1;
  epss_score: number;

  // Applicable when a Vulnerability is associated with entire fleet
  software?: { name: string; icon_key?: string }[];
  software_count?: number;
  first_detection_date?: string;
  latest_detection_date?: string;
  device_count?: number;

  // Applicable when a Vulnerability is associated with a specific device
  detection_datetime?: string;
  name?: string;
  icon_key?: string | null;
  version?: string;
  path?: string;
};

export type AllVulnerabilitiesFilter = {
  term: string;
  severity: Severity[];
  latestDetected: DetectionDateFilterFields;
  softwareName: {
    appName: Software['name'][];
    osName: Software['name'][];
  };
};

export type DeviceVulnerabilitiesFilter = {
  term: string;
  severity: Severity[];
  detectionDate: DetectionDateFilterFields;
};

export type SoftwareSummary = {
  detection_date: string;
  path: string;
  version: string;
  name: string;
  icon_key: string | null;
};

export type VulnerableDevice = {
  software_summary: SoftwareSummary[];
  assigned_user: string;
  assigned_user_email: string;
  blueprint_name: string;
  blueprint_id: string;
  device_id: string;
  first_detected: string;
  last_detected: string;
  model: string;
  name: string;
  no_of_installs: number;
  os_version: string;
  serial_number: string;
};

export type VulnerableDevicePagination = {
  page: number;
  size: number;
};

export type VulnerabilityDevices = {
  devices: VulnerableDevice[];
  totalDevices: number;
  devicePagination: VulnerableDevicePagination;
};

type Blueprint = {
  name: string;
  id: string;
};

export type SoftwareVersion = {
  latest_detection_date: string;
  blueprints: Blueprint[];
  version: string;
  devices_impacted: number;
};

export type Software = {
  name: string;
  icon_key: string | null;
  num_versions: number;
  blueprints: Blueprint[];
  num_devices: number;
  vulnerability_type: VulnerabilityType;
  software_versions: SoftwareVersion[];
};

export type CVEFeedback = {
  issue: string;
  details?: string;
};

export type AllVulnerabilitiesRequestSchema = {
  filter: {
    severity?: { in: Severity[] | null };
    latest_detection_date?: any;
    vulnerable_software?: { in: Software['name'][] | null };
  };
  sort_by: string;
  search: string;
};

export type DeviceVulnerabilitiesRequestSchema = {
  filter: { severity?: { in: Severity[] | null }; detection_datetime?: any };
  sort_by: string;
  search: string;
};

export type VulnerableDevicesRequestSchema = {
  filter: { detection_datetime?: any; blueprint_id?: { in: string[] | null } };
  sort_by: SidePanelDeviceTabSortTypes;
  search: string;
};

export type VulnerableSoftwareRequestSchema = {
  filter: {
    latest_detection_date?: any;
    blueprint_id?: { in: string[] | null };
  };
  sort_by: SidePanelSoftwareTabSortTypes;
  search: string;
};

export type ExportAllVulnerabilitiesRequestSchema = {
  filter: AllVulnerabilitiesRequestSchema['filter'];
};

export type SidePanelDeviceTabSortTypes =
  | 'last_detection_date'
  | '-last_detection_date'
  | 'name'
  | '-name';

export type SidePanelSoftwareTabSortTypes =
  | 'latest_detection_date'
  | '-latest_detection_date'
  | 'vulnerable_software'
  | '-vulnerable_software';
