import { Button, Flex } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import type * as React from 'react';
import { useFlags } from 'src/config/feature-flags';
import type {
  Blueprint,
  DeviceFamily,
} from 'src/features/visibility/prism/hooks';

type GlobalFilterModalFooterProps = {
  handleApplyfilters: () => void;
  setEditViewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBlueprints: React.Dispatch<React.SetStateAction<Blueprint[]>>;
  setSelectedDeviceFamilies: React.Dispatch<
    React.SetStateAction<DeviceFamily[]>
  >;
  setBlueprintFilterOption: React.Dispatch<React.SetStateAction<string>>;
};

const GlobalFilterModalFooter = ({
  handleApplyfilters,
  setEditViewModalOpen,
  setSelectedBlueprints,
  setSelectedDeviceFamilies,
  setBlueprintFilterOption,
}: GlobalFilterModalFooterProps) => {
  const { 'ios-apple-vision-pro': iosAppleVisionPro } = useFlags();
  const allDeivceFamilies: DeviceFamily[] = [
    'Mac',
    'iPhone',
    'iPad',
    'AppleTV',
  ];
  if (iosAppleVisionPro) {
    allDeivceFamilies.push('Vision');
  }
  return (
    <Flex>
      <Button
        variant="subtle"
        onClick={() => {
          setSelectedBlueprints([]);
          setSelectedDeviceFamilies(allDeivceFamilies);
          setBlueprintFilterOption('all_blueprints');
        }}
      >
        {i18n.t('Reset to default')}
      </Button>
      <Flex css={{ ml: 'auto' }} gap="sm">
        <Button variant="default" onClick={() => setEditViewModalOpen(false)}>
          {i18n.t('Cancel')}
        </Button>
        <Button variant="primary" onClick={handleApplyfilters}>
          {i18n.t('Apply')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default GlobalFilterModalFooter;
