import { Flex, Text, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { SparkleBeeLogo } from './sparkle-bee-logo';

export const EmptyState = () => {
  return (
    <Container>
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{
          marginBottom: '24px',
        }}
      >
        <LogoContainer>
          <SparkleBeeLogo />
        </LogoContainer>
        <Text
          css={{
            color: '$neutral90',
            textAlign: 'center',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '36px /* 150% */',
            letterSpacing: '-0.8px',
          }}
        >
          {i18n.t('How can I help you today?')}
        </Text>
      </Flex>
    </Container>
  );
};

const Container = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  gap: '32px',
  flex: '1 0 0',
  alignSelf: 'stretch',
});

const LogoContainer = styled(Flex, {
  display: 'flex',
  // width: '80px',
  // height: '80px',
  padding: 'var(--Primitive-Spacing-space-8, 8px)',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  // borderRadius: '12px',
  // background: 'var(--Neutral-neutral90, #1F272F)',
});
