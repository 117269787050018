import { i18n } from 'i18n';

/* istanbul ignore file */
export const footerLinks = [
  {
    getLabel: () => i18n.t('Privacy Policy'),
    link: 'https://www.kandji.io/legal/privacy/',
  },
  {
    getLabel: () => i18n.t('Cookie Policy'),
    link: 'https://www.kandji.io/legal/privacy/',
  },
  {
    getLabel: () => i18n.t('California Privacy Notice'),
    link: 'https://www.kandji.io/legal/privacy/',
  },
  {
    getLabel: () => i18n.t('Accessibility'),
    link: 'https://www.kandji.io/legal/privacy/',
  },
  {
    getLabel: () => i18n.t('Legal'),
    link: 'https://www.kandji.io/legal/privacy/',
  },
  {
    getLabel: () => i18n.t('Patent'),
    link: 'https://www.kandji.io/legal/privacy/',
  },
];
