import { Chip, Flex, Text, Tooltip } from '@kandji-inc/nectar-ui';
import { useLayoutEffect, useRef, useState } from 'react';
import { highlightedText } from 'src/pages/ADEListView/utils/highlightText';

const CHIP_GAP = 4;
const PLUS_CHIP_WIDTH = 23;

const OverflowingChipList = (props: {
  chips: string[];
  tooltipWidth?: number;
  searchTerm?: string | undefined;
}) => {
  const { chips, searchTerm = '' } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleChips, setVisibleChips] = useState(chips.length);
  const [containerWidth, setContainerWidth] = useState(0);

  useLayoutEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateContainerWidth();

    window.addEventListener('resize', updateContainerWidth);
    return () => window.removeEventListener('resize', updateContainerWidth);
  }, []);

  useLayoutEffect(() => {
    if (containerWidth === 0) return;

    const children = Array.from(
      containerRef.current?.children[0]?.children || [],
    );
    const widths = children.map((child) => child.offsetWidth);

    let totalWidth = 0;
    let visibleCount = 0;

    for (let i = 0; i < widths.length; i++) {
      totalWidth += widths[i] + CHIP_GAP;
      if (totalWidth + PLUS_CHIP_WIDTH > containerWidth) break;
      visibleCount++;
    }

    setVisibleChips(visibleCount);
  }, [chips, containerWidth]);

  return (
    <Flex gap="xs" ref={containerRef} css={{ width: '100%' }}>
      <Flex gap="xs">
        {chips.map((chip, index) => (
          <Chip
            key={chip}
            label={
              <Text css={{ fontWeight: '$medium' }}>
                {highlightedText(chip, searchTerm)}
              </Text>
            }
            size="compact"
            css={{
              visibility: index >= visibleChips ? 'hidden' : 'visible',
              position: index >= visibleChips ? 'absolute' : 'static',
              userSelect: 'text',
            }}
          />
        ))}
      </Flex>
      {visibleChips < chips.length && (
        <Tooltip
          side="bottom"
          theme="light"
          css={{ zIndex: 2, padding: '$3 $4', maxWidth: 'min-content' }}
          interactive
          content={
            <Flex gap="sm" flow="row" wrap="wrap">
              {chips.slice(visibleChips).map((chip) => (
                <Chip
                  key={chip}
                  label={
                    <Text css={{ fontWeight: '$medium' }}>
                      {highlightedText(chip, searchTerm)}
                    </Text>
                  }
                  size="compact"
                  css={{ userSelect: 'text' }}
                />
              ))}
            </Flex>
          }
        >
          <Chip
            label={`+${chips.length - visibleChips}`}
            size="compact"
            css={{ width: 15 }}
          />
        </Tooltip>
      )}
    </Flex>
  );
};

export { OverflowingChipList };
