import { Box, Flex, Text } from '@kandji-inc/nectar-ui';
import React from 'react';

import { ProfileTypes } from 'src/app/common/constants';

import { OTHER_PROFILE_TYPES } from './constants';

import { i18n } from 'i18n';
import type { StatusTypeKind } from './device-status-tab.types';

/* A button to download the log file */
const LogDownload = (props: { logFileUrl: string }) => {
  const { logFileUrl } = props;

  return logFileUrl ? (
    <Box ml6 className="b-txt-bold">
      <a
        href={logFileUrl}
        rel="noopener noreferrer"
        className="b-alink b-ml-micro"
      >
        Download log file
      </a>
    </Box>
  ) : null;
};

/* Formats log text into a bullet pointed list */
export const formatLogText = (logText: string) =>
  logText.split('\n').map((l: string, i: number) => (
    <Flex key={i} flow="row" gap="sm" pt1 pl2>
      <Text>•</Text>
      <Text>{l}</Text>
    </Flex>
  ));

/* Formats log text with no bullet in sight */
export const semiformatLogText = (logText: string) =>
  logText.split('\n').map((l: string, i: number) => (
    <Flex key={i} flow="row" gap="sm" pt1 pl2>
      <Text>{l}</Text>
    </Flex>
  ));

/* The base log format */
export const LogRecord = (props: {
  header: string;
  date?: string;
  text?: string;
  logFileUrl?: string;
}) => {
  const { header, date, text, logFileUrl } = props;

  const logDownloadStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    alignItems: 'center',
  };

  return (
    header && (
      <Box mb2>
        <Box css={logFileUrl ? logDownloadStyles : {}}>
          <Text>
            {header}
            {date && ` - ${i18n.format.datetime(date)}`}
          </Text>
          <LogDownload logFileUrl={logFileUrl} />
        </Box>
        {text && <Box>{formatLogText(text)}</Box>}
      </Box>
    )
  );
};

/* The log used for most Library Items */
export const LibraryItemLog = (libraryItem: {
  status?: string;
  last_audit_run?: string;
  last_audit_log?: string;
  date?: string;
  log?: string;
}) => {
  const { status, last_audit_run, last_audit_log, date, log } = libraryItem;
  const installInProgress = ['CACHED', 'DOWNLOADING', 'INSTALLING'].includes(
    status,
  );
  return (
    <Flex flow="column" gap="lg">
      {last_audit_log && (
        <LogRecord
          header={i18n.t('Last Audit')}
          date={last_audit_run}
          text={last_audit_log}
        />
      )}
      {log && (
        <LogRecord
          header={
            installInProgress
              ? i18n.t('Last Install Progress')
              : i18n.t('Last Install')
          }
          date={date}
          text={log}
        />
      )}
    </Flex>
  );
};

/* A log used for VPP apps */
export const VppAppLog = (vppApp: {
  status: string;
  last_audit_run?: string;
  last_audit_log?: string;
  date?: string;
  log?: string;
  log_file_url?: string;
}) => {
  const { status, last_audit_run, last_audit_log, date, log, log_file_url } =
    vppApp;
  const isInstall = ['installing', 'INSTALLING'].includes(status);

  return (
    <Flex flow="column" gap="lg">
      {last_audit_log && (
        <LogRecord
          header={i18n.t('Last Audit')}
          date={last_audit_run}
          text={last_audit_log}
        />
      )}
      {!isInstall && log && (
        <LogRecord
          header={i18n.t('Last Install')}
          date={date}
          text={log}
          logFileUrl={log_file_url}
        />
      )}
    </Flex>
  );
};

/* A log used for Library Items that are typically only run once (i.e. Custom Scripts) */
export const StandardLog = (item: { date?: string; log?: string }) => {
  const { date, log } = item;
  return log ? <LogRecord header="Last Run" date={date} text={log} /> : null;
};

/* A log used for Profiles with the 'profile' type */
export const ProfileLog = (
  row: {
    control_log?: string;
    control_reported_at?: string;
    last_audit_log?: string;
    last_audit_run?: string;
    log?: string;
    date?: string;
  },
  controlLogHeader = null,
) => {
  const {
    control_log,
    control_reported_at,
    last_audit_log,
    last_audit_run,
    log,
    date,
  } = row;

  return (
    <Flex flow="column" gap="lg">
      {control_log && (
        <LogRecord
          header={controlLogHeader || i18n.t('Status')}
          date={control_reported_at}
          text={control_log}
        />
      )}
      {last_audit_log && (
        <LogRecord
          header={i18n.t('Last Profile Audit')}
          date={last_audit_run}
          text={last_audit_log}
        />
      )}
      {log && (
        <LogRecord
          header={i18n.t('Last Profile Install')}
          date={date}
          text={log}
        />
      )}
    </Flex>
  );
};

/* Returns the appropriate logs for a Profile depending on the type (i.e. 'profile' vs. 'ssh') */
export const LogProfileWithControl = (row: {
  date?: string;
  identifier?: string;
  type?: StatusTypeKind;
  control_log?: string;
  control_reported_at?: string;
  last_audit_log?: string;
  last_audit_run?: string;
  log?: string;
}) => {
  const { type, identifier } = row;

  if (OTHER_PROFILE_TYPES.includes(type)) {
    return LibraryItemLog(row);
  }

  let controlLogTitle = null;
  if (identifier === ProfileTypes.FILEVAULT) {
    controlLogTitle = 'FileVault Status';
  } else if (identifier === ProfileTypes.FIREWALL) {
    controlLogTitle = 'Firewall Status';
  }

  return ProfileLog(row, controlLogTitle);
};

/* A log used for Parameters */
export const ParameterLog = (row: {
  details: Array<string>;
  status: string;
  run: string;
}) => {
  const { details, status, run } = row;
  return (
    details?.length && (
      <Box>
        {run && (
          <Text css={{ marginBottom: '$2' }}>
            {status !== 'REMEDIATED'
              ? `Last Update: ${i18n.format.datetime(run)}`
              : `Remediation on ${i18n.format.datetime(run)}`}
          </Text>
        )}
        {details?.map((el) => (
          <Text key={el} css={{ marginBottom: '$2' }}>
            {el}
          </Text>
        ))}
      </Box>
    )
  );
};

/* A log used for AppBlocking */
export const AppBlockingLog = (libraryItem: {
  date?: string;
  log?: string;
  last_audit_log?: string;
  last_audit_run?: string;
}) => {
  const { date, log, last_audit_log, last_audit_run } = libraryItem;
  return (
    (log || last_audit_log) && (
      <Box>
        {(date || last_audit_run) && (
          <Text css={{ marginBottom: '$2' }}>
            {`Last Update: ${i18n.format.datetime(date || last_audit_run)}`}
          </Text>
        )}
        <Box css={{ marginBottom: '$2' }}>
          {semiformatLogText(log || last_audit_log)}
        </Box>
      </Box>
    )
  );
};
