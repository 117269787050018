import { formatNumber } from '@kandji-inc/nectar-i18n';
import {
  Box,
  Button,
  Card,
  Flex,
  Icon,
  Link,
  Text,
  Tooltip,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useEffect, useRef, useState } from 'react';
import { links } from 'src/app/common/constants';
import { SoftwareIcon } from 'src/features/edr/vulnerability/common/software-icon';
import type { VulnerableDevice } from 'src/features/edr/vulnerability/vulnerability.types';
import { highlightedText } from 'src/pages/ADEListView/utils/highlightText';
import DeviceIcon from '../../../../assets/device.png';
import { DeviceSoftwareItem } from './device-software-item';

const VerticalDivider = styled(Flex, {
  width: '1px',
  height: '16px',
  backgroundColor: '$neutral20',
  borderRadius: '$rounded',
});

const HorizontalDivider = styled(Box, {
  width: '100%',
  height: '1px',
  backgroundColor: '$neutral20',
});

type DeviceCardProps = {
  device: VulnerableDevice;
  searchTerm: string;
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
};

const DeviceCard = (props: DeviceCardProps) => {
  const { device, searchTerm, isExpanded, setIsExpanded } = props;
  const {
    software_summary,
    assigned_user,
    assigned_user_email,
    blueprint_name,
    blueprint_id,
    device_id,
    name,
    serial_number,
  } = device;

  const DEVICE_PATH_LIST_LIMIT = 5;
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const scrollToRef = useRef(null);
  const showLessButtonRef = useRef(null);

  useEffect(
    /* istanbul ignore next */ () => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Set showArrow to true if the "Show less" button is not intersecting
          if (showLessButtonRef.current) {
            setShowArrow(
              // Checking that `top` is less than 150 prevents the arrow from appearing
              // when the "Show less" button is hidden at the bottom of the page
              showLessButtonRef.current.getBoundingClientRect().top < 150 &&
                !entry.isIntersecting,
            );
          }
        },
        { root: null, threshold: 1 }, // Trigger when the button is completely out of view
      );

      if (showLessButtonRef.current) {
        observer.observe(showLessButtonRef.current);
      }

      return () => observer.disconnect();
    },
    [isShowingMore],
  );

  return (
    <Card css={{ padding: '$0' }}>
      <Flex
        flow="column"
        gap="lg"
        css={{ scrollMarginTop: '115px' }}
        ref={scrollToRef}
      >
        <Flex alignItems="center" gap="sm" p4 pb0>
          <img
            src={DeviceIcon}
            alt="device"
            style={{ width: 44, height: 44 }}
          />
          <Flex flow="column" gap="sm">
            <Flex alignItems="center" gap="sm">
              <Text size="3" css={{ fontWeight: '$medium' }}>
                {highlightedText(name, searchTerm)}
              </Text>
              <Link
                href={`${links.devices}/${device_id}/vulnerabilities`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  name="arrow-up-right-from-square"
                  size="sm"
                  color="var(--colors-neutral70)"
                />
              </Link>
            </Flex>

            <Flex gap="xs">
              <Text variant="secondary">{serial_number}</Text>
              {assigned_user && <Text variant="secondary">{'—'}</Text>}
              {assigned_user && (
                <Text variant="secondary">{assigned_user}</Text>
              )}
              {assigned_user_email && <Text variant="secondary">{'—'}</Text>}
              <Text variant="secondary">{assigned_user_email}</Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="lg"
          alignItems="center"
          css={{ padding: '0 $4', ...(!isExpanded && { paddingBottom: '$3' }) }}
        >
          <Flex
            gap="xs"
            alignItems="center"
            onClick={() => setIsExpanded(!isExpanded)}
            css={{ '&:hover': { cursor: 'pointer' } }}
          >
            <Box
              css={{
                width: 16,
                height: 16,
                svg: { width: 16, height: 16, verticalAlign: 'top' },
              }}
            >
              <Icon
                name={isExpanded ? 'fa-angle-up-small' : 'fa-angle-down-small'}
                color="var(--color-neutral-70)"
              />
            </Box>
            <Text variant="secondary" size="1" css={{ fontWeight: '$medium' }}>
              {isExpanded
                ? i18n.t('View less details')
                : i18n.t('View more details')}
            </Text>
          </Flex>

          <VerticalDivider />

          <Flex alignItems="center" gap="xs">
            <Icon
              name="kandji-blueprint"
              size="xs"
              style={{ color: 'var(--colors-neutral70)' }}
            />
            <Link
              href={`${links.blueprints}/${blueprint_id}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="subtle"
              css={{
                '&:hover': {
                  textDecorationColor: '$neutral70',
                },
              }}
            >
              <Text size="1" variant="secondary">
                {blueprint_name}
              </Text>
            </Link>
          </Flex>

          <VerticalDivider />

          <Flex
            alignItems="center"
            gap="xs"
            css={{ flexGrow: 1, overflow: 'hidden' }}
          >
            <Icon
              name="grid-2"
              size="xs"
              style={{ color: 'var(--colors-neutral70)' }}
            />
            <Text size="1" variant="secondary">
              {formatNumber(software_summary.length)}
            </Text>

            <Flex gap="xs">
              <Flex
                css={{
                  border: '1px solid $neutral30',
                  borderRadius: '$rounded-sm',
                  padding: '2px $1',
                  gap: '2px',
                }}
              >
                <SoftwareIcon
                  iconKey={software_summary[0].icon_key}
                  size="xs"
                />
                <Text
                  size="1"
                  variant="description"
                  css={{ fontWeight: '$medium' }}
                >
                  {software_summary[0].name} {software_summary[0].version}
                </Text>
              </Flex>
              {software_summary.length > 1 && (
                <Tooltip
                  side="top"
                  theme="light"
                  interactive
                  css={{
                    zIndex: 3,
                    padding: '$3 $4',
                    maxHeight: '190px',
                    maxWidth: 'auto',
                  }}
                  content={
                    <Flex flow="column" css={{ gap: '6px' }}>
                      {software_summary.slice(1).map((software) => (
                        <Flex gap="xs">
                          <SoftwareIcon iconKey={software.icon_key} size="xs" />
                          <Text>{`${software.name} ${software.version}`}</Text>
                        </Flex>
                      ))}
                    </Flex>
                  }
                >
                  <Flex
                    css={{
                      border: '1px solid $neutral30',
                      borderRadius: '$rounded-sm',
                      padding: '2px $1',
                      gap: '2px',
                    }}
                  >
                    <Text
                      size="1"
                      variant="description"
                      css={{ fontWeight: '$medium' }}
                    >
                      {i18n.t('+{count}', {
                        count: formatNumber(software_summary.length - 1),
                      })}
                    </Text>
                  </Flex>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {isExpanded && (
        <Flex flow="column" wFull css={{ padding: '20px $4 $4' }}>
          {/* First set of device paths shown by default */}
          {software_summary
            .slice(0, DEVICE_PATH_LIST_LIMIT)
            .map((software, idx) => {
              const isLastItem =
                idx ===
                Math.min(software_summary.length, DEVICE_PATH_LIST_LIMIT) - 1;
              return (
                <Flex wFull flow="column">
                  <DeviceSoftwareItem
                    software={software}
                    searchTerm={searchTerm}
                  />
                  {!isLastItem && (
                    <HorizontalDivider css={{ margin: '20px 0' }} />
                  )}
                </Flex>
              );
            })}

          {/* Show more/less button */}
          {software_summary.length > DEVICE_PATH_LIST_LIMIT && (
            <Flex
              flow="row"
              alignItems="center"
              css={{
                gap: '20px',
                marginTop: '20px',
                marginBottom: isShowingMore ? '20px' : '',
              }}
              ref={showLessButtonRef}
            >
              <HorizontalDivider />
              <Button
                variant="link"
                compact
                icon={{ name: 'arrows-to-line' }}
                onClick={() => setIsShowingMore(!isShowingMore)}
              >
                {isShowingMore
                  ? i18n.$t('Show less')
                  : i18n.$t('Show all ({total})', {
                      total: formatNumber(software_summary.length),
                    })}
              </Button>
              <HorizontalDivider />
            </Flex>
          )}

          {/* Items after the Show more/less button */}
          {software_summary.length > DEVICE_PATH_LIST_LIMIT &&
            isShowingMore &&
            software_summary
              .slice(DEVICE_PATH_LIST_LIMIT)
              .map((software, idx) => {
                const isLastItem =
                  idx + DEVICE_PATH_LIST_LIMIT === software_summary.length - 1;
                return (
                  <Flex wFull flow="column">
                    <DeviceSoftwareItem
                      software={software}
                      searchTerm={searchTerm}
                    />
                    {!isLastItem && (
                      <HorizontalDivider css={{ margin: '20px 0' }} />
                    )}
                  </Flex>
                );
              })}
        </Flex>
      )}

      {showArrow && (
        <Flex
          justifyContent="end"
          css={{ position: 'sticky', bottom: 60, height: 0, paddingRight: 53 }}
        >
          <Button
            icon={{ name: 'arrow-up-to-line' }}
            onClick={
              /* istanbul ignore next */ () => {
                if (scrollToRef.current) {
                  scrollToRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
              }
            }
            css={{ height: 36, marginTop: -50 }}
          />
        </Flex>
      )}
    </Card>
  );
};

export { DeviceCard };
