import { i18n } from 'i18n';
import constants from '../../../common/constants';
import type { ThreatDetail } from '../../../threat.types';
import type { ActionOption } from '../types';

const getRowActionOptions = (
  item: ThreatDetail,
  onRelease: (threat: ThreatDetail) => void,
  onRecheckStatus: (threat: ThreatDetail) => void,
  isThreatBeingChecked: boolean,
): ActionOption[] => {
  const options: Record<string, ActionOption> = {
    [constants.THREAT_STATUS.QUARANTINED]: {
      label: i18n.t('Release threat'),
      icon: 'square-arrow-up',
      onClick: /*istanbul ignore next */ () => onRelease(item),
    },
    [constants.THREAT_STATUS.NOT_QUARANTINED]: {
      label: i18n.t('Recheck status'),
      icon: 'arrows-rotate',
      onClick: /* istanbul ignore next */ () => {
        if (!isThreatBeingChecked) onRecheckStatus(item);
      },
      disabled: isThreatBeingChecked,
    },
  };

  const virusTotalOption: ActionOption = {
    label: i18n.t('Search VirusTotal'),
    icon: 'virus-total',
    onClick: /* istanbul ignore next -- Linking */ () =>
      window.open(`${constants.VIRUS_TOTAL_URL}${item.file_hash}`),
  };

  if (!(item.status in options)) {
    return [virusTotalOption];
  }

  return [options[item.status], virusTotalOption];
};

export default getRowActionOptions;
