import { blueprintLibraryCategories } from 'features/blueprints/common';
/* istanbul ignore file */
import { i18n } from 'i18n';
import {
  apiTypes,
  canAddLibraryTypes,
  categories,
  deviceTypes,
} from '../../common';
import LibraryItemConfig from './item-config.class';

const autoApps = {
  'Auto Apps': new LibraryItemConfig({
    getName: () => i18n.t('Auto Apps'),
    getDescription: () => '',
    name: 'Auto Apps',
    description: '',
    type: apiTypes.AUTO_APP,
    publisher: null,
    devices: [deviceTypes.MAC],
    osRequirements: [],
    category: categories.AUTO_APPS,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    icon: '',
    canAdd: canAddLibraryTypes.PREDEFINED_BY_BACKEND,
    identifier: '',
    routeType: 'automatic-apps',
    getUrl: ({ id }) => `/library/automatic-apps/${id}`,
    getAddUrl: ({ id }) => `/library/automatic-apps/${id}/add`,
    singleBlueprintAllowed: true,
  }),
};

export default autoApps;
