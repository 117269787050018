/* istanbul ignore file */
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { queryParamHistory } from 'src/app/_actions/parameters';

const useParamHistory = (
  parameterId: string,
  computerId: string,
  options: Record<string, any> = {
    displayType: 'LAST_STATE',
    ordering: '-run',
  },
  isEnabled = true,
  queryOptions?: Partial<UseQueryOptions>,
) =>
  useQuery({
    queryKey: ['parameter-history', parameterId, computerId, options],
    queryFn: () =>
      queryParamHistory({
        parameterid: parameterId,
        computerid: computerId,
        ...options,
      }),
    enabled: isEnabled,
    ...queryOptions,
  });

export default useParamHistory;
