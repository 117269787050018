import { Box, Flex, Heading, Loader, Tooltip } from '@kandji-inc/nectar-ui';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { DataTable } from 'src/components';
import { Pagination, usePagination } from 'src/components/ui';
import { InterfaceContext } from 'src/contexts/interface';
import useAdjustSidebarChatBubble from 'src/features/util/hooks/use-adjust-sidebar-chat-bubble';
import TableHeaderIconButton from 'src/features/visibility/prism/components/PrismTable/components/TableHeaderIconButton';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import { i18n } from 'src/i18n';
import { useShallow } from 'zustand/react/shallow';
import { NoResultsFound } from '../common/table/no-results-found';
import { NoVulnerabilities } from '../common/table/no-vulnerabilities';
import { getColumns } from '../common/table/table-columns';
import { useGetVulnerabilities } from '../hooks/use-get-vulnerabilities';
import { useVulnerabilitySort } from '../hooks/use-vulnerability-sort';
import useVulnerability from '../store';
import { Dashboard } from './dashboard';
import { VulnerabilitiesTableExportButton } from './export-button';
import { FilterBar } from './filter-bar';
import { VulnerabilitySidePanel } from './side-panel';
import { TableFilterBar } from './table-filter-bar';
import { transformAllVulnerabilitiesFilterToApi } from './transformers/transformToApi';

const VulnerabilitiesTable = () => {
  useWhiteBackground();

  useAdjustSidebarChatBubble();
  const { paginationState: pagination, setPagination } = usePagination();
  const { sidebarDocked, bannerTopOffset } = useContext(InterfaceContext);
  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;

  const columns = getColumns({ isOnDeviceRecord: false });

  const [selectedVulnerabilityId, setSelectedVulnerabilityId, filter] =
    useVulnerability(
      useShallow((state) => [
        state.selectedVulnerabilityId,
        state.setSelectedVulnerabilityId,
        state.allVulnerabilitiesFilter,
      ]),
    );

  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const dashboardOffset = isTableExpanded ? 0 : 206;

  const history = useHistory();
  const location = useLocation();

  const onRowClick = /* istanbul ignore next */ (row) => {
    setSelectedVulnerabilityId(row.cve_id);

    history.push({
      pathname: history.location.pathname,
      search: `?cve_id=${row.cve_id}`,
    });
  };

  useEffect(
    /* istanbul ignore next */ () => {
      const searchParams = new URLSearchParams(location.search);
      const cveId = searchParams.get('cve_id');

      if (cveId) {
        setSelectedVulnerabilityId(cveId);
      }
    },
    [location.search],
  );

  const { sort, sortBy, handleSort } = useVulnerabilitySort();

  const { vulnerabilities, count, isLoading } = useGetVulnerabilities(
    [pagination, sortBy, filter],
    {
      size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      sort_by: sortBy,
      filter: transformAllVulnerabilitiesFilterToApi(filter),
      search: filter.term,
    },
  );

  const hasAtLeastOneNonDateFilterApplied =
    filter?.term || filter?.severity?.length > 0;
  const hasVulnerabilities = vulnerabilities?.length > 0;
  const hasNoVulnerabilities = !isLoading && !hasVulnerabilities;

  return (
    <Box
      hFull
      css={{
        overflow: 'hidden',
        margin: '0 calc(var(--k-main-layout-side-padding) * -1) -48px',
      }}
    >
      <Heading size="1" css={{ padding: '$4 $5 $2' }}>
        {i18n.t('Vulnerabilities')}
      </Heading>

      <Box wFull mt2 mb2 css={{ height: 1, backgroundColor: '$neutral20' }} />

      <FilterBar />

      <Dashboard isOpen={!isTableExpanded} />

      <Flex
        flow="row"
        alignItems="center"
        justifyContent="space-between"
        pl3
        pr3
      >
        <TableFilterBar />

        <Flex>
          <Tooltip
            content={i18n.t('{action} table', {
              action: isTableExpanded ? i18n.t('Collapse') : i18n.t('Expand'),
            })}
            side="top"
          >
            <TableHeaderIconButton
              role="button"
              name={i18n.t('collapse-expand-table')}
              onClick={() => setIsTableExpanded(!isTableExpanded)}
              icon={
                isTableExpanded
                  ? 'arrow-down-left-and-arrow-up-right-to-center'
                  : 'arrow-up-right-and-arrow-down-left-from-center'
              }
            />
          </Tooltip>
          <VulnerabilitiesTableExportButton filter={filter} />
        </Flex>
      </Flex>

      <Box
        css={{
          height: `calc(100vh - 193px - ${bannerTopOffset}px - ${dashboardOffset}px)`,

          // Provide extra space at the bottom of the scroll container so that
          // the ellipsis menus don't get overlapped by the chat bubble
          ':where(table)': {
            marginBottom: hasVulnerabilities ? '120px' : '0',
            pointerEvents: selectedVulnerabilityId ? 'none' : 'auto',
          },

          // since we have space between last row and the bottom of the
          // scroll container, we must manually add a bottom border
          ':where(tbody) tr:last-of-type': {
            borderBottom: '1px solid $neutral30',
          },

          // Apply margin between left edge of the table and the first column header
          ':where(thead) th:first-of-type': {
            paddingLeft: '36px',
          },

          // Apply margin between left edge of the table and the first column data cell
          ':where(tbody) td:first-of-type': {
            paddingLeft: '36px',
          },

          // Ensure ellipsis menu does not increase the height of each row
          ':where(tbody) td:last-of-type': {
            padding: 0,
          },
        }}
      >
        <DataTable
          // @ts-ignore - type Vulnerability is specified which is more clear that the expected `any` type
          columns={columns}
          data={vulnerabilities}
          pinnedColumns={[]}
          offsets={{}}
          onRowClick={onRowClick}
          sort={{ sortState: sort, setSortState: handleSort }}
          selectionModel={{
            selection: [selectedVulnerabilityId],
            hideSelectionColumn: true,
          }}
          css={isLoading || hasNoVulnerabilities ? { maxHeight: '36px' } : {}}
        />

        {/* Account for the height of the table header */}
        <Box css={{ height: 'calc(100% - 37px)' }}>
          {isLoading && (
            <Flex hFull alignItems="center" justifyContent="center">
              <Loader size="lg" data-testid="loader" />
            </Flex>
          )}

          {hasNoVulnerabilities && hasAtLeastOneNonDateFilterApplied && (
            <NoResultsFound />
          )}

          {hasNoVulnerabilities && !hasAtLeastOneNonDateFilterApplied && (
            <NoVulnerabilities
              lastDetectedFilter={filter.latestDetected}
              isOnDeviceRecord={false}
            />
          )}
        </Box>
      </Box>

      <Box
        css={{
          position: 'fixed',
          bottom: 0,
          padding: '$3 $5',
          borderTop: '1px solid $neutral20',
          backgroundColor: '$neutral0',
          width: sidebarDocked
            ? `calc(100% - ${SIDEBAR_DOCKED_OFFSET}px)`
            : `calc(100% - ${SIDEBAR_CLOSE_OFFSET}px)`,
        }}
      >
        <Pagination
          currentPage={pagination.pageIndex + 1}
          totalItems={count}
          itemsPerPage={pagination.pageSize}
          onPageChange={
            /* istanbul ignore next */ (page) =>
              setPagination({ ...pagination, pageIndex: page - 1 })
          }
          onItemsPerPageChange={
            /*  istanbul ignore next */ (itemsPerPage) => {
              setPagination({
                ...pagination,
                pageIndex: 0,
                pageSize: itemsPerPage,
              });
            }
          }
        />
      </Box>

      {selectedVulnerabilityId && !isLoading && <VulnerabilitySidePanel />}
    </Box>
  );
};

export { VulnerabilitiesTable };
