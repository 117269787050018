/* istanbul ignore file */
import { i18n } from 'i18n';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { type AllVulnerabilitiesFilter, Severity } from './vulnerability.types';

export const severityColorMap = {
  critical: '$red80',
  high: '$red50',
  medium: '$yellow60',
  low: '$yellow50',
  none: '$green50',
};

export const translatedSeverity = {
  [Severity.critical]: () => i18n.t('Critical'),
  [Severity.high]: () => i18n.t('High'),
  [Severity.medium]: () => i18n.t('Medium'),
  [Severity.low]: () => i18n.t('Low'),
  [Severity.none]: () => i18n.t('None'),
};

export const translatedSeverityRanges = {
  noneBottom: () => i18n.format.number(0.0),
  lowBottom: () => i18n.format.number(0.1),
  lowTop: () => i18n.format.number(3.9),
  mediumBottom: () => i18n.format.number(4.0),
  mediumTop: () => i18n.format.number(6.9),
  highBottom: () => i18n.format.number(7.0),
  highTop: () => i18n.format.number(8.9),
  criticalBottom: () => i18n.format.number(9.0),
  criticalTop: () => i18n.format.number(10.0),
};

export const DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS: {
  getLabel: () => string;
  getInlineLabel?: () => string;
  value: string;
}[] = [
  {
    getLabel: () => i18n.t('Last 24 hours'),
    getInlineLabel: () => i18n.t('last 24 hours'),
    value: '24hours',
  },
  {
    getLabel: () => i18n.t('Last 7 days'),
    getInlineLabel: () => i18n.t('last 7 days'),
    value: '7days',
  },
  {
    getLabel: () => i18n.t('Last 30 days'),
    getInlineLabel: () => i18n.t('last 30 days'),
    value: '30days',
  },
  {
    getLabel: () => i18n.t('Last 60 days'),
    getInlineLabel: () => i18n.t('last 60 days'),
    value: '60days',
  },
  {
    getLabel: () => i18n.t('Last 90 days'),
    getInlineLabel: () => i18n.t('last 90 days'),
    value: '90days',
  },
  {
    getLabel: () => i18n.t('All time'),
    value: 'all_time',
  },
  {
    getLabel: () => i18n.t('Custom date range'),
    value: 'custom_date_range',
  },
] as const;

export const GLOBAL_FILTER_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS: {
  getLabel: () => string;
  getInlineLabel?: () => string;
  value: string;
}[] = [
  {
    getLabel: () => i18n.t('All active CVEs'),
    value: 'all_time',
  },
  ...DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS.filter(
    (option) => option.value !== 'all_time',
  ),
];

export const DEFAULT_DETECTION_DATE_FILTER = { value: 'all_time' };

export const defaultDateFilter: DetectionDateFilterFields = {
  value: '',
  operator: '',
  dateRangeFrom: '',
  dateRangeTo: '',
  selectedDate: '',
};

export const defaultAllVulnerabilitiesFilter: AllVulnerabilitiesFilter = {
  term: '',
  severity: [],
  latestDetected: DEFAULT_DETECTION_DATE_FILTER,
  softwareName: {
    appName: [],
    osName: [],
  },
};

export const defaultDeviceVulnerabilitiesFilter = {
  term: '',
  severity: [],
  detectionDate: DEFAULT_DETECTION_DATE_FILTER,
};
