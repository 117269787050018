import { Flex, styled } from '@kandji-inc/nectar-ui';
import { ExploitabilityScoreTile } from './tiles/exploitability-score-tile';
import { TopSoftwareWithVulnerabilitiesTile } from './tiles/top-software-with-vulnerabilities-tile';
import { VulnerabilitiesBySeverityTile } from './tiles/vulnerabilities-by-severity-tile';

const Container = styled(Flex, {
  maxHeight: '218px',
  overflow: 'hidden',
  transition: 'max-height 0.5s',

  '&.hidden': {
    maxHeight: 0,
    margin: 0,
  },
});

type DashboardProps = {
  isOpen: boolean;
};

const Dashboard = (props: DashboardProps) => {
  return (
    <Container
      pl5
      pr5
      gap="md"
      flow="column"
      className={props.isOpen ? '' : 'hidden'}
    >
      <Flex flow="row" gap="md" css={{ minHeight: '218px' }}>
        <VulnerabilitiesBySeverityTile />
        <ExploitabilityScoreTile />
        <TopSoftwareWithVulnerabilitiesTile />
      </Flex>
    </Container>
  );
};

export { Dashboard };
