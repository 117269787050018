import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { transformDetectionDateFilterToApi } from '../../helpers';
import type { VulnerabilityState } from '../../store';
import type {
  AllVulnerabilitiesFilter,
  AllVulnerabilitiesRequestSchema,
  DeviceVulnerabilitiesRequestSchema,
  VulnerableDevicesRequestSchema,
  VulnerableSoftwareRequestSchema,
} from '../../vulnerability.types';

export const transformAllVulnerabilitiesFilterToApi = (
  filter: Partial<AllVulnerabilitiesFilter>,
): AllVulnerabilitiesRequestSchema['filter'] => {
  const { severity, latestDetected, softwareName } = filter;

  return {
    severity: { in: severity?.length ? severity : null },
    vulnerable_software: {
      in:
        softwareName?.appName?.length || softwareName?.osName?.length
          ? [...softwareName.appName, ...softwareName.osName]
          : null,
    },
    latest_detection_date: transformDetectionDateFilterToApi(latestDetected),
  };
};

export const transformDeviceVulnerabilitiesFilterToApi = (
  filter: VulnerabilityState['deviceVulnerabilitiesFilter'],
): DeviceVulnerabilitiesRequestSchema['filter'] => {
  const { severity, detectionDate } = filter;

  return {
    severity: { in: severity.length ? severity : null },
    detection_datetime: transformDetectionDateFilterToApi(detectionDate),
  };
};

export const transformVulnerableDevicesFilterToApi =
  /* istanbul ignore next */ (filter: {
    detectionDate: DetectionDateFilterFields;
    blueprints: string[];
  }): VulnerableDevicesRequestSchema['filter'] => {
    const { detectionDate, blueprints } = filter;

    return {
      detection_datetime: transformDetectionDateFilterToApi(detectionDate),
      blueprint_id: { in: blueprints.length ? blueprints : null },
    };
  };

export const transformVulnerableSoftwareFilterToApi =
  /* istanbul ignore next */ (filter: {
    latestDetectionDate: DetectionDateFilterFields;
    blueprints: string[];
  }): VulnerableSoftwareRequestSchema['filter'] => {
    const { latestDetectionDate, blueprints } = filter;

    return {
      latest_detection_date:
        transformDetectionDateFilterToApi(latestDetectionDate),
      blueprint_id: { in: blueprints.length ? blueprints : null },
    };
  };
