import { useState } from 'react';
import { ExportButton } from 'src/components/export';
import { i18n } from 'src/i18n';
import type { VulnerabilityState } from '../../store';
import type {
  AllVulnerabilitiesFilter,
  ExportAllVulnerabilitiesRequestSchema,
} from '../../vulnerability.types';
import { transformAllVulnerabilitiesFilterToApi } from '../transformers/transformToApi';

type VulnerabilitiesTableExportButtonProps = {
  filter: AllVulnerabilitiesFilter;
};

const VulnerabilitiesTableExportButton = (
  props: VulnerabilitiesTableExportButtonProps,
) => {
  const { filter } = props;
  const [exportType, setExportType] = useState('current_view');

  return (
    <ExportButton
      title={i18n.t('Export Vulnerabilities to CSV')}
      exportTypeOptions={[
        { type: 'current_view', label: i18n.t('Current View') },
        {
          type: 'all_vulnerabilities',
          label: i18n.t('All Vulnerabilities'),
        },
      ]}
      helpText={getHelpText(exportType)}
      onExport={() => handleExportPayload({ exportType, filter })}
      exportType={exportType}
      setExportType={setExportType}
      exportDataType="vulnerabilities"
    />
  );
};

const handleExportPayload = ({
  exportType,
  filter,
}: {
  exportType: string;
  filter: VulnerabilityState['allVulnerabilitiesFilter'];
}) => {
  const payload: ExportAllVulnerabilitiesRequestSchema =
    exportType === 'current_view'
      ? { filter: transformAllVulnerabilitiesFilterToApi(filter) }
      : {
          filter: transformAllVulnerabilitiesFilterToApi({
            severity: null,
            latestDetected: null,
            softwareName: null,
          }),
        };

  return payload;
};

const getHelpText = (exportType: string) => {
  if (exportType === 'current_view') {
    return i18n.ut(
      'CSV will <strong>only</strong> include data that is currently visible in the table, and contain any applied filters.',
    );
  }
  return i18n.ut(
    'CSV will include <strong>all</strong> data for this category, including data that is not currently visible in the table.',
  );
};

export { VulnerabilitiesTableExportButton };
