import {
  Button,
  DialogPrimitives as Dialog,
  Flex,
  Icon,
  Separator,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import deviceImagesMap from 'components/common/image-device/map';
import { getItemConfig as getLibraryItemConfig } from 'features/blueprint-flow/helpers';
import { paths } from 'features/blueprints/common';
import { default as integrationStrings } from 'features/integrations/generic-cards-view/cards-config';
import { getDeviceIconByFamily } from 'features/visibility/prism/utils/column-utils';
import { i18n } from 'i18n';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import { useDebouncedState } from 'src/hooks/useDebouncedState';
import {
  useCreateRecentSearch,
  useUniversalSearchQueries,
  useUniversalSearchRecents,
} from '../hooks/universal-search-queries';
import { NoResultsFoundIcon } from './NoResultsFoundIcon';

const COLLAPSED_TOTAL = 12;

const getDeviceIcon = (modelId: string) =>
  deviceImagesMap[modelId] ?? getDeviceIconByFamily(modelId);

const NavIcon = styled(Icon, {
  borderRadius: '2px',
  color: '$neutral0',
  backgroundColor: '$neutral50',
});

const NavText = styled(Text, {
  color: '$blue50',
  visibility: 'hidden',
  fontWeight: '$medium',
  fontSize: '$2',
});

const StyledSearchListItem = styled(Flex, {
  padding: '6px $5',
  gap: '$1',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: '$neutral0',
  '& [data-role="nav-icon"]': {
    color: '$neutral0',
  },
  '&[data-selected=true]': {
    backgroundColor: '$button_subtle_surface_hover',
    [`& ${NavIcon}`]: {
      color: '$blue50',
      backgroundColor: 'transparent',
    },
    [`& ${NavText}`]: {
      visibility: 'visible',
    },
  },
});

const ItemSummary = styled(Flex, {
  flexDirection: 'column',
  maxWidth: 'calc(100% - 210px)',
  overflow: 'hidden',
});

const LoadingIcon = styled('div', {
  height: 24,
  width: 24,
  backgroundColor: '$neutral05',
  borderRadius: '$rounded',
});

const LoadingBar = styled('div', {
  height: 24,
  backgroundColor: '$neutral05',
  borderRadius: '$rounded',
  variants: {
    size: {
      sm: {
        width: '30%',
      },
      md: {
        width: '50%',
      },
    },
  },
});

const ResourceSection = ({ title, children }) => {
  return (
    <Flex flow="column">
      <Flex css={{ padding: '6px $5', alignItems: 'center' }}>
        <Text size="1" weight="medium" variant="description">
          {title}
        </Text>
      </Flex>
      {children}
    </Flex>
  );
};

const DeviceListItem = ({ device, isHighlighted, onSelect, onHighlight }) => {
  const {
    asset_tag: assetTag,
    device__family: deviceFamily,
    device__name: deviceName,
    model_id: modelId,
    serial_number: serialNumber,
    device__user_name: userName,
    device__user_email: userEmail,
  } = device;
  const icon = getDeviceIcon(modelId);
  return (
    <StyledSearchListItem
      data-testid="device-list-item"
      data-selected={isHighlighted}
      css={{ paddingLeft: '20px' }}
      onClick={(e) =>
        onSelect({ record: device, newTab: e.metaKey, type: 'devices' })
      }
      onMouseMove={() => onHighlight(device)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <img
          data-testid="device_icon_image"
          height="32"
          width="32"
          src={icon}
          alt={deviceFamily}
        />
      </Flex>
      <ItemSummary>
        <Text css={{ fontWeight: '$medium', color: '$neutral90' }}>
          {deviceName}
        </Text>
        <Text css={{ fontSize: '$1', color: '$neutral70' }}>
          {modelId} - {serialNumber}
          {assetTag && ' - '}
          {assetTag}
        </Text>
        <Flex
          alignItems="center"
          css={{
            gap: '2px',
            maxHeight: '16px',
            overflowX: 'hidden',
            fontSize: '$1',
          }}
        >
          {userName && (
            <>
              <Flex css={{ flex: 'none' }}>
                <Icon size="xs" name="user" />
              </Flex>
              <Text css={{ flex: 'none', fontSize: '$1', color: '$neutral70' }}>
                {userName} {userEmail && ' - '}
              </Text>
            </>
          )}
          {userEmail && (
            <>
              <Flex css={{ flex: 'none' }}>
                <Icon size="xs" name="envelope" />
              </Flex>
              <Text css={{ flex: 'none', fontSize: '$1', color: '$neutral70' }}>
                {userEmail}
              </Text>
            </>
          )}
          {!userName && !userEmail && (
            <Text
              css={{ fontStyle: 'italic', fontSize: '$1', color: '$neutral70' }}
            >
              User not assigned
            </Text>
          )}
        </Flex>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Device record')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

const BlueprintListItem = ({
  blueprint,
  isHighlighted,
  onSelect,
  onHighlight,
}) => {
  return (
    <StyledSearchListItem
      data-testid="blueprint-list-item"
      onClick={(e) =>
        onSelect({ record: blueprint, newTab: e.metaKey, type: 'blueprints' })
      }
      data-selected={isHighlighted}
      onMouseMove={() => onHighlight(blueprint)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <Icon size={20} name="kandji-blueprint" />
      </Flex>
      <ItemSummary>
        <Text css={{ color: '$neutral90' }}>{blueprint.name}</Text>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Blueprint')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

const LibraryItemListItem = ({
  libraryItem,
  isHighlighted,
  onSelect,
  onHighlight,
}) => {
  const itemConfig = getLibraryItemConfig(libraryItem);
  const [icon, setIcon] = useState(libraryItem.icon);

  return (
    <StyledSearchListItem
      data-testid="library-list-item"
      onClick={(e) =>
        onSelect({
          record: libraryItem,
          newTab: e.metaKey,
          type: 'libraryItems',
        })
      }
      data-selected={isHighlighted}
      onMouseMove={() => onHighlight(libraryItem)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <img
          height="20"
          width="20"
          data-testid="device_icon_image"
          src={icon || itemConfig.icon}
          onError={() => setIcon(itemConfig.icon)}
          alt={libraryItem.name}
        />
      </Flex>
      <ItemSummary>
        <Text css={{ fontWeight: '$medium', color: '$neutral90' }}>
          {libraryItem.name}
        </Text>
        <Text variant="secondary" css={{ fontSize: '$1', color: '$neutral70' }}>
          {itemConfig.getName() || itemConfig.name}
          {libraryItem.instance_name ? ` - ${libraryItem.instance_name}` : ''}
        </Text>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Library Item')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

// istanbul ignore next
const IntegrationListItem = ({
  integration,
  isHighlighted,
  onSelect,
  onHighlight,
}) => {
  const { title, img } = integrationStrings[integration.type];
  const name =
    integration.category === 'Directory integrations'
      ? integration.name
      : title();
  const subtitle =
    integration.category === 'Directory integrations' ? title() : '';
  const separator = subtitle && integration.category ? ' - ' : '';
  return (
    <StyledSearchListItem
      data-testid="integration-list-item"
      onClick={(e) =>
        onSelect({
          record: integration,
          newTab: e.metaKey,
          type: 'integrations',
        })
      }
      data-selected={isHighlighted}
      onMouseMove={() => onHighlight(integration)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <img
          height="20"
          width="20"
          data-testid="device_icon_image"
          src={img}
          alt={integration.name}
        />
      </Flex>
      <ItemSummary>
        <Text css={{ fontWeight: '$medium', color: '$neutral90' }}>{name}</Text>
        <Text variant="secondary" css={{ fontSize: '$1', color: '$neutral70' }}>
          {`${subtitle}${separator}${integration.category ?? ''}`}
        </Text>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Integration')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

const EmptyScreen = ({ search }) => {
  const message = search
    ? i18n.t('No results found')
    : i18n.t('No recent search history ');
  return (
    <Flex
      css={{
        padding: '$5',
        gap: '$1',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      data-testid="empty-screen"
    >
      <Text css={{ fontWeight: '$medium', color: '$neutral90' }}>
        {message}
      </Text>
      {search === '' && (
        <Text Text variant="secondary">
          {i18n.t(
            'Search for a device, Library items, Blueprints or integrations.',
          )}
        </Text>
      )}
      <Flex pt3>
        <NoResultsFoundIcon />
      </Flex>
    </Flex>
  );
};

const RecentsSection = ({
  recents,
  onSelect,
  highlightedIndex,
  onHighlight,
}) => {
  return (
    <ResourceSection title={i18n.t('Recents')}>
      {recents.map((recent, index) => {
        if (recent.type === 'devices') {
          return (
            <DeviceListItem
              key={recent.record['device_information.device_id']}
              device={recent.record}
              onSelect={onSelect}
              isHighlighted={index === highlightedIndex}
              onHighlight={onHighlight}
            />
          );
        }
        if (recent.type === 'blueprints') {
          return (
            <BlueprintListItem
              key={recent.record.id}
              blueprint={recent.record}
              onSelect={onSelect}
              isHighlighted={index === highlightedIndex}
              onHighlight={onHighlight}
            />
          );
        }
        if (recent.type === 'libraryItems') {
          return (
            <LibraryItemListItem
              key={recent.record.id}
              libraryItem={recent.record}
              onSelect={onSelect}
              isHighlighted={index === highlightedIndex}
              onHighlight={onHighlight}
            />
          );
        }
        if (recent.type === 'integrations') {
          return (
            <IntegrationListItem
              key={recent.record.uuid}
              integration={recent.record}
              onSelect={onSelect}
              isHighlighted={index === highlightedIndex}
              onHighlight={onHighlight}
            />
          );
        }
      })}
    </ResourceSection>
  );
};

const LoadingItem = ({ size }: { size: 'sm' | 'md' }) => (
  <Flex gap="xs" css={{ padding: '6px $5', alignItems: 'center' }}>
    <LoadingIcon />
    <LoadingBar size={size} />
  </Flex>
);

const LoadingScreen = ({ length }) => (
  <Flex flow="column" gap="xs" data-testid="loading-screen">
    {Array.from({ length }).map((_, idx) => (
      <LoadingItem size={idx % 2 === 0 ? 'sm' : 'md'} />
    ))}
  </Flex>
);

export const UniversalSearchDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const history = useHistory();
  const [debouncedSearch, setSearch, search] = useDebouncedState<string>('');
  const [expandedSections, setExpandedSections] = useState({
    devices: false,
    blueprints: false,
    libraryItems: false,
    integrations: false,
  });
  const previousExpandedSections = useRef(expandedSections);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const { data, isPending: searchPending } = useUniversalSearchQueries({
    search,
  });
  const { data: recents, isPending: recentsPending } =
    useUniversalSearchRecents();
  const isPending = (search.trim() && searchPending) || recentsPending;
  const { mutate: createRecentSearch } = useCreateRecentSearch();
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      setSearch('');
    }
  }, [isOpen, setSearch]);

  useEffect(() => {
    setExpandedSections({
      devices: false,
      blueprints: false,
      libraryItems: false,
      integrations: false,
    });
    setHighlightedIndex(-1);
  }, [debouncedSearch]);

  // istanbul ignore next
  useEffect(() => {
    const collapsedSection = Object.keys(expandedSections).find((section) => {
      return (
        expandedSections[section] === false &&
        previousExpandedSections.current[section] === true
      );
    });
    if (collapsedSection) {
      setHighlightedRecord({ type: collapsedSection, record: 'expander' });
    }
    previousExpandedSections.current = expandedSections;
  }, [expandedSections]);

  // istanbul ignore next
  useEffect(() => {
    if (scrollRef.current) {
      const { top: contentTop, bottom: contentBottom } =
        scrollRef.current.getBoundingClientRect();
      const selectedEl = scrollRef.current.querySelector(
        '[data-selected=true]',
      );
      if (!selectedEl) {
        return;
      }
      const firstEl = scrollRef.current.querySelectorAll('[data-selected]')[0];
      if (firstEl === selectedEl) {
        scrollRef.current.scrollTo({ top: 0 });
        return;
      }

      const { top: selectedTop, bottom: selectedBottom } =
        selectedEl.getBoundingClientRect();

      if (selectedBottom > contentBottom) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollTop + selectedBottom - contentBottom,
        });
      } else if (selectedTop < contentTop) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollTop + selectedTop - contentTop,
        });
      }
    }
  }, [highlightedIndex]);

  const sectionData = useMemo(() => {
    const sections = {
      devices: { data: [], next: 'blueprints' },
      blueprints: { data: [], next: 'libraryItems' },
      libraryItems: { data: [], next: 'integrations' },
      integrations: { data: [], next: 'devices' },
    };
    if (search.trim() === '' || searchPending) {
      return sections;
    }
    let totalCount =
      data.devices.length +
      data.blueprints.length +
      data.libraryItems.length +
      data.integrations.length;
    let selectedCount = 0;
    let currentSection = 'devices';
    while (selectedCount < COLLAPSED_TOTAL && totalCount > 0) {
      const itemIdx = sections[currentSection].data.length;
      const item = data[currentSection][itemIdx];
      if (item != null) {
        sections[currentSection].data.push(item);
        selectedCount++;
        totalCount--;
      }
      currentSection = sections[currentSection].next;
    }
    Object.keys(expandedSections).forEach((section) => {
      if (expandedSections[section]) {
        sections[section].data = [...data[section]];
        sections[section].data.push({ type: section, record: 'expander' });
      } else if (data[section].length > sections[section].data.length) {
        sections[section].data.push({ type: section, record: 'expander' });
      }
    });
    return sections;
  }, [data, expandedSections, search, searchPending]);

  const onRecordClick = useCallback(
    ({ record, type, newTab }) => {
      let path = '';
      let idPath = '';
      switch (type) {
        case 'devices':
          idPath = 'device_information.device_id';
          path = `${links.devices}/${record[idPath]}`;
          break;
        case 'blueprints':
          idPath = 'id';
          path =
            record.type === 'flow'
              ? paths.flowBlueprint(record.id)
              : paths.blueprint(record.id);
          break;
        case 'libraryItems':
          idPath = 'id';
          path = record.getUrl({ id: record.id });
          break;
        case 'integrations':
          idPath = 'uuid';
          path = `/integrations/${record.type}`;
          break;
      }

      const recent = recents.find(
        (recent) =>
          record[idPath] === recent.record[idPath] && recent.type === type,
      );
      if (recent == null) {
        createRecentSearch({ resource_id: record[idPath], type });
      }
      if (newTab) {
        window.open(path, '_blank');
        return;
      }
      onClose();
      history.push(path);
    },
    [createRecentSearch, history, onClose, recents],
  );

  const getHighlightedRecord = useCallback(() => {
    if (search.trim() === '') {
      return recents[highlightedIndex];
    }
    let recordIndex = 0;
    let highlightedRecord = null;
    let highlightedRecordType = null;
    Object.keys(sectionData).forEach((section) => {
      sectionData[section].data.forEach((record) => {
        if (recordIndex === highlightedIndex) {
          highlightedRecord = record;
          highlightedRecordType = section;
        }
        recordIndex++;
      });
    });
    return { record: highlightedRecord, type: highlightedRecordType };
  }, [highlightedIndex, recents, search, sectionData]);

  const setHighlightedRecord = useCallback(
    (record) => {
      const allRecords =
        search.trim() === ''
          ? recents.map(({ record }) => record)
          : [
              ...sectionData.devices.data,
              ...sectionData.blueprints.data,
              ...sectionData.libraryItems.data,
              ...sectionData.integrations.data,
            ];
      setHighlightedIndex(
        allRecords.findIndex((r) => {
          // istanbul ignore next
          if (record.record === 'expander') {
            return r.type === record.type && r.record === 'expander';
          }
          return r === record;
        }),
      );
    },
    [recents, sectionData, search],
  );

  const Expander = useCallback(
    ({ section, onHighlight, isHighlighted }) => {
      const remainingCount =
        // need to add 1 since we have the expander in sectionData
        data[section].length - sectionData[section].data.length + 1;
      return (
        <Flex
          css={{
            alignItems: 'center',
            cursor: 'pointer',
            padding: '6px $5',
          }}
          data-testid="section-expander"
          data-selected={isHighlighted}
          onMouseMove={() => onHighlight(section)}
          onClick={() =>
            setExpandedSections((prev) => ({
              ...prev,
              [section]: !prev[section],
            }))
          }
        >
          <Button
            css={{ textDecoration: isHighlighted ? 'underline' : 'none' }}
            variant="link"
          >
            {expandedSections[section]
              ? i18n.t('See less')
              : i18n.t('See more ({count})', {
                  count: remainingCount,
                })}
          </Button>
        </Flex>
      );
    },
    [expandedSections, data, sectionData],
  );

  const totalResults =
    search.trim() === ''
      ? recents.length
      : sectionData.devices.data.length +
        sectionData.blueprints.data.length +
        sectionData.libraryItems.data.length +
        sectionData.integrations.data.length;

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Content
        hideClose
        animateOpen={false}
        data-testid="universal-search-dialog"
        onClose={onClose}
        onKeyDown={
          // istanbul ignore next
          (e) => {
            if (e.key === 'ArrowDown') {
              e.preventDefault();
              setHighlightedIndex((prev) =>
                prev === totalResults - 1 ? 0 : prev + 1,
              );
            } else if (e.key === 'ArrowUp') {
              e.preventDefault();
              setHighlightedIndex((prev) =>
                prev === 0 || prev === -1 ? totalResults - 1 : prev - 1,
              );
            } else if (e.key === 'Enter') {
              const { record, type } = getHighlightedRecord();
              if (record.record === 'expander') {
                setExpandedSections((prev) => ({
                  ...prev,
                  [type]: !prev[type],
                }));
              } else {
                onRecordClick({ record, type, newTab: e.metaKey });
              }
            } else if (e.key === 'Tab') {
              e.preventDefault();
              e.stopPropagation();
            }
          }
        }
        css={{
          maxWidth: '800px',
          width: '70%',
          minWidth: '400px',
          height: 'auto',
          maxHeight: 'calc(100vh - 180px)',
          padding: '$4 0',
          position: 'fixed',
          top: '90px',
          transform: 'translate(-50%, 0)',
          boxShadow: '$elevation3',
          border: '1px solid $neutral20',
          borderRadius: '$rounded-lg',
        }}
      >
        <TextField
          data-testid="universal-search-dialog-input"
          showClearButton={debouncedSearch.length > 0}
          onClear={() => setSearch('')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={i18n.t('Search for anything')}
          css={{ paddingLeft: '$5', paddingRight: '$5' }}
          componentCss={{
            input: { border: 'none', boxShadow: 'none !important' },
          }}
        />
        <Separator css={{ marginTop: '$3', marginBotton: '$1' }} />
        {search.trim() === '' && recents.length > 0 && !isPending && (
          <RecentsSection
            recents={recents}
            onSelect={onRecordClick}
            highlightedIndex={highlightedIndex}
            onHighlight={setHighlightedRecord}
          />
        )}
        {search.trim() === '' && recents.length === 0 && !isPending && (
          <EmptyScreen search={debouncedSearch} />
        )}
        {isPending && <LoadingScreen length={6} />}
        {search.trim() && !searchPending && totalResults === 0 && (
          <EmptyScreen search={debouncedSearch} />
        )}
        {search.trim() && !searchPending && totalResults !== 0 && (
          <Flex
            ref={scrollRef}
            gap="md"
            flow="column"
            css={{ overflowY: 'scroll' }}
          >
            <Flex flow="column">
              {sectionData.devices.data.length > 0 && (
                <ResourceSection title={i18n.t('Devices')}>
                  {sectionData.devices.data.map((device) =>
                    // istanbul ignore next
                    device.record === 'expander' ? (
                      <Expander
                        section="devices"
                        isHighlighted={
                          getHighlightedRecord().type === 'devices' &&
                          getHighlightedRecord().record.record === 'expander'
                        }
                        onHighlight={() =>
                          setHighlightedRecord({
                            type: 'devices',
                            record: 'expander',
                          })
                        }
                      />
                    ) : (
                      <DeviceListItem
                        key={device['device_information.device_id']}
                        device={device}
                        onSelect={onRecordClick}
                        isHighlighted={getHighlightedRecord().record === device}
                        onHighlight={setHighlightedRecord}
                      />
                    ),
                  )}
                </ResourceSection>
              )}
              {sectionData.blueprints.data.length > 0 && (
                <ResourceSection title={i18n.t('Blueprints')}>
                  {sectionData.blueprints.data.map((blueprint) =>
                    // istanbul ignore next
                    blueprint.record === 'expander' ? (
                      <Expander
                        section="blueprints"
                        isHighlighted={
                          getHighlightedRecord().type === 'blueprints' &&
                          getHighlightedRecord().record.record === 'expander'
                        }
                        onHighlight={() =>
                          setHighlightedRecord({
                            type: 'blueprints',
                            record: 'expander',
                          })
                        }
                      />
                    ) : (
                      <BlueprintListItem
                        key={blueprint.id}
                        blueprint={blueprint}
                        onSelect={onRecordClick}
                        isHighlighted={
                          getHighlightedRecord().record === blueprint
                        }
                        onHighlight={setHighlightedRecord}
                      />
                    ),
                  )}
                </ResourceSection>
              )}
              {sectionData.libraryItems.data.length > 0 && (
                <ResourceSection title={i18n.t('Library Items')}>
                  {sectionData.libraryItems.data.map((libraryItem) =>
                    // istanbul ignore next
                    libraryItem.record === 'expander' ? (
                      <Expander
                        section="libraryItems"
                        isHighlighted={
                          getHighlightedRecord().type === 'libraryItems' &&
                          getHighlightedRecord().record.record === 'expander'
                        }
                        onHighlight={() =>
                          setHighlightedRecord({
                            type: 'libraryItems',
                            record: 'expander',
                          })
                        }
                      />
                    ) : (
                      <LibraryItemListItem
                        key={libraryItem.id}
                        libraryItem={libraryItem}
                        onSelect={onRecordClick}
                        isHighlighted={
                          getHighlightedRecord().record === libraryItem
                        }
                        onHighlight={setHighlightedRecord}
                      />
                    ),
                  )}
                </ResourceSection>
              )}
              {sectionData.integrations.data.length > 0 && (
                <ResourceSection title={i18n.t('Integrations')}>
                  {sectionData.integrations.data.map((integration) =>
                    integration.record === 'expander' ? (
                      <Expander
                        section="integrations"
                        isHighlighted={
                          getHighlightedRecord().type === 'integrations' &&
                          getHighlightedRecord().record.record === 'expander'
                        }
                        onHighlight={
                          // istanbul ignore next
                          () =>
                            setHighlightedRecord({
                              type: 'integrations',
                              record: 'expander',
                            })
                        }
                      />
                    ) : (
                      <IntegrationListItem
                        key={integration.uuid}
                        integration={integration}
                        onSelect={onRecordClick}
                        isHighlighted={
                          getHighlightedRecord().record === integration
                        }
                        onHighlight={setHighlightedRecord}
                      />
                    ),
                  )}
                </ResourceSection>
              )}
            </Flex>
          </Flex>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};
