/* istanbul ignore file */
import { useEffect } from 'react';
import { libraryItemService } from 'src/features/library-items/data-service/library-item/library-item-service';
import { useShallow } from 'zustand/react/shallow';
import useVulnerability from '../store';

const useFetchIcons = () => {
  const [iconMap, setIconMap] = useVulnerability(
    useShallow((state) => [state.iconMap, state.setIconMap]),
  );

  const transformResults = (results) =>
    (results ?? []).reduce((acc, item) => {
      // Auto Apps use Bundle IDs to retrieve icons (ex. 'com.google.Chrome'). OS versions use their name (ex. 'macOS 11 Big Sur').
      const uniqueID = item.data?.bundle_identifiers || item.name;
      acc[uniqueID] = item.data?.icon_url || null;
      return acc;
    }, {});

  useEffect(() => {
    // If the iconMap doesn't exist, fetch the icons and create it
    if (!iconMap) {
      libraryItemService
        .find({ type: 'managed-library' })
        .then((r) => setIconMap(transformResults(r.data?.results)));
    }
  }, []);
};

export { useFetchIcons };
