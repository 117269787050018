// istanbul ignore file
import {
  Button,
  DropdownMenuPrimitives as DropdownMenu,
  Flex,
  IconButton,
  TextArea,
  Tooltip,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';
import { useRateChatMessageMutation } from '../ai-queries';
import { ThumbsDownIcon, ThumbsUpIcon } from './feedback-icons';

export const ChatFeedback = ({
  onOpenChange,
  chatSessionId,
  messageId,
  isLastBotMessage,
}: {
  onOpenChange: (isOpen: boolean) => void;
  chatSessionId: string;
  messageId: string;
  isLastBotMessage: boolean;
}) => {
  const [isPositiveFeedbackOpen, setIsPositiveFeedbackOpen] =
    React.useState(false);
  const [isNegativeFeedbackOpen, setIsNegativeFeedbackOpen] =
    React.useState(false);

  const { mutateAsync: rateChatMessage, isPending } =
    useRateChatMessageMutation(chatSessionId, messageId);

  const handleFeedback = async (
    feedback: 'positive' | 'negative',
    message?: string | undefined,
  ) => {
    try {
      await rateChatMessage({
        liked: feedback === 'positive',
        optional_feedback: message,
      });
      setIsPositiveFeedbackOpen(false);
      setIsNegativeFeedbackOpen(false);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  React.useEffect(() => {
    onOpenChange(isPositiveFeedbackOpen || isNegativeFeedbackOpen);
  }, [isPositiveFeedbackOpen, isNegativeFeedbackOpen, onOpenChange]);

  return (
    <Flex justifyContent="center" alignItems="center">
      <FeedbackDropdown
        feedback="positive"
        isOpen={isPositiveFeedbackOpen}
        setIsOpen={setIsPositiveFeedbackOpen}
        isLastBotMessage={isLastBotMessage}
        handleFeedback={handleFeedback}
        isPending={isPending}
      />
      <FeedbackDropdown
        feedback="negative"
        isOpen={isNegativeFeedbackOpen}
        setIsOpen={setIsNegativeFeedbackOpen}
        isLastBotMessage={isLastBotMessage}
        handleFeedback={handleFeedback}
        isPending={isPending}
      />
    </Flex>
  );
};

const FeedbackDropdown = ({
  feedback,
  isOpen,
  setIsOpen,
  isLastBotMessage,
  handleFeedback,
  isPending,
}: {
  feedback: 'positive' | 'negative';
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isLastBotMessage: boolean;
  handleFeedback: (feedback: 'positive' | 'negative', message?: string) => void;
  isPending: boolean;
}) => (
  <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
    <Tooltip
      content={i18n.t('Share {feedback} feedback', { feedback })}
      side="right"
    >
      <DropdownMenu.Trigger asChild>
        <FeedbackIconButton
          feedback={feedback}
          open={isOpen}
          aria-label={i18n.t('Share {feedback} feedback', { feedback })}
          data-testid={`kai-feedback-button-${feedback}`}
        />
      </DropdownMenu.Trigger>
    </Tooltip>
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        css={{ zIndex: 1000, marginTop: '$2' }}
        side={isLastBotMessage ? 'top' : 'bottom'}
      >
        <FeedbackDropdownContent
          feedback={feedback}
          onFeedback={handleFeedback}
          isPending={isPending}
        />
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
);

const FeedbackDropdownContent = ({
  feedback,
  onFeedback,
  isPending,
}: {
  feedback: 'positive' | 'negative';
  onFeedback: (feedback: 'positive' | 'negative', message?: string) => void;
  isPending: boolean;
}) => {
  const [message, setMessage] = React.useState('');
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFeedback(feedback, message);
      }}
    >
      <Flex flow="column" gap="sm" css={{ padding: '$3' }}>
        <label
          htmlFor={`feedback-textarea-${feedback}`}
          style={{ display: 'none' }}
        >
          {i18n.t(
            'Tell us about your experience. How could we improve? (optional)',
          )}
        </label>
        <TextArea
          id={`feedback-textarea-${feedback}`}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={i18n.t(
            'Tell us about your experience. How could we improve? (optional)',
          )}
          css={{ width: '320px', '& textarea': { minHeight: '120px' } }}
        />
        <Button
          type="submit"
          variant="primary"
          css={{ alignSelf: 'flex-end' }}
          disabled={isPending}
        >
          {i18n.t('Share feedback')}
        </Button>
      </Flex>
    </form>
  );
};

const FeedbackIconButton = React.forwardRef<
  HTMLButtonElement,
  { feedback: 'positive' | 'negative'; open: boolean }
>(({ feedback, open, ...props }, ref) => {
  return (
    <IconButton
      {...props}
      ref={ref}
      icon={feedback === 'positive' ? <ThumbsUpIcon /> : <ThumbsDownIcon />}
      variant="subtle"
      compact
      css={{
        backgroundColor: open ? '$button_subtle_surface_active' : 'transparent',
      }}
    />
  );
});
