// istanbul ignore file
import { ThreatService } from '../../../../data-service';
import ThreatsGroupMitreTechniquesSchema from '../../../schemas/ThreatsGroupMitreTechniquesSchema';

const threatService = new ThreatService();

const mitreTechniquesQuery = async () => {
  const result = await threatService.getMitreTechniques();

  if (!ThreatsGroupMitreTechniquesSchema.safeParse(result?.data).success) {
    throw new Error('Invalid schema');
  }

  return result;
};

export default mitreTechniquesQuery;
