import { Flex, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import type { SortState } from 'src/components';
import { Pagination } from 'src/components/ui';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import ThreatListErrorBoundary from '../common/components/ThreatListErrorBoundary';
import ThreatListLoader from '../common/components/ThreatListLoader';
import ThreatListReleaseModal from '../common/components/ThreatListReleaseModal';
import ThreatListSuspense from '../common/components/ThreatListSuspense';
import ThreatListEmptyStates from '../common/components/ThreatListTable/ThreatListEmptyStates';
import constants from '../common/constants';
import useFileStatusPolling from '../common/hooks/use-file-status-polling';
import useGetBehavioralThreats from '../common/hooks/use-get-behavioral-threats/use-get-behavioral-threats';
import toRequestFormat from '../common/hooks/use-get-threats/toRequestFormat';
import useGetThreats from '../common/hooks/use-get-threats/use-get-threats';
import useStateParams from '../common/hooks/use-state-params';
import downloadCSVFile from '../common/utils/download-csv-file';
import getDisplayDetectionPeriod from '../common/utils/get-display-detection-period';
import { ThreatService } from '../data-service';
import type {
  BehavioralThreatDetail,
  DetectionTypeSelectorState,
  FileStatus,
  FilterFields,
  FiltersState,
  PageSize,
  PaginationState,
  ShowToast,
  SortColumnDirection,
  SortColumnName,
  SortColumnState,
  ThreatClassification,
  ThreatDetail,
  ThreatStatus,
} from '../threat.types';
import ThreatListFilters from './ThreatListFilters';
import ThreatListSplitView from './ThreatListSplitView/ThreatListSplitView';
import ThreatListSplitViewPM from './ThreatListSplitViewPM/ThreatListSplitViewPM';
import ThreatListTable from './ThreatListTable/ThreatListTable';
import {
  createPaginationParamConfig,
  defaultFilters,
  filtersParamConfig,
  sortParamConfig,
} from './params/params';

const Container = styled(Flex, {
  flow: 'column',
  flex: 1,
  mx: '-$5',
  overflow: 'hidden',
});

const ContentLayout = styled(Flex, {
  height: 'calc(100vh - 206px)',
  flexDirection: 'column',
  position: 'relative',
});

const FiltersContainer = styled(Flex, {
  zIndex: 3,
  flow: 'row',
  gap: '$4',
  py: '$3',
  px: '$5',
  alignItems: 'center',
});

const TableContainer = styled(Flex, {
  backgroundColor: 'rgba(255,255,255,0.85)',
  height: 'calc(100% - 188px)',
  position: 'absolute',
  width: '100%',
  top: 128,
  minHeight: 250,
});

const PaginationContainer = styled(Flex, {
  boxShadow:
    '$shadows$inset_border_bottom_1 $colors$neutral0, $shadows$inset_border_top_1 $colors$neutral30',
  padding: '$3 $5',
  backgroundColor: '$neutral0',
  zIndex: 3,
});

const ThreatListSplitViewContainer = styled(Flex, {
  height: 'calc(100vh - 205px)',
});

const errorBoundaryTableCss = {
  flex: 1,
  minHeight: 400,
};

type ThreatListEventsProps = Readonly<{
  deviceId?: string | undefined;
  showToast: ShowToast;
  showChartTiles?: boolean | undefined;
  isTableScrollable?: boolean | undefined;
}>;

const ThreatListEvents = (props: ThreatListEventsProps) => {
  const { deviceId, showToast } = props;
  const threatService = useMemo(() => new ThreatService(), []);
  const [threatToRelease, setSelectedThreatForRelease] = useState<
    ThreatDetail | undefined
  >();
  const [selectedForSplitView, setSelectedForSplitView] = useState<
    | {
        threat: ThreatDetail | BehavioralThreatDetail;
        isFileDetectionType: boolean;
      }
    | undefined
  >();

  const isThreatSelectedForSplitView = Boolean(selectedForSplitView?.threat);

  const { paginationConfig, updateStoredPageSize } =
    createPaginationParamConfig('threatListEventsPaginationPageSize');

  const {
    filters,
    setFilters,
    pagination,
    setPagination,
    sort,
    setSort,
    isDefaultFilterState,
  } = useStateParams<FiltersState, PaginationState, SortColumnState>(
    filtersParamConfig,
    paginationConfig,
    sortParamConfig,
    defaultFilters,
  );

  const isFileDetectionType = Boolean(
    filters.detectionType === constants.THREAT_DETECTION_TYPE.FILE ||
      filters.detectionType === undefined,
  );

  const {
    data: dataFileThreats,
    isLoading: isLoadingFileThreats,
    isFetching: isFetchingFileThreats,
    isError: isErrorFetchingFileThreats,
    error: errorFetchingFileThreats,
    refetch: refetchThreats,
  } = useGetThreats(filters, sort, pagination, deviceId, isFileDetectionType);

  const {
    data: dataBehavioralThreats,
    isLoading: isLoadingBehavioralThreats,
    isError: isErrorFetchingBehavioralThreats,
    error: errorFetchingBehavioralThreats,
  } = useGetBehavioralThreats(
    filters,
    sort,
    pagination,
    deviceId,
    !isFileDetectionType,
  );

  // TODO: Move this logic away from the main component
  const dataBehavioralThreatsParsed = useMemo(
    () => ({
      results: dataBehavioralThreats?.detections.map((item) => ({
        ...item,
        file_hash: item.threat_id,
        process_name: item.process,
        status: item.threat_status,
      })),
      malware_count: undefined,
      pup_count: undefined,
      ...dataBehavioralThreats,
    }),
    [dataBehavioralThreats],
  );

  const dataThreats = isFileDetectionType
    ? dataFileThreats
    : dataBehavioralThreatsParsed;
  const isLoadingThreats = isFileDetectionType
    ? isLoadingFileThreats
    : isLoadingBehavioralThreats;
  const isErrorFetchingThreats = isFileDetectionType
    ? isErrorFetchingFileThreats
    : isErrorFetchingBehavioralThreats;
  const errorFetchingThreats = isFileDetectionType
    ? errorFetchingFileThreats
    : errorFetchingBehavioralThreats;

  const dataCount = dataThreats?.count;
  const malwareCount = dataThreats?.malware_count;
  const pupCount = dataThreats?.pup_count;
  const threats: ThreatDetail[] | BehavioralThreatDetail[] =
    dataThreats?.results || [];
  const isEmpty = dataCount === 0;
  const isFiltering = !isDefaultFilterState(['detectionType']);
  const isFilteringOmittingFields = !isDefaultFilterState([
    'detectionDate',
    'detectionType',
  ]);
  const displayDetectionPeriod: string = getDisplayDetectionPeriod(
    filters.detectionDate,
  );

  /* istanbul ignore next */
  const handlePaginationChange = (page: number, pageSize: PageSize) => {
    setPagination({ page, pageSize });
    setSelectedForSplitView(undefined);

    updateStoredPageSize(pageSize);
  };

  const handleExportThreatDetailsClick = async () => {
    const { limit, offset, ...rest } = toRequestFormat(
      filters,
      sort,
      pagination,
      deviceId,
    );

    showToast(`export-preparing`);
    try {
      const rawData = await threatService.getExportThreats(rest);
      downloadCSVFile(rawData?.data);
      showToast(`export-complete`);
    } catch {
      showToast(`export-error`);
    }
  };

  /* istanbul ignore next */
  const setFiltersAndResetPage = (newFilters: FiltersState) => {
    setFilters(newFilters);
    setPagination({ page: 1 });
    setSelectedForSplitView(undefined);
  };

  /* istanbul ignore next */
  const handleClearFilters = (filterName: FilterFields) => {
    setFiltersAndResetPage({ [filterName]: undefined });
  };

  /* istanbul ignore next */
  const handleApplyFilters = (
    filterName: FilterFields,
    value: ThreatStatus[] | ThreatClassification | DetectionDateFilterFields,
  ) => {
    setFiltersAndResetPage({ [filterName]: value });
  };

  /* istanbul ignore next */
  const handleApplyFiltersForDetectionType = (
    value: DetectionTypeSelectorState | undefined,
  ) => {
    setFiltersAndResetPage({
      status: undefined,
      classification: undefined,
      detectionType: value,
    });
  };

  /* istanbul ignore next */
  const handleClearAllFilters = () => {
    setFiltersAndResetPage({
      status: undefined,
      classification: undefined,
      detectionDate: undefined,
      query: undefined,
    });
  };

  const handleStatusEventChange = useCallback(
    /* istanbul ignore next */ (event: FileStatus) => {
      showToast(`status-${event}`);
      if (event === 'resolved') {
        refetchThreats();
      }
    },
    [showToast, refetchThreats],
  );

  const { startFileStatusCheck, isPolling: isThreatBeingChecked } =
    useFileStatusPolling(handleStatusEventChange);

  const handleRecheckThreatStatus = useCallback(
    /* istanbul ignore next */ (selectedThreat: ThreatDetail) => {
      startFileStatusCheck(
        selectedThreat.device_id,
        selectedThreat.file_hash,
        selectedThreat.file_path,
      );
    },
    [startFileStatusCheck],
  );

  useEffect(
    /* istanbul ignore next */ () => {
      if (!isFetchingFileThreats && selectedForSplitView?.threat) {
        const updatedThreat = threats.find(
          (item) =>
            `${item.file_hash}${item.file_path}${item.device_id}${item.detection_date}` ===
            `${selectedForSplitView?.threat.file_hash}${selectedForSplitView?.threat.file_path}${selectedForSplitView?.threat.device_id}${selectedForSplitView?.threat.detection_date}`,
        );
        setSelectedForSplitView({ threat: updatedThreat, isFileDetectionType });
      }
    },
    [isFetchingFileThreats, isFileDetectionType],
  );

  /* istanbul ignore next */
  const handleColumnSortChange = useCallback(
    /*istanbul ignore next */ (sortState: SortState) => {
      setSort({
        sortBy: sortState.col as SortColumnName | undefined,
        sortOrder: sortState.direction as SortColumnDirection | undefined,
      });
      setSelectedForSplitView(undefined);
    },
    [setSort],
  );

  const handleRelease = (deviceCount: number) => {
    handleCloseModal();
    /* istanbul ignore next */
    showToast('release-complete', {
      content: () =>
        i18n.t(
          '{threatName} will be released on {deviceCount} devices upon next check in.',
          {
            threatName: threatToRelease?.threat_name,
            deviceCount,
          },
        ),
    });
  };

  const handleReleaseError = (type: string) => {
    handleCloseModal();
    showToast(type);
  };

  const handleCloseModal = () => {
    setSelectedThreatForRelease(undefined);
  };
  const handleGetReleaseDetails = useCallback(
    /* istanbul ignore next */ (item: ThreatDetail) => {
      setSelectedThreatForRelease(item);
    },
    [],
  );

  const handleOpenSplitView = useCallback(
    (
      threat: ThreatDetail | BehavioralThreatDetail,
      isFileDetectionType: boolean,
    ) => {
      setSelectedForSplitView({ threat, isFileDetectionType });
    },
    [],
  );

  const handleCloseSplitView = () => {
    setSelectedForSplitView(undefined);
  };

  const ThreatListSplitViewPlaceholder =
    selectedForSplitView?.isFileDetectionType
      ? ThreatListSplitView
      : ThreatListSplitViewPM;

  return (
    <ThreatListErrorBoundary
      css={errorBoundaryTableCss}
      preReset={refetchThreats}
    >
      <Container>
        <ContentLayout>
          <FiltersContainer>
            <ThreatListFilters
              filters={filters}
              onApply={handleApplyFilters}
              onDetectionTypeApply={handleApplyFiltersForDetectionType}
              onClear={handleClearFilters}
              onClearAll={handleClearAllFilters}
              isFiltering={isFiltering}
              malwareCount={malwareCount}
              pupCount={pupCount}
              isEmpty={isEmpty}
              isLoading={isLoadingThreats}
              onExport={handleExportThreatDetailsClick}
              isFileDetectionType={isFileDetectionType}
              isExportHidden={
                isEmpty || isLoadingThreats || !isFileDetectionType
              }
            />
          </FiltersContainer>

          <ThreatListTable
            isFileDetectionType={isFileDetectionType}
            searchTerm={filters.query}
            handleReleaseThreat={handleGetReleaseDetails}
            handleRecheckThreatStatus={handleRecheckThreatStatus}
            isThreatBeingChecked={isThreatBeingChecked}
            threats={threats}
            sort={sort}
            handleColumnSortChange={handleColumnSortChange}
            handleOpenSplitView={handleOpenSplitView}
            selectedForSplitView={selectedForSplitView}
            isThreatSelectedForSplitView={isThreatSelectedForSplitView}
            isError={isErrorFetchingThreats}
            error={errorFetchingThreats}
            isEmpty={isEmpty}
          />

          {(isEmpty || isLoadingThreats) && (
            <TableContainer>
              {!isLoadingThreats ? (
                <ThreatListEmptyStates
                  isFiltering={isFilteringOmittingFields}
                  displayDetectionPeriod={displayDetectionPeriod}
                  displayDetectionSubject={
                    isFileDetectionType
                      ? i18n.t('malware or PUP detections')
                      : i18n.t('malicious or suspicious behavioral detections')
                  }
                />
              ) : (
                <ThreatListLoader label={i18n.t('Loading Threats')} />
              )}
            </TableContainer>
          )}

          <PaginationContainer>
            <Pagination
              currentPage={pagination.page}
              totalItems={dataCount || 0}
              itemsPerPage={pagination.pageSize}
              onPageChange={
                /* istanbul ignore next */ (page) =>
                  handlePaginationChange(page, pagination.pageSize)
              }
              onItemsPerPageChange={
                /* istanbul ignore next */ (itemsPerPage) =>
                  handlePaginationChange(1, itemsPerPage as PageSize)
              }
            />
          </PaginationContainer>
        </ContentLayout>

        {isThreatSelectedForSplitView && (
          <ThreatListSplitViewContainer>
            <ThreatListSuspense>
              <ThreatListSplitViewPlaceholder
                selected={selectedForSplitView?.threat}
                onClose={handleCloseSplitView}
                query={filters.query}
                onReleaseThreat={
                  /* istanbul ignore next */ () =>
                    handleGetReleaseDetails(selectedForSplitView?.threat)
                }
                onRecheckStatus={handleRecheckThreatStatus}
                isThreatBeingChecked={isThreatBeingChecked}
              />
            </ThreatListSuspense>
          </ThreatListSplitViewContainer>
        )}
      </Container>
      {threatToRelease && (
        <ThreatListReleaseModal
          name={threatToRelease?.threat_name}
          fileHash={threatToRelease?.file_hash}
          deviceId={threatToRelease?.device_id}
          libraryItemId={threatToRelease?.library_item_id}
          onClose={handleCloseModal}
          onRelease={handleRelease}
          onError={handleReleaseError}
        />
      )}
    </ThreatListErrorBoundary>
  );
};

export default memo(ThreatListEvents);
