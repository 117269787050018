import { type CSS, Flex, styled } from '@kandji-inc/nectar-ui';
import useVulnerability from '../store';

type SoftwareIconProps = {
  iconKey: string;
  alt?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  css?: CSS;
};

const SoftwareIconContainer = styled(Flex, {
  variants: {
    size: {
      xs: {
        minWidth: '$4',
        width: '$4',
        height: '$4',
      },
      sm: {
        minWidth: '$5',
        width: '$5',
        height: '$5',
      },
      md: {
        minWidth: '$6',
        width: '$6',
        height: '$6',
      },
      lg: {
        minWidth: '$7',
        width: '$7',
        height: '$7',
      },
      xl: {
        minWidth: '40px',
        width: '40px',
        height: '40px',
      },
    },
  },
});

const SoftwareIcon = (props: SoftwareIconProps) => {
  const { iconKey, alt = '', size = 'sm', css } = props;

  const iconMap = useVulnerability((state) => state.iconMap);

  // Until the iconMap is loaded, return an "empty" icon to save the space for said icon
  if (!iconMap) {
    return <SoftwareIconContainer size={size} />;
  }

  // If the iconMap exists but the icon doesn't exist in the iconMap, return null
  if (!iconMap[iconKey]) {
    return null;
  }

  return (
    <SoftwareIconContainer
      justifyContent="center"
      alignItems="center"
      size={size}
      css={{
        '& img': {
          width: '100%',
          height: '100%',
        },
        ...css,
      }}
    >
      <img src={iconMap[iconKey]} alt={alt} />
    </SoftwareIconContainer>
  );
};

export { SoftwareIcon };
