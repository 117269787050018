import { Flex, Toast, styled } from '@kandji-inc/nectar-ui';
import type { Computer } from '@shared/types/common.types';
import { InterfaceContext } from 'contexts/interface';
import { useContext, useRef } from 'react';
import featureFlags from 'src/config/feature-flags';
import useThreatsToast from '../common/hooks/use-threats-toast';
import ThreatListEvents from './ThreatListEvents';
import ThreatListEventsLegacy from './ThreatListEventsLegacy';

const Container = styled(Flex, {
  flexDirection: 'column',
});

type ThreatListProps = Readonly<{
  computer?: Computer | undefined;
}>;

const ThreatList = (props: ThreatListProps) => {
  const { computer } = props;
  const { sidebarOpened } = useContext(InterfaceContext);
  const toastRef = useRef(null);
  const { showToast } = useThreatsToast(toastRef);
  const isNewTableFFOn = featureFlags.getFlag(
    'edr_031725_new-device-record-threat-table',
  );

  return (
    <>
      <Container css={{ minHeight: 'calc(100vh - 253px)' }}>
        {!isNewTableFFOn ? (
          <ThreatListEventsLegacy
            deviceId={computer?.id}
            showToast={showToast}
          />
        ) : (
          <ThreatListEvents deviceId={computer?.id} showToast={showToast} />
        )}
      </Container>
      <Toast
        testId="threat-toaster"
        managerRef={toastRef}
        viewportCss={{
          left: sidebarOpened ? '256px' : '78px',
        }}
      />
    </>
  );
};

export default ThreatList;
