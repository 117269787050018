import { Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import VulnerabilityIcon from '../../assets/vulnerability.png';
import { getNoVulnerabilitiesCopy } from '../../helpers';

const NoVulnerabilities = (props: {
  lastDetectedFilter: DetectionDateFilterFields;
  isOnDeviceRecord?: boolean;
}) => {
  const { lastDetectedFilter, isOnDeviceRecord = false } = props;
  const copy = getNoVulnerabilitiesCopy({
    lastDetectedFilter,
    isOnDeviceRecord,
  });

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flow="column"
      hFull
      css={{ overflow: 'hidden' }}
    >
      <img
        src={VulnerabilityIcon}
        alt="Vulnerability"
        style={{ width: '50px' }}
      />
      <Heading size="4" css={{ fontWeight: '$medium', paddingTop: '$3' }}>
        {i18n.t('No vulnerabilities detected')}
      </Heading>
      <Text>{copy}</Text>
    </Flex>
  );
};

export { NoVulnerabilities };
