import {
  deviceTypes,
  platformTypes,
} from 'src/features/library-items/library/common';
import { getEnabledPlatforms } from 'src/hooks/useGetEnabledPlatforms';
import type { TDeviceFamily, TPlatform } from 'src/types/platform.types';

export const getDevicePlatform = (deviceFamily: TDeviceFamily): TPlatform => {
  if (deviceFamily === 'Windows' || deviceFamily === 'Android') {
    return deviceFamily;
  }

  return 'Apple';
};

export function platformsFromDevices(devices: string[]): {
  hasApple: boolean;
  hasWindows: boolean;
  hasAndroid: boolean;
} {
  const { androidEnabled, windowsEnabled } = getEnabledPlatforms();
  const hasAndroid = devices.includes(platformTypes.ANDROID) && androidEnabled;
  const hasWindows = devices.includes(platformTypes.WINDOWS) && windowsEnabled;
  const hasApple = Object.values(deviceTypes).some((type) =>
    devices.includes(type),
  );

  return {
    hasApple,
    hasWindows,
    hasAndroid,
  };
}

export function appleOnlyDevices(devices: string[]) {
  return devices.filter(
    (type) => type !== platformTypes.ANDROID && type !== platformTypes.WINDOWS,
  );
}
