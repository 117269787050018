import React, { useState } from 'react';

import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text, TextField } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { setDeviceName } from '../../computer/api';

const ComputerSetMDMDeviceName = (props) => {
  const { info, onHide } = props;
  const { computerId, deviceFamily } = info;

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState();

  const placeholderMap = {
    Mac: i18n.t("Logan's Mac"),
    iPad: i18n.t("Logan's iPad"),
    iPhone: i18n.t("Logan's iPhone"),
    iPod: i18n.t("Logan's iPod touch"),
    AppleTV: i18n.t('5th Floor Conference Room Apple TV'),
  };

  const handleOnDeviceNameChange = (e) => setName(e.target.value);

  const onSetDeviceName = () => {
    setIsLoading(true);
    setDeviceName(computerId, name)
      .then(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.t('Command initiated'));
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.common.error());
      });
  };

  const content = (
    <Flex flow="column" gap="md" css={{ minWidth: '$15' }}>
      <Text>
        {i18n.t('Enter the updated device name you would like to set.')}
      </Text>
      <TextField
        aria-label="device-name"
        placeholder={placeholderMap[deviceFamily]}
        onChange={handleOnDeviceNameChange}
      />
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        {i18n.t('Cancel')}
      </Button>
      <Button
        variant="primary"
        loading={isLoading}
        disabled={!name}
        onClick={onSetDeviceName}
      >
        {i18n.t('Set Device Name')}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      onOpenChange={onHide}
      title={i18n.t('Set Device Name')}
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerSetMDMDeviceName;
