/* istanbul ignore file */
import { Box, Button, Flex, Text, TextField } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { publicApi } from 'src/app/api/base';
import { URL_USERS } from 'src/app/api/urls';
import { useBodyNoMargin } from 'src/hooks/useBodyNoMargin';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { useTemporaryCss } from 'src/hooks/useTemporaryCss';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import Loader from 'src/theme/components/atoms/Loader';
import KandjiBee from './assets/kandji-logo.svg';
import { footerLinks } from './common';

const ResetPassword = () => {
  const isScreenSmall = useMediaQuery('(max-width: 768px)');
  useWhiteBackground();
  useBodyNoMargin();
  useTemporaryCss('#app', { minWidth: 'unset' });

  const { clientId } = useSelector((state) => state.auth0);

  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<{ password: string; confirmPassword: string }>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();

  const onSubmit = ({ password, confirmPassword }) => {
    if (!password) {
      setError('password', { message: i18n.t('Required.') });
      return;
    }

    if (password !== confirmPassword) {
      setError('confirmPassword', {
        message: i18n.t('Passwords should match.'),
      });
      return;
    }

    if (password.length < 8) {
      setError('password', {
        message: i18n.t(
          'Password should contain at least 8 characters or digits.',
        ),
      });
      return;
    }

    return publicApi(`${URL_USERS}resetpassword`)
      .post({
        token: params.get('token'),
        new_password: password,
        password_confirmation: confirmPassword,
      })
      .then(() => setIsSuccess(true))
      .catch(() =>
        setError('root', { message: i18n.t('Something went wrong.') }),
      );
  };

  useEffect(() => {
    if (params.has('token')) {
      const token = params.get('token');
      publicApi(`${URL_USERS}resetpassword`)
        .get({ token })
        .catch((error) => {
          if (error.response.status === 400) {
            setError('root', {
              message: i18n.t('The specified token is expired.'),
            });
          } else {
            setError('root', {
              message: i18n.t('The specified token is invalid.'),
            });
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [params]);

  const hasRootError = errors.root;
  const linkStyle = {
    color: '#505E71',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
  };

  if (!clientId) {
    return (
      <Flex wFull hFull alignItems="center" justifyContent="center">
        <Loader type="splash" />
      </Flex>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex wFull hFull css={{ height: '100vh' }}>
        <Flex flex="1" alignItems="center" justifyContent="center">
          <Box
            hFull
            css={{
              display: 'grid',
              gridTemplateRows: '1fr auto',
              padding: '48px',
              paddingBottom: 0,
            }}
          >
            <Flex
              flow="column"
              css={{
                width: hasRootError || isSuccess ? '440px' : '340px',
                alignSelf: 'center',
                justifySelf: 'center',
              }}
            >
              {hasRootError && (
                <>
                  <img
                    src={KandjiBee}
                    alt="kandji-bee"
                    style={{
                      height: '48px',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      alignSelf: 'flex-start',
                      marginBottom: '24px',
                    }}
                  />
                  <Text
                    size="5"
                    css={{ marginBottom: '16px', letterSpacing: '-1.2px' }}
                  >
                    {i18n.t('Oops. Something went wrong.')}
                  </Text>
                  <Text css={{ lineHeight: '20px', marginBottom: '28px' }}>
                    {errors.root.message}
                  </Text>
                  <Button
                    variant="primary"
                    onClick={() => history.push('/signin')}
                  >
                    {i18n.t('Return to login page')}
                  </Button>
                </>
              )}

              {isLoading && <Loader type="splash" />}

              {isSuccess && (
                <>
                  <img
                    src={KandjiBee}
                    alt="kandji-bee"
                    style={{
                      height: '48px',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      alignSelf: 'flex-start',
                      marginBottom: '24px',
                    }}
                  />
                  <Text
                    size="5"
                    css={{ marginBottom: '18px', letterSpacing: '-1.2px' }}
                  >
                    {i18n.t('Password reset successfully.')}
                  </Text>
                  <Button
                    variant="primary"
                    onClick={() => history.push('/signin')}
                  >
                    {i18n.t('Return to login page')}
                  </Button>
                </>
              )}

              {!isSuccess && !isLoading && !hasRootError && (
                <>
                  <img
                    src={KandjiBee}
                    alt="kandji-bee"
                    style={{
                      height: '48px',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      alignSelf: 'flex-start',
                      marginBottom: '24px',
                    }}
                  />
                  <Text
                    size="5"
                    css={{ marginBottom: '28px', letterSpacing: '-1.2px' }}
                  >
                    {i18n.t('Create a new password')}
                  </Text>
                  <TextField
                    {...register('password')}
                    type="password"
                    label={i18n.t('New password')}
                    placeholder={i18n.t('Enter a new password')}
                    hint={
                      errors.password
                        ? {
                            label: errors.password.message,
                          }
                        : undefined
                    }
                    state={errors.password ? 'error' : 'default'}
                    onKeyDownCapture={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit(onSubmit)();
                      }
                    }}
                    css={{ marginBottom: '18px' }}
                  />
                  <TextField
                    {...register('confirmPassword')}
                    type="password"
                    label={i18n.t('Confirm new password')}
                    placeholder={i18n.t('Confirm new password')}
                    hint={
                      errors.confirmPassword
                        ? {
                            label: errors.confirmPassword.message,
                          }
                        : undefined
                    }
                    state={errors.confirmPassword ? 'error' : 'default'}
                    onKeyDownCapture={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit(onSubmit)();
                      }
                    }}
                    css={{ marginBottom: '18px' }}
                  />

                  <Flex flow="column" gap="sm">
                    <Button variant="primary" type="submit">
                      {i18n.t('Confirm')}
                    </Button>
                    <Button
                      variant="subtle"
                      onClick={() => history.push('/signin')}
                    >
                      {i18n.t('Cancel')}
                    </Button>
                  </Flex>
                </>
              )}
            </Flex>
            <Flex
              gap="sm"
              wrap="wrap"
              justifyContent="center"
              css={{ paddingBottom: '42px' }}
            >
              {footerLinks.map(({ getLabel, link }) => (
                <a
                  key={getLabel()}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  style={linkStyle}
                >
                  {getLabel()}
                </a>
              ))}
            </Flex>
          </Box>
        </Flex>
        {!isScreenSmall && (
          <Flex
            flex="1"
            css={{
              background: 'linear-gradient(180deg, #000 -15.27%, #2A2A2A 100%)',
              flexShrink: 2,
            }}
          />
        )}
      </Flex>
    </form>
  );
};

export default ResetPassword;
