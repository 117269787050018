import { Flex } from '@kandji-inc/nectar-ui';
import React from 'react';

import type { TDeviceFamily } from '@shared/types/platform.types';
import ComputerTechSpecsTab from 'src/app/components/computer/tabs/ComputerTechSpecsTab';
import { get } from 'src/features/util/lib';
import { useGetEnabledPlatforms } from 'src/hooks/useGetEnabledPlatforms';
import { getDevicePlatform } from 'src/utils/PlatformUtils';
import { DetailsCategory, DetailsCategoryRow, DetailsWrapper } from './common';
import { platformDeviceDetails } from './platform.mapping';

interface DeviceDetailsProps {
  deviceId: string;
  deviceFamily: TDeviceFamily;
}

export default function DeviceDetails(props: DeviceDetailsProps) {
  const { deviceId, deviceFamily } = props;
  const { windowsEnabled } = useGetEnabledPlatforms();

  const platform = getDevicePlatform(deviceFamily);
  const deviceDetailsCategories = platformDeviceDetails[platform];

  if (platform !== 'Windows' || !windowsEnabled) {
    return <ComputerTechSpecsTab />;
  }

  return (
    <Flex pt5>
      <DetailsWrapper deviceId={deviceId}>
        {(details) =>
          deviceDetailsCategories.map((rootCategory) => {
            const { category, fields } = rootCategory;
            return (
              <DetailsCategory key={category} title={category}>
                {Object.values(fields).map((field) => {
                  const { dataKey, title, transform } = field;
                  const key = Array.isArray(dataKey)
                    ? dataKey.find((k) => get<string | number>(details, k))
                    : dataKey;

                  const value = key
                    ? get<string | number>(details, key)
                    : undefined;
                  const transformed =
                    transform && value ? transform(value, key) : value;

                  return (
                    <DetailsCategoryRow title={title} value={transformed} />
                  );
                })}
              </DetailsCategory>
            );
          })
        }
      </DetailsWrapper>
    </Flex>
  );
}
