import { Flex, styled } from '@kandji-inc/nectar-ui';
import type { CSS } from '@kandji-inc/nectar-ui';
import EDRCopyButton from '../../../common/components/EDRCopyButton';
import ThreatListTooltip from '../../../common/components/EDRTooltip';
import ThreatListHighlightedText from './ThreatListHighlightedText';

const TextTruncate = styled('span', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const TextWrapper = styled(Flex, {
  overflow: 'hidden',
  alignItems: 'center',
  '& > div': {
    visibility: 'hidden',
  },
  '&:hover': {
    '& > div': {
      visibility: 'visible',
    },
  },
});

type ThreatListTextCopyButtonProps = {
  text: string;
  copyText?: string;
  highlighted?: string | undefined;
  tooltip?: string | undefined;
  buttonTootlipPosition?: 'top' | 'bottom' | 'left' | 'right';
  css?: CSS;
};

const ThreatListTextCopyButton = (props: ThreatListTextCopyButtonProps) => {
  const {
    text,
    highlighted,
    tooltip,
    buttonTootlipPosition,
    copyText = text,
    css,
  } = props;

  return (
    <ThreatListTooltip side="bottom" label={tooltip}>
      <TextWrapper gap="xs" css={css}>
        <TextTruncate>
          <ThreatListHighlightedText search={highlighted}>
            {text}
          </ThreatListHighlightedText>
        </TextTruncate>
        <EDRCopyButton
          value={copyText}
          tooltipSide={buttonTootlipPosition}
          css={{ tooltipCss: { zIndex: 2 } }}
        />
      </TextWrapper>
    </ThreatListTooltip>
  );
};

export default ThreatListTextCopyButton;
