/* istanbul ignore file */

import { blueprintLibraryCategories } from 'features/blueprints/common';
import { i18n } from 'i18n';
import {
  apiTypes,
  canAddLibraryTypes,
  categories,
  deviceTypes,
  publishers,
} from '../../common';
import LibraryItemConfig from './item-config.class';

/* These items are returned from the API (for now). Their configurations live
here for the purpose of allowing frontend configuration, specifically routing.
*/
const operatingSystemApps = {
  'iOS Release': new LibraryItemConfig({
    getName: () => i18n.t('Operating Systems'),
    getDescription: () => i18n.t('Operating Systems managed by Kandji.'),
    name: 'Operating Systems',
    description: 'Operating Systems managed by Kandji.',
    type: apiTypes.IOS_RELEASE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.IPHONE],
    osRequirements: [],
    category: categories.IOS_RELEASES,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    blueprintLibraryName: categories.OPERATING_SYSTEMS,
    icon: '',
    canAdd: canAddLibraryTypes.PREDEFINED_BY_BACKEND,
    identifier: '',
    routeType: 'ios-releases',
    getUrl: ({ id }) => `/library/ios-releases/${id}`,
    getAddUrl: ({ id }) => `/library/ios-releases/${id}/add`,
    isOldPath: () => false,
    singleBlueprintAllowed: true,
  }),
  'ipadOS Release': new LibraryItemConfig({
    getName: () => i18n.t('Operating Systems'),
    getDescription: () => i18n.t('Operating Systems managed by Kandji.'),
    name: 'Operating Systems',
    description: 'Operating Systems managed by Kandji.',
    type: apiTypes.IPADOS_RELEASE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.IPAD],
    osRequirements: [],
    category: categories.IPADOS_RELEASES,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    blueprintLibraryName: categories.OPERATING_SYSTEMS,
    icon: '',
    canAdd: canAddLibraryTypes.PREDEFINED_BY_BACKEND,
    identifier: '',
    routeType: 'ipados-releases',
    getUrl: ({ id }) => `/library/ipados-releases/${id}`,
    getAddUrl: ({ id }) => `/library/ipados-releases/${id}/add`,
    isOldPath: () => false,
    singleBlueprintAllowed: true,
  }),
  'tvOS Release': new LibraryItemConfig({
    getName: () => i18n.t('Operating Systems'),
    getDescription: () => i18n.t('Operating Systems managed by Kandji.'),
    name: 'Operating Systems',
    description: 'Operating Systems managed by Kandji.',
    type: apiTypes.TVOS_RELEASE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.TV],
    osRequirements: [],
    category: categories.TVOS_RELEASES,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    blueprintLibraryName: categories.OPERATING_SYSTEMS,
    icon: '',
    canAdd: canAddLibraryTypes.PREDEFINED_BY_BACKEND,
    identifier: '',
    routeType: 'tvos-releases',
    getUrl: ({ id }) => `/library/tvos-releases/${id}`,
    getAddUrl: ({ id }) => `/library/tvos-releases/${id}/add`,
    isOldPath: () => false,
    singleBlueprintAllowed: true,
  }),
  'MacOS Release': new LibraryItemConfig({
    getName: () => i18n.t('Operating Systems'),
    getDescription: () => '',
    name: 'Operating Systems',
    description: ``,
    type: apiTypes.MACOS_RELEASE,
    publisher: publishers.APPLE,
    devices: [deviceTypes.MAC],
    osRequirements: [],
    category: categories.OPERATING_SYSTEMS,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    blueprintLibraryName: categories.OPERATING_SYSTEMS,
    icon: '',
    canAdd: canAddLibraryTypes.PREDEFINED_BY_BACKEND,
    identifier: '',
    routeType: 'operating-systems',
    getUrl: ({ id }) => `/library/operating-systems/${id}`,
    getAddUrl: ({ id }) => `/library/operating-systems/${id}/add`,
    singleBlueprintAllowed: true,
    getDoNotManageUpdatesError: () =>
      i18n.t(
        'This configuration is invalid. If you choose to continuously enforce upgrades, you need to set an update schedule or specify a minimum version.',
      ),
  }),
};

export default operatingSystemApps;
