import { formatNumber } from '@kandji-inc/nectar-i18n';
import {
  Badge,
  Flex,
  Heading,
  Icon,
  Loader,
  Text,
  Tooltip,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';
import { useShallow } from 'zustand/react/shallow';
import { useGetExploitabilityScore } from '../../../hooks/dashboard/use-get-exploitability-score';
import useVulnerability from '../../../store';
import { Tile, TileHeading } from './tile';

const ExploitabilityScoreTile = () => {
  const [timespanFilter, softwareNameFilter, severityFilter] = useVulnerability(
    useShallow((state) => [
      state.allVulnerabilitiesFilter.latestDetected,
      state.allVulnerabilitiesFilter.softwareName,
      state.allVulnerabilitiesFilter.severity,
    ]),
  );
  const { KEVScore, trend, isLoading } = useGetExploitabilityScore(
    softwareNameFilter,
    timespanFilter,
    severityFilter,
  );

  const isTrendPositive = trend > 0;

  return (
    <Tile>
      <Flex flow="column" gap="xs" hFull>
        <Flex
          alignItems="center"
          gap="xs"
          justifyContent="space-between"
          css={{ minHeight: '28px' }}
        >
          <Flex gap="xs">
            <TileHeading size="4">
              {i18n.t('Vulnerabilities with a known exploit')}
            </TileHeading>
            {!isLoading && trend !== 0 && (
              <Badge
                color={isTrendPositive ? 'red' : 'green'}
                compact
                css={{
                  paddingTop: '$0',
                  paddingBottom: '$0',
                  height: 16,
                  alignSelf: 'center',
                }}
              >
                <Flex gap="sm" alignItems="center">
                  <Icon
                    name={
                      isTrendPositive ? 'arrow-up-right' : 'arrow-down-left'
                    }
                    style={{ width: 10 }}
                  />
                  <Text
                    css={{ fontSize: '12px' }}
                  >{`${formatNumber(parseFloat((trend * 100).toFixed(2)))}%`}</Text>
                </Flex>
              </Badge>
            )}
          </Flex>
        </Flex>

        {isLoading && (
          <Flex hFull alignItems="center" justifyContent="center">
            <Loader data-testid="loading-vulnerabilities-by-software" />
          </Flex>
        )}

        {!isLoading && (
          <Flex
            hFull
            flow="column"
            alignItems="center"
            justifyContent="center"
            gap="xs"
          >
            <Heading size="1">{`${formatNumber(parseFloat(KEVScore.toFixed(2)))}%`}</Heading>
            <Flex flow="row" alignItems="center" gap="xs">
              <Text variant="description">{i18n.t('Exploitability')}</Text>
              <Tooltip
                content={i18n.t(
                  'Percent of CVEs with a known exploited vulnerability (KEV)',
                )}
                side="bottom"
                theme="light"
              >
                <Flex>
                  <Icon
                    name="circle-info"
                    size="xs"
                    color="var(--colors-neutral60)"
                  />
                </Flex>
              </Tooltip>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Tile>
  );
};

export { ExploitabilityScoreTile };
