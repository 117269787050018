import { blueprintLibraryCategories } from 'features/blueprints/common';
import { i18n } from 'i18n';
/* istanbul ignore file */
import React from 'react';
import {
  apiTypes,
  canAddLibraryTypes,
  categories,
  deviceTypes,
  i18nOsRequirements,
  osRequirements,
  publishers,
  publishersTranslations,
} from '../../common';
import svgs from '../icons';
import LibraryItemConfig from './item-config.class';

const enrollmentApps = {
  Liftoff: new LibraryItemConfig({
    getName: () => i18n.t('Liftoff'),
    getDescription: () =>
      i18n.t(
        'Create a customized onboarding experience for your end users as they enroll into Kandji and their device is initially configured. Users can optionally be locked into a full screen experience.',
      ),
    name: 'Liftoff',
    description:
      'Create a customized onboarding experience for your end users as they enroll into Kandji and their device is initially configured. Users can optionally be locked into a full screen experience.',
    type: apiTypes.KANDJI_SETUP,
    publisher: publishers.KANDJI,
    getPublisher: () => publishersTranslations(publishers.KANDJI),
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_14],
    getOsRequirements: () => [i18nOsRequirements(osRequirements.MAC_10_14)],
    category: categories.ENROLLMENT,
    blueprintCategory: blueprintLibraryCategories.ENROLLMENT,
    icon: svgs['Kandji Setup'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'liftoff',
    getUrl: ({ id }) => `/library/liftoff/${id}`,
    getAddUrl: () => '/library/liftoff/add',
    singleBlueprintAllowed: true,
  }),
  'Kandji Login': new LibraryItemConfig({
    getName: () => i18n.t('Passport'),
    getDescription: () =>
      i18n.t(
        'Allow users to authenticate on Mac computers using their organization’s directory credentials. Configure settings and customize the experience below.',
      ),
    name: 'Passport',
    description: `Allow users to authenticate on Mac computers using their organization’s directory credentials. Configure settings and customize the experience below.`,
    type: apiTypes.KANDJI_LOGIN,
    publisher: publishers.KANDJI,
    getPublisher: () => publishersTranslations(publishers.KANDJI),
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_14],
    getOsRequirements: () => [i18nOsRequirements(osRequirements.MAC_10_14)],
    category: categories.ENROLLMENT,
    blueprintCategory: blueprintLibraryCategories.ENROLLMENT,
    icon: svgs['Kandji Passport'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'passport',
    getUrl: ({ id }) => `/library/passport/${id}`,
    getAddUrl: () => `/library/passport/add`,
    // TODO: use it in counters
    isHidden: ({ environment, account }) => {
      const { isEnv } = environment;

      if (isEnv.local) {
        return false;
      }

      const config = account.currentCompany.feature_configuration;
      const isAllowed = Boolean(config.kandji_login?.enabled);

      return !isAllowed;
    },
    singleBlueprintAllowed: true,
  }),
};

export default enrollmentApps;
