/* istanbul ignore file */
import { links } from 'src/app/common/constants';
import {
  apiTypes,
  categories,
} from 'src/features/library-items/library/common';
import {
  AppBlockingLog,
  LibraryItemLog,
  LogProfileWithControl,
  ParameterLog,
  StandardLog,
  VppAppLog,
} from './expanded-details';

const SINGLE_RUN_DATE_COLUMN_NAME = 'Last ran';
const RECURRING_RUN_DATE_COLUMN_NAME = 'Checked in';

const PENDING_STATUSES = ['Pending', 'Available', 'Excluded'];

const STATUS_MAP = {
  STOPPED: { label: 'Stopped', color: 'red' },
  ERROR: { label: 'Error', color: 'red' },
  WARNING: { label: 'Warning', color: 'red' },
  FAILED: { label: 'Failed', color: 'red' },
  MISSING: { label: 'Missing', color: 'red' },
  MUTE: { label: 'Mute', color: 'yellow' },
  INCOMPATIBLE: { label: 'Incompatible', color: 'neutral' },
  EXCLUDED: { label: 'Excluded', color: 'neutral' },
  INSTALLING: { label: 'Installing', color: 'neutral' },
  AVAILABLE: { label: 'Available', color: 'neutral' },
  PENDING: { label: 'Pending', color: 'neutral' },
  CHANGES_PENDING: { label: 'Changes pending', color: 'neutral' },
  NOT_YET_RUN: { label: 'Not yet run', color: 'neutral' },
  REMEDIATED: { label: 'Remediated', color: 'blue' },
  INSTALLED: { label: 'Installed', color: 'green' },
  PASS: { label: 'Pass', color: 'green' },
  SUCCESS: { label: 'Success', color: 'green' },
  CACHED: { label: 'Cached', color: 'neutral' },
  DOWNLOADING: { label: 'Downloading', color: 'neutral' },
};

const STATUS_TYPE_SEVERITY = {
  red: 10,
  yellow: 20,
  neutral: 30,
  blue: 40,
  green: 50,
};

const STATUS_TYPES = {
  [apiTypes.APP_BLOCKING]: {
    name: 'App Blocking',
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: AppBlockingLog,
  },
  [apiTypes.IPA_APP]: {
    link: links.ipaApps,
    name: categories.IN_HOUSE_APPS,
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: LibraryItemLog,
  },
  [apiTypes.VPP_APP]: {
    link: links.vppApps,
    name: categories.APP_STORE,
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: VppAppLog,
  },
  [apiTypes.AUTO_APP]: {
    link: links.automaticApps,
    name: categories.AUTO_APPS,
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: LibraryItemLog,
  },
  [apiTypes.MACOS_RELEASE]: {
    link: links.operatingSystems,
    name: categories.OPERATING_SYSTEMS,
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: LibraryItemLog,
  },
  [apiTypes.IOS_RELEASE]: {
    link: links.iOSReleases,
    name: categories.OPERATING_SYSTEMS,
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: LibraryItemLog,
  },
  [apiTypes.IPADOS_RELEASE]: {
    link: links.iPadOSReleases,
    name: categories.OPERATING_SYSTEMS,
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: LibraryItemLog,
  },
  [apiTypes.TVOS_RELEASE]: {
    link: links.tvOSReleases,
    name: categories.OPERATING_SYSTEMS,
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: LibraryItemLog,
  },
  [apiTypes.CUSTOM_APP]: {
    link: links.customApps,
    name: categories.CUSTOM_APPS,
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: LibraryItemLog,
  },
  [apiTypes.CUSTOM_SCRIPT]: {
    link: links.customScripts,
    name: categories.CUSTOM_SCRIPTS,
    dateColumnName: SINGLE_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: StandardLog,
  },
  [apiTypes.CUSTOM_PRINTER]: {
    link: links.customPrinters,
    name: categories.CUSTOM_PRINTERS,
    dateColumnName: SINGLE_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: StandardLog,
  },
  profile: {
    name: 'Profiles',
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: LogProfileWithControl,
  },
  [apiTypes.THREAT_SECURITY_POLICY]: {
    link: links.avert,
    name: categories.SECURITY,
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: StandardLog,
  },
  parameter: {
    name: 'Parameters',
    dateColumnName: RECURRING_RUN_DATE_COLUMN_NAME,
    expandedDetailsComponent: ParameterLog,
  },
};

const OTHER_PROFILE_TYPES: string[] = [
  apiTypes.DEVICE_NAME,
  apiTypes.WALLPAPER,
  apiTypes.SSH,
  apiTypes.RECOVERY_PASSWORD,
];

const OTHER_SECURITY_TYPES: string[] = [apiTypes.DEVICE_CONTROL];

const ALLOWED_TYPES = [
  ...new Set([
    ...Object.keys(STATUS_TYPES),
    ...OTHER_PROFILE_TYPES,
    ...OTHER_SECURITY_TYPES,
    apiTypes.IPA_APP_V2,
  ]),
];

export {
  ALLOWED_TYPES,
  OTHER_PROFILE_TYPES,
  OTHER_SECURITY_TYPES,
  PENDING_STATUSES,
  STATUS_MAP,
  STATUS_TYPE_SEVERITY,
  STATUS_TYPES,
  SINGLE_RUN_DATE_COLUMN_NAME,
  RECURRING_RUN_DATE_COLUMN_NAME,
};
