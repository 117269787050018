/* istanbul ignore file */
import { Flex, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useEffect, useMemo, useState } from 'react';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import ThreatListErrorBoundary from '../common/components/ThreatListErrorBoundary';
import ThreatListReleaseModal from '../common/components/ThreatListReleaseModal';
import ThreatListSuspense from '../common/components/ThreatListSuspense';
import ThreatListTable from '../common/components/ThreatListTable/ThreatListTable';
import constants from '../common/constants';
import useFileStatusPolling from '../common/hooks/use-file-status-polling';
import useGetBehavioralThreats from '../common/hooks/use-get-behavioral-threats/use-get-behavioral-threats';
import toRequestFormat from '../common/hooks/use-get-threats/toRequestFormat';
import useGetThreats from '../common/hooks/use-get-threats/use-get-threats';
import useStateParams from '../common/hooks/use-state-params';
import downloadCSVFile from '../common/utils/download-csv-file';
import getDisplayDetectionPeriod from '../common/utils/get-display-detection-period';
import { ThreatService } from '../data-service';
import type {
  BehavioralThreatDetail,
  DetectionTypeSelectorState,
  FileStatus,
  FilterFields,
  FiltersState,
  PageSize,
  PaginationState,
  ShowToast,
  SortColumnDirection,
  SortColumnName,
  SortColumnState,
  TableHeaderColumn,
  ThreatClassification,
  ThreatDetail,
  ThreatStatus,
} from '../threat.types';
import ThreatListFilters from './ThreatListFilters';
import ThreatListSplitView from './ThreatListSplitView/ThreatListSplitView';
import ThreatListSplitViewPM from './ThreatListSplitViewPM/ThreatListSplitViewPM';
import ThreatListTableBody from './ThreatListTableBody';
import {
  createPaginationParamConfig,
  defaultFilters,
  filtersParamConfig,
  sortParamConfig,
} from './params/params';

const Container = styled(Flex, {
  position: 'sticky',
  backgroundColor: '$neutral0',
  zIndex: 3,
});

const errorBoundaryTableCss = {
  flex: 1,
  minHeight: 400,
};

const getColumns = (isFileDetectionType: boolean): TableHeaderColumn[] => [
  {
    name: 'threat_name',
    title: i18n.t('Threat ID'),
    tooltip: isFileDetectionType
      ? i18n.t('SHA-256 hash of the detected file')
      : i18n.t('Behavioral detection rule name'),
    tooltipWidth: 150,
    size: isFileDetectionType ? 13 : 12,
  },
  {
    name: 'associated_item',
    title: i18n.t('Associated Item'),
    size: isFileDetectionType ? 10 : 11,
    tooltip: isFileDetectionType
      ? i18n.t('The most recent file name')
      : i18n.t('The most recent parent process name'),
  },
  {
    name: 'classification',
    title: i18n.t('Classification'),
    size: 10,
  },
  {
    name: 'detection_date',
    title: i18n.t('Detection Date'),
    size: 11,
  },
  {
    name: 'status',
    title: i18n.t('Threat Status'),
    size: 11,
  },
];

type ThreatListEventsProps = Readonly<{
  deviceId?: string | undefined;
  showToast: ShowToast;
  showChartTiles?: boolean | undefined;
  isTableScrollable?: boolean | undefined;
}>;

const ThreatListEvents = (props: ThreatListEventsProps) => {
  const { deviceId, showToast } = props;
  const threatService = useMemo(() => new ThreatService(), []);
  const [threatToRelease, setSelectedThreatForRelease] = useState<
    ThreatDetail | undefined
  >();
  const [selectedForSplitView, setSelectedForSplitView] = useState<
    | {
        threat: ThreatDetail | BehavioralThreatDetail;
        isFileDetectionType: boolean;
      }
    | undefined
  >();

  const isThreatSelectedForSplitView = Boolean(selectedForSplitView?.threat);

  const { paginationConfig, updateStoredPageSize } =
    createPaginationParamConfig('threatListEventsPaginationPageSize');

  const {
    filters,
    setFilters,
    pagination,
    setPagination,
    sort,
    setSort,
    isDefaultFilterState,
  } = useStateParams<FiltersState, PaginationState, SortColumnState>(
    filtersParamConfig,
    paginationConfig,
    sortParamConfig,
    defaultFilters,
  );

  const isFileDetectionType = Boolean(
    filters.detectionType === constants.THREAT_DETECTION_TYPE.FILE ||
      filters.detectionType === undefined,
  );

  const {
    data: dataFileThreats,
    isLoading: isLoadingFileThreats,
    isFetching: isFetchingFileThreats,
    isError: isErrorFetchingFileThreats,
    error: errorFetchingFileThreats,
    refetch: refetchThreats,
  } = useGetThreats(filters, sort, pagination, deviceId, isFileDetectionType);

  const {
    data: dataBehavioralThreats,
    isLoading: isLoadingBehavioralThreats,
    isError: isErrorFetchingBehavioralThreats,
    error: errorFetchingBehavioralThreats,
  } = useGetBehavioralThreats(
    filters,
    sort,
    pagination,
    deviceId,
    !isFileDetectionType,
  );

  // TODO: Move this logic away from the main component
  const dataBehavioralThreatsParsed = {
    results: dataBehavioralThreats?.detections.map((item) => ({
      ...item,
      file_hash: item.threat_id,
      process_name: item.process,
      status: item.threat_status,
    })),
    malware_count: undefined,
    pup_count: undefined,
    ...dataBehavioralThreats,
  };

  const dataThreats = isFileDetectionType
    ? dataFileThreats
    : dataBehavioralThreatsParsed;
  const isLoadingThreats = isFileDetectionType
    ? isLoadingFileThreats
    : isLoadingBehavioralThreats;
  const isErrorFetchingThreats = isFileDetectionType
    ? isErrorFetchingFileThreats
    : isErrorFetchingBehavioralThreats;
  const errorFetchingThreats = isFileDetectionType
    ? errorFetchingFileThreats
    : errorFetchingBehavioralThreats;

  const dataCount = dataThreats?.count;
  const malwareCount = dataThreats?.malware_count;
  const pupCount = dataThreats?.pup_count;
  const threats: ThreatDetail[] | BehavioralThreatDetail[] =
    dataThreats?.results || [];
  const isEmpty = dataCount === 0;
  const isFiltering = !isDefaultFilterState(['detectionType']);
  const isFilteringOmittingFields = !isDefaultFilterState([
    'detectionDate',
    'detectionType',
  ]);
  const displayDetectionPeriod: string = getDisplayDetectionPeriod(
    filters.detectionDate,
  );

  const handlePaginationChange = (page: number, pageSize: PageSize) => {
    setPagination({ page, pageSize });
    setSelectedForSplitView(undefined);

    updateStoredPageSize(pageSize);
  };

  const handleExportThreatDetailsClick = async () => {
    const { limit, offset, ...rest } = toRequestFormat(
      filters,
      sort,
      pagination,
      deviceId,
    );

    showToast(`export-preparing`);
    try {
      const rawData = await threatService.getExportThreats(rest);
      downloadCSVFile(rawData?.data);
      showToast(`export-complete`);
    } catch {
      showToast(`export-error`);
    }
  };

  const setFiltersAndResetPage = (newFilters: FiltersState) => {
    setFilters(newFilters);
    setPagination({ page: 1 });
    setSelectedForSplitView(undefined);
  };

  const handleClearFilters = (filterName: FilterFields) => {
    setFiltersAndResetPage({ [filterName]: undefined });
  };

  const handleApplyFilters = (
    filterName: FilterFields,
    value: ThreatStatus[] | ThreatClassification | DetectionDateFilterFields,
  ) => {
    setFiltersAndResetPage({ [filterName]: value });
  };

  const handleApplyFiltersForDetectionType = (
    value: DetectionTypeSelectorState | undefined,
  ) => {
    setFiltersAndResetPage({
      status: undefined,
      classification: undefined,
      detectionType: value,
    });
  };

  const handleClearAllFilters = () => {
    setFiltersAndResetPage({
      status: undefined,
      classification: undefined,
      detectionDate: undefined,
      query: undefined,
    });
  };

  const handleStatusEventChange = (event: FileStatus) => {
    showToast(`status-${event}`);
    if (event === 'resolved') {
      refetchThreats();
    }
  };

  const { startFileStatusCheck, isPolling: isThreatBeingChecked } =
    useFileStatusPolling(handleStatusEventChange);

  const handleRecheckThreatStatus = (selectedThreat: ThreatDetail) => {
    startFileStatusCheck(
      selectedThreat.device_id,
      selectedThreat.file_hash,
      selectedThreat.file_path,
    );
  };

  useEffect(() => {
    if (!isFetchingFileThreats && selectedForSplitView?.threat) {
      const updatedThreat = threats.find(
        (item) =>
          `${item.file_hash}${item.file_path}${item.device_id}${item.detection_date}` ===
          `${selectedForSplitView?.threat.file_hash}${selectedForSplitView?.threat.file_path}${selectedForSplitView?.threat.device_id}${selectedForSplitView?.threat.detection_date}`,
      );
      setSelectedForSplitView({ threat: updatedThreat, isFileDetectionType });
    }
  }, [isFetchingFileThreats, isFileDetectionType]);

  const handleColumnSortChange = (
    sortBy: SortColumnName | undefined,
    sortOrder: SortColumnDirection | undefined,
  ) => {
    setSort({ sortBy, sortOrder });
    setSelectedForSplitView(undefined);
  };

  const handleRelease = (deviceCount: number) => {
    handleCloseModal();
    showToast('release-complete', {
      content: () =>
        i18n.t(
          '{threatName} will be released on {deviceCount} devices upon next check in.',
          {
            threatName: threatToRelease?.threat_name,
            deviceCount,
          },
        ),
    });
  };

  const handleReleaseError = (type: string) => {
    handleCloseModal();
    showToast(type);
  };

  const handleCloseModal = () => {
    setSelectedThreatForRelease(undefined);
  };

  const handleGetReleaseDetails = (item: ThreatDetail) => {
    setSelectedThreatForRelease(item);
  };

  const handleOpenSplitView = (
    threat: ThreatDetail | BehavioralThreatDetail,
    isFileDetectionType: boolean,
  ) => {
    setSelectedForSplitView({ threat, isFileDetectionType });
  };

  const handleCloseSplitView = () => {
    setSelectedForSplitView(undefined);
  };

  const containerCssProcessMonitoring = {
    '& td:first-child': {
      paddingLeft: '$5',
    },
    '& td:last-child': {
      textAlign:
        isThreatSelectedForSplitView || !isFileDetectionType ? 'left' : 'right',
    },
  };

  const ThreatListSplitViewPlaceholder =
    selectedForSplitView?.isFileDetectionType
      ? ThreatListSplitView
      : ThreatListSplitViewPM;

  return (
    <ThreatListErrorBoundary
      css={errorBoundaryTableCss}
      preReset={refetchThreats}
    >
      <Flex flow="row" flex={1} css={{ mx: '-$5' }}>
        <Flex flow="column">
          <Container
            flow="row"
            gap="sm"
            alignItems="end"
            justifyContent="space-between"
            py3
            px5
            css={{
              top: 135,
            }}
          >
            <ThreatListFilters
              filters={filters}
              onApply={handleApplyFilters}
              onDetectionTypeApply={handleApplyFiltersForDetectionType}
              onClear={handleClearFilters}
              onClearAll={handleClearAllFilters}
              isFiltering={isFiltering}
              malwareCount={malwareCount}
              pupCount={pupCount}
              isEmpty={isEmpty}
              isLoading={isLoadingThreats}
              onExport={handleExportThreatDetailsClick}
              isFileDetectionType={isFileDetectionType}
              isExportHidden={
                isEmpty || isLoadingThreats || !isFileDetectionType
              }
            />
          </Container>
          <ThreatListTable
            isLoading={isLoadingThreats}
            isError={isErrorFetchingThreats}
            error={errorFetchingThreats}
            sort={sort}
            onColumnSort={handleColumnSortChange}
            pagination={pagination}
            onPaginationChange={handlePaginationChange}
            dataCount={dataCount}
            displayDetectionPeriod={displayDetectionPeriod}
            displayDetectionSubject={
              isFileDetectionType
                ? i18n.t('malware or PUP detections')
                : i18n.t('malicious or suspicious behavioral detections')
            }
            isFiltering={isFilteringOmittingFields}
            showToast={showToast}
            columns={getColumns(isFileDetectionType)}
            isMoreActionsShown={
              !isThreatSelectedForSplitView && isFileDetectionType
            }
            containerCss={{
              '& tr > td:last-child': {
                paddingRight: '$5',
              },
              '& th:last-child, td:last-child': {
                padding: 0,
                textAlign: 'right',
              },
              '& thead': {
                top: 227,
                zIndex: 2,
              },
              '& th:first-child': {
                paddingLeft: '$5',
              },
              ...containerCssProcessMonitoring,
            }}
            paginationCss={{
              position: 'sticky',
              width: 'auto',
              '.full-width-sidebar-closed &': {
                width: 'auto',
              },
              bottom: 0,
            }}
            tablePlaceholderCss={{
              height: 'calc(100% - 96px)',
            }}
          >
            <ThreatListTableBody
              threats={threats}
              query={filters.query}
              onGetReleaseDetails={handleGetReleaseDetails}
              onOpenSplitView={handleOpenSplitView}
              isMoreActionsShown={
                !isThreatSelectedForSplitView && isFileDetectionType
              }
              onRecheckStatus={handleRecheckThreatStatus}
              isThreatBeingChecked={isThreatBeingChecked}
              selectedThreatForSplitView={selectedForSplitView?.threat}
              isFileDetectionType={isFileDetectionType}
            />
          </ThreatListTable>
        </Flex>
        {isThreatSelectedForSplitView && (
          <Flex
            css={{
              height: 'calc(100vh - 135px)',
              zIndex: 1,
              position: 'sticky',
              top: 135,
            }}
          >
            <ThreatListSuspense>
              <ThreatListSplitViewPlaceholder
                selected={selectedForSplitView?.threat}
                onClose={handleCloseSplitView}
                query={filters.query}
                onReleaseThreat={
                  /* istanbul ignore next */ () =>
                    handleGetReleaseDetails(selectedForSplitView?.threat)
                }
                onRecheckStatus={handleRecheckThreatStatus}
                isThreatBeingChecked={isThreatBeingChecked}
              />
            </ThreatListSuspense>
          </Flex>
        )}
      </Flex>
      {threatToRelease && (
        <ThreatListReleaseModal
          name={threatToRelease?.threat_name}
          fileHash={threatToRelease?.file_hash}
          deviceId={threatToRelease?.device_id}
          libraryItemId={threatToRelease?.library_item_id}
          onClose={handleCloseModal}
          onRelease={handleRelease}
          onError={handleReleaseError}
        />
      )}
    </ThreatListErrorBoundary>
  );
};

export default ThreatListEvents;
