import { Box, Code, Flex, Text, Tooltip, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import EDRCopyButton from 'src/features/edr/common/components/EDRCopyButton';
import { SoftwareIcon } from 'src/features/edr/vulnerability/common/software-icon';
import type { SoftwareSummary } from 'src/features/edr/vulnerability/vulnerability.types';
import {
  doesTextHaveMatch,
  highlightedText,
} from 'src/pages/ADEListView/utils/highlightText';

type DeviceSoftwareItemProps = {
  software: SoftwareSummary;
  searchTerm: string;
};

const DetailValue = styled(Text, {
  fontWeight: '$medium',
});

const DeviceSoftwareItem = (props: DeviceSoftwareItemProps) => {
  const { software, searchTerm } = props;
  const { detection_date, name, icon_key, version, path } = software;

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isMatchInOverflow, setIsMatchInOverflow] = useState(false);
  const codeRef = useRef<HTMLDivElement>(null);
  const hiddenTextRef = useRef<HTMLDivElement>(null);

  useEffect(
    /* istanbul ignore next */ () => {
      const checkOverflow = () => {
        if (codeRef.current && hiddenTextRef.current) {
          const isOverflowing =
            hiddenTextRef.current.scrollWidth > codeRef.current.clientWidth;
          setIsOverflowing(isOverflowing);

          // Determines if there is a match in the overflowed text. This determines whether or not the ellipsis should be highlighted.
          if (isOverflowing) {
            const visibleTextWidth = codeRef.current.clientWidth;
            const fullText = hiddenTextRef.current.textContent || '';
            const visibleText = fullText.slice(
              0,
              Math.floor(visibleTextWidth / 8),
            ); // Approximate character width
            const overflowingText = fullText.slice(visibleText.length);

            setIsMatchInOverflow(
              searchTerm && doesTextHaveMatch(overflowingText, searchTerm),
            );
          }
        }
      };

      const debouncedCheckOverflow = debounce(checkOverflow, 100);

      // Run once on mount
      checkOverflow();

      // Attach resize listener
      window.addEventListener('resize', debouncedCheckOverflow);

      // Cleanup on unmount
      return () => {
        window.removeEventListener('resize', debouncedCheckOverflow);
      };
    },
    [path, searchTerm],
  );

  const pathDisplay = (
    <Code
      css={{
        display: 'flex',
        maxWidth: '500px',
      }}
    >
      <Text
        ref={codeRef}
        css={{
          maxWidth: '450px',
          fontFamily: '$code',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textDecorationThickness: '0.5px',
        }}
      >
        {highlightedText(path, searchTerm)}
      </Text>
      <Text
        ref={hiddenTextRef}
        css={{
          fontFamily: '$code',
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'nowrap',
          textDecorationThickness: '0.5px',
        }}
      >
        {path}
      </Text>

      {/* Manually add ellipsis when the text is overflowing so that we have 
      control over whether or not it is highlighted upon search. */}
      {
        /* istanbul ignore next - text cannot overflow in tests */ isOverflowing && (
          <Text
            css={{
              background: isMatchInOverflow ? '$yellow30' : '',
            }}
          >
            ...
          </Text>
        )
      }
    </Code>
  );

  return (
    <Flex gap="md">
      <SoftwareIcon iconKey={icon_key} size="md" />
      <Flex flow="column">
        <Flex alignItems="center" gap="sm" pb3>
          <Text size="3" css={{ fontWeight: '$medium' }}>
            {name}
          </Text>
          <Flex
            css={{
              border: '1px solid $neutral30',
              borderRadius: '$rounded-sm',
              padding: '2px $1',
            }}
          >
            <Text
              size="1"
              variant="description"
              css={{ fontWeight: '$medium' }}
            >
              {version}
            </Text>
          </Flex>
        </Flex>

        <Flex gap="xl">
          <Flex flow="column" gap="sm">
            <Text variant="secondary">{i18n.t('Detection date')}</Text>
            <DetailValue css={{ lineHeight: '$2' }}>
              {i18n.format.datetime(detection_date)}
            </DetailValue>
          </Flex>

          {path && (
            <Flex flow="column" gap="sm">
              <Text variant="secondary">{i18n.t('Path')}</Text>
              <Flex alignItems="center" gap="xs">
                {
                  /* istanbul ignore next - text cannot overflow in tests */ isOverflowing ? (
                    <Tooltip
                      content={<Text>{highlightedText(path, searchTerm)}</Text>}
                      theme="dark"
                      side="bottom"
                      css={{ zIndex: 2, maxWidth: 'auto' }}
                    >
                      {pathDisplay}
                    </Tooltip>
                  ) : (
                    pathDisplay
                  )
                }
                <Box css={{ zIndex: 2 }}>
                  <EDRCopyButton
                    value={path}
                    testId="path-copy-button"
                    css={{ tooltipCss: { zIndex: 2 } }}
                  />
                </Box>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export { DeviceSoftwareItem };
