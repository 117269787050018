import { Box, Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import type React from 'react';
import { useGetDeviceDetails } from './api';
import type { TWindowsDeviceDetail } from './windows.mapping';

// TODO: Apple/Android
export type IDeviceDetail = TWindowsDeviceDetail;

interface DetailsWrapperProps {
  deviceId: string;
  children: (detail: IDeviceDetail) => React.ReactNode;
}

export const DetailsWrapper = (props: DetailsWrapperProps) => {
  const { deviceId, children } = props;
  const { data, isLoading } = useGetDeviceDetails(deviceId);

  if (isLoading) {
    return null;
  }

  return (
    <Flex flow="column" css={{ gap: '64px' }}>
      {children(data)}
    </Flex>
  );
};

export interface IDetailCategory<TPath extends string = string> {
  [key: string]: {
    dataKey: TPath | Array<TPath>;
    title: string;
    transform?: (value: any, dataKey: TPath) => React.ReactNode;
  };
}

interface DetailsCategoryProps {
  title: string;
  children: React.ReactNode;
}

export const DetailsCategory = (props: DetailsCategoryProps) => {
  const { title, children } = props;

  return (
    <Flex flow="column" css={{ gap: '18px' }}>
      <Heading size="3">{title}</Heading>
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          columnGap: '20px',
          rowGap: '4px',
        }}
      >
        {children}
      </Box>
    </Flex>
  );
};

interface DetailsCategoryRowProps {
  title: string;
  value: React.ReactNode;
}

export const DetailsCategoryRow = (props: DetailsCategoryRowProps) => {
  const { title, value } = props;

  return (
    <>
      <Text variant="secondary" css={{ lineHeight: '20px' }}>
        {title}:
      </Text>
      <Text
        css={{
          lineHeight: '20px',
          fontWeight: 500,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whitespace: 'nowrap',
        }}
      >
        {value || '-'}
      </Text>
    </>
  );
};
