/* istanbul ignore file */
import { Box } from '@kandji-inc/nectar-ui';
import React, { useEffect, useRef } from 'react';

export default function GooglePlayStore() {
  const iFrameRef = useRef();

  useEffect(() => {
    if (iFrameRef.current) {
      const token =
        'WAPNoZFzHPYkRwn4zSYB3aV0DVfRDU3Vc1IH8L4HxWYWHiSi4kTQA4fK5kDQSGRp-c41GeqcnEDcI3QMp6bdOCEAxyiYF9n_sDgBlIgeBCrfBmXt6qXjFd4zgHvunmYTVBF5rCxmZONwutIG9aGUCFx3WGGGHNXj9ZQ64aF7kOMhEnlZZoMITmxk';
      // @ts-ignore
      window.gapi?.load('gapi.iframes', () => {
        const options = {
          url: `https://play.google.com/work/embedded/search?token=${token}&mode=SELECT`,
          where: iFrameRef.current,
          attributes: {
            style: 'width: 600px; height:1000px',
            scrolling: 'yes',
          },
        };

        // @ts-ignore
        const iframe = window.gapi.iframes.getContext().openChild(options);
      });
    }
  }, []);

  return (
    <Box wScreen hScreen>
      <div ref={iFrameRef} />
    </Box>
  );
}
