/* istanbul ignore file */
import {
  Flex,
  Text,
  Tooltip,
  TooltipProvider,
  styled,
} from '@kandji-inc/nectar-ui';
import type { ReactNode } from 'react';
import { i18n } from 'src/i18n';
import {
  severityColorMap,
  translatedSeverity,
  translatedSeverityRanges,
} from '../../constants';
import { Severity } from '../../vulnerability.types';

type SeverityTooltipProps = {
  severity: Severity;
  children: ReactNode;
};

const SeverityKey = styled(Text, {
  width: '73px',
});

const SeverityTooltip = (props: SeverityTooltipProps) => {
  const { severity, children } = props;

  const severities = [
    {
      value: Severity.critical,
      label: translatedSeverity.Critical(),
      lowRange: translatedSeverityRanges.criticalBottom(),
      highRange: translatedSeverityRanges.criticalTop(),
    },
    {
      value: Severity.high,
      label: translatedSeverity.High(),
      lowRange: translatedSeverityRanges.highBottom(),
      highRange: translatedSeverityRanges.highTop(),
    },
    {
      value: Severity.medium,
      label: translatedSeverity.Medium(),
      lowRange: translatedSeverityRanges.mediumBottom(),
      highRange: translatedSeverityRanges.mediumTop(),
    },
    {
      value: Severity.low,
      label: translatedSeverity.Low(),
      lowRange: translatedSeverityRanges.lowBottom(),
      highRange: translatedSeverityRanges.lowTop(),
    },
    {
      value: Severity.none,
      label: `${i18n.t('None')}*`,
      lowRange: translatedSeverityRanges.noneBottom(),
      highRange: null,
    },
  ];

  const content = (
    <Flex flow="column" gap="xs">
      <Text css={{ fontWeight: '$medium', fontSize: '$1' }}>
        {i18n.t('CVSS v4.0 Ratings')}
      </Text>

      <Flex flow="column" gap="xs">
        <Flex flow="row">
          <SeverityKey css={{ fontWeight: '$medium' }}>
            {i18n.t('Severity')}
          </SeverityKey>
          <Text css={{ fontWeight: '$medium' }}>
            {i18n.t('Severity Score Range')}
          </Text>
        </Flex>

        {severities.map((item) => {
          const { value, label, lowRange, highRange } = item;
          const css = value.toLowerCase().includes(severity.toLowerCase())
            ? {
                fontWeight: '$medium',
                color: severityColorMap[severity.toLowerCase()],
              }
            : {};

          return (
            <Flex flow="row">
              <SeverityKey css={css}>{label}</SeverityKey>
              <Text css={css}>
                {lowRange}
                {highRange ? ` - ${highRange}` : ''}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );

  return (
    <TooltipProvider>
      <Tooltip
        content={content}
        side="bottom"
        theme="light"
        css={{ zIndex: 100, padding: '$3 $4', maxWidth: '260px' }}
      >
        {children}
      </Tooltip>
    </TooltipProvider>
  );
};

export { SeverityTooltip };
